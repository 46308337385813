import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
export interface PeriodicElement {
  user: string;
  Membershipid: any;
  Phoneno: any;
  admin: any;
  Status: string;
  Lastvisited: any;
  adminimg: any;
  Action: any;
  image: any;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     user: 'Kristin Watson',
//     Membershipid: '56CALVARY1234',
//     Phoneno: '+91-9876543210',
//     Status: 'suspended',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-1.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Cody Fisher',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'active',
//     Lastvisited: '18-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-2.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Arlene McCoy',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'suspended',
//     Lastvisited: '8-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-3.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Floyd Miles',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'active',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-4.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Darlene Robertson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'active',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-5.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'suspended',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-6.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'suspended',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-7.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'active',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-8.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
//   {
//     user: 'Kristin Watson',
//     Phoneno: '+91-9876543210',
//     Membershipid: '56CALVARY1234',
//     Status: 'suspended',
//     Lastvisited: '13-08-2022',
//     Action: '',
//     image: '../../assets/images/panel_images/user-9.svg',
//     admin: 'Admin',
//     adminimg: '../../assets/images/panel_images/admin-i.svg',
//   },
// ];
@Component({
  selector: 'app-internal-team',
  templateUrl: './internal-team.component.html',
  styleUrls: ['./internal-team.component.css'],
})
export class InternalTeamComponent implements OnInit {
  services: any[] = ['super admin', 'admin', 'manager'];
  status: any[] = ['active', 'suspended'];
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  loader: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  query: {};
  role: any;
  search: any = '';
  currentPageView: number;
  statusView: string;
  constructor(private service: AdminService, private route: Router) {}
  displayedColumns: string[] = [
    'user',
    'Phoneno',
    'role',
    'Status',
    'Lastvisited',
    'Action',
  ];
  ngOnInit(): void {
    this.loader = true;
    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.internalTeam(this.query).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.formattedUsers;
      this.totalSummary = result.totalUsers;
      this.loader = false;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  roleEvent(e: any) {
    console.log(e.value);
    if (e.value != '') {
      this.role = JSON.stringify(e.value);
      // this.role = e.value;
    } else {
      this.role = '';
    }

    this.query = {
      role: this.role ? this.role : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    console.log(this.pageSize);
    this.service.internalTeam(this.query).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.formattedUsers;
      this.totalSummary = result.totalUsers;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  statusEvent(e: any) {
    console.log(e.value);
    if (e.value != '') {
      this.statusView = JSON.stringify(e.value);
      // this.role = e.value;
    } else {
      this.statusView = '';
    }

    this.query = {
      role: this.role ? this.role : '',
      status: this.statusView ? this.statusView : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    console.log(this.pageSize);
    this.service.internalTeam(this.query).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.formattedUsers;
      this.totalSummary = result.totalUsers;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  view(id: any) {
    this.route.navigate(['update-user'], { queryParams: { id: id } });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.currentPageView = newCurrentPage;
    console.log(this.currentPage);

    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    console.log(this.pageSize);
    this.service.internalTeam(this.query).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.formattedUsers;
      this.totalSummary = result.totalUsers;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  suspended(id: any) {
    this.service.suspendedUser(id).subscribe((result) => {
      console.log(result);

      this.service.internalTeam(this.query).subscribe((result) => {
        console.log(result);
        this.ELEMENT_DATA = result.formattedUsers;
        this.totalSummary = result.totalUsers;
        Swal.fire({
          icon: 'success',
          title: 'Supended succefully',
          showConfirmButton: false,
          timer: 1500,
        });
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      });
    });
  }

  searchDropDown(event: any) {
    if (event.value != '') {
      this.search = event.target.value;
    } else {
      this.search = '';
    }
    this.query = {
      name: this.search ? this.search : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPageView ? this.currentPageView : '',
    };
    console.log(this.pageSize);
    this.service.internalTeam(this.query).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.formattedUsers;
      this.totalSummary = result.totalUsers;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
}
