import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import factory from 'highcharts/modules/map';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-reviewscampinsdialog',
  templateUrl: './reviewscampinsdialog.component.html',
  styleUrls: ['./reviewscampinsdialog.component.css'],
})
export class ReviewscampinsdialogComponent implements OnInit {
  selectType: any = true;
  selectTypeMerchant: any = false;
  selectTypeMerchantSMS: any;
  selectTypeMerchantSMSNOW: any;
  success: any;
  selectTypeVisitorSMS: any;
  selectTypeVisitorSMSNOW: any;
  successVisitor: any;
  clicks: any;
  Req: any;
  location: any;
  id: any;
  num: any;
  loading: boolean;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: AdminService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.clicks = this.data.clicks;
    this.location = this.data.location;
    this.Req = this.data.reqSent;
    this.id = this.data.id;
    this.num = this.data.num;
  }

  merchant() {
    this.selectTypeMerchant = true;
    this.selectType = false;
  }
  sms() {
    this.selectTypeMerchant = false;
    this.selectTypeMerchantSMS = true;
  }
  sendSms() {
    this.selectTypeMerchantSMSNOW = true;
    this.selectTypeMerchantSMS = false;
  }
  sendnow() {
    this.loading = true;

    let obj = {
      compaginType: 'Sms',
      request_sent: this.Req,
      clicked: this.clicks,
      locationName: this.location,
      mobileNumber: this.num,
      deviceId: this.id,
    };
    this.service.campignReviews(obj).subscribe((res) => {
      this.success = true;
      this.selectTypeMerchantSMSNOW = false;
      this.loading = false;
    });
  }
  visitors() {
    this.selectTypeVisitorSMS = true;
    this.selectType = false;
  }
  sendSmsVisitor() {
    this.selectTypeVisitorSMS = false;
    this.selectTypeVisitorSMSNOW = true;
  }
  sendnowVisitor() {
    this.successVisitor = true;
    this.selectTypeVisitorSMSNOW = false;
  }
  back() {
    this.selectType = true;
    this.success = false;
  }
  backVisitor() {
    this.selectType = true;
    this.successVisitor = false;
  }
  close() {
    this.dialog.closeAll();
  }
}
