import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  ClickEvent,
  HoverRatingChangeEvent,
  RatingChangeEvent,
} from 'angular-star-rating';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
// import {
//   ClickEvent,
//   HoverRatingChangeEvent,
//   RatingChangeEvent,
// } from '@angular-star-rating-lib/angular-star-rating';

@Component({
  selector: 'app-location-reviews',
  templateUrl: './location-reviews.component.html',
  styleUrls: ['./location-reviews.component.css'],
})
export class LocationReviewsComponent implements OnInit {
  reviewForm: FormGroup;
  locationName: any;
  locationId: any;
  onClickResult: ClickEvent;
  onHoverRatingChangeResult: HoverRatingChangeEvent;
  onRatingChangeResult: RatingChangeEvent;
  subscribeSubmitted: any;
  starsSubmitted: any = 0;
  stars: boolean;
  loader: boolean;
  customer: any;
  constructor(
    private dilaog: MatDialog,
    private fb: FormBuilder,
    private service: AdminService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reviewForm = this.fb.group({
      name: ['', [Validators.required]],
      id: ['', [Validators.required]],
      reviewName: ['', [Validators.required]],
      stars: [''],
      review: ['', [Validators.required]],
    });
    console.log(this.data);
    this.locationName = this.data.name;
    this.locationId = this.data.id;
    this.customer = this.data.customer;
  }

  ngOnInit(): void {
    this.reviewForm.controls['name'].setValue(this.locationName);
    this.reviewForm.controls['id'].setValue(this.locationId);
  }

  get c(): { [key: string]: AbstractControl } {
    return this.reviewForm.controls;
  }
  submit() {
    if (this.starsSubmitted == 0) {
      this.stars = true;
    }
    if (this.reviewForm.invalid) {
      this.subscribeSubmitted = true;
    } else {
      this.subscribeSubmitted = false;
      this.loader = true;
      console.log('Submitted', this.starsSubmitted?.rating);
      this.reviewForm.controls['stars'].setValue(this.starsSubmitted?.rating);
      let obj = {
        locationName: this.reviewForm.value.name,
        locationId: this.reviewForm.value.id,
        reviewed_by: this.reviewForm.value.reviewName,
        review: this.reviewForm.value.review.trim(),
        stars: this.reviewForm.value.stars,
      };
      this.service.addReviews(obj).subscribe((review) => {
        this.dilaog.closeAll();
        this.loader = false;

        this.reviewForm.reset();
        Swal.fire({
          text: 'Succefully Added review',
          showConfirmButton: true, // Hide the "OK" button
          icon: 'success', // You can change the icon type
          timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        });
      });
      console.log('Submitted', this.reviewForm.value);
    }
  }
  close() {
    this.dilaog.closeAll();
  }
  onClick = ($event: ClickEvent) => {
    console.log('onClick $event: ', $event.rating);
    this.onClickResult = $event;
    this.starsSubmitted = $event;
    if (this.starsSubmitted != 0) {
      this.stars = false;
    }
  };

  onRatingChange = ($event: RatingChangeEvent) => {
    console.log('onRatingUpdated $event: ', $event);
    this.onRatingChangeResult = $event;
  };

  onHoverRatingChange = ($event: HoverRatingChangeEvent) => {
    console.log('onHoverRatingChange $event: ', $event);
    this.onHoverRatingChangeResult = $event;
  };
}
