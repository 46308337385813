

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Reports</h1>
        </div>
            <app-loader *ngIf="loader"></app-loader>

       <div class="d-flex   language-header">
       
            <select   class="selectpicker" >
                <option  *ngFor="let item of Language" [selected]="item == 'weekly'" value="{{item}}">{{item}}</option>
            </select>
        </div>
     </div>

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
        </div>
     </div>
     <!-- <div class="d-flex justify-content-end mr-md-5 mb-3">
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of monthsArray" [selected]="item == this.currentMonth" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
        <!-- <div><h2>Merchant Subscriptions</h2>
             </div> -->

         <div class="table-data mb-5 pb-3 mt-4">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> L_Name </th>
    <td mat-cell *matCellDef="let element">
      <p class="Id"> {{element?.merchantId}}</p>  </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> L_ID </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element?.locationId}}</p></td>
  </ng-container>
 <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> No of review requests sent </th>
    <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> New reviews received
 </th>
    <td mat-cell *matCellDef="let element"><p class="Id">{{element?.amount}}</p></td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> No of referral requests sent
 </th>
    <td mat-cell *matCellDef="let element"> {{element?.date | date}} </td>
  </ng-container>
  <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> No of new visits via referrals
 </th>
    <td mat-cell *matCellDef="let element"
    > 
     <p [class.failed]="element?.paymentStatus == 'failed'" [class.success]="element?.paymentStatus == 'success'">{{element?.paymentStatus}}</p> 
     </td>
  </ng-container>
 
  <ng-container matColumnDef="subscription">
    <th mat-header-cell *matHeaderCellDef> Customers visits( new vs repeat)
 </th>
    <td mat-cell *matCellDef="let element"> {{element?.statusDetails?.status}} </td>
  </ng-container>
  <!-- <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Invited </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> invite_clicks </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="leads">
    <th mat-header-cell *matHeaderCellDef> Leads </th>
    <td mat-cell *matCellDef="let element"> {{element.totalLeads}} </td>
  </ng-container>
  <ng-container matColumnDef="firstvisits">
    <th mat-header-cell *matHeaderCellDef> first_visits </th>
    <td mat-cell *matCellDef="let element"> {{element.firstVisit}} </td>
  </ng-container>
  <ng-container matColumnDef="conversion">
    <th mat-header-cell *matHeaderCellDef> Conversion </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="Revenue">
    <th mat-header-cell *matHeaderCellDef> Revenue </th>
    <td mat-cell *matCellDef="let element"> {{element.revenue}} </td>
  </ng-container> -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">Generate report </div></td>
  </ng-container>
  <ng-container matColumnDef="action2">
    <th mat-header-cell *matHeaderCellDef> Action 2 </th>
<td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              mat-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>
                <img
                  src="../../assets/images/action-dot.svg"
                  id="users-total"
                  class="action-dots"
              /></mat-icon>
              <div
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                place
              ></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                class="View"
                mat-menu-item
                value="Edit"
              >
                <img
                  src="../../assets/images/view.svg"
                  class="mr-3"
                />
                <span>email</span>
              </button>
              <button
                class="Deactivate"
                mat-menu-item
                value="Deactivate"
              >
                <img
                  src="../../assets/images/suspened.svg"
                  class="mr-3"
                />
                <span>sms</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
              <button
                class="Deactivate"
                mat-menu-item
                value="Deactivate"
              >
                <img
                  src="../../assets/images/suspened.svg"
                  class="mr-3"
                />
                <span>WhatsApp</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
            </mat-menu>
          </td>
  </ng-container>
  <ng-container matColumnDef="action3">
    <th mat-header-cell *matHeaderCellDef> Action 3</th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">Download report </div></td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <!-- <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> -->
     </div>
 
      </div>
      </div>