import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPasswordValidator } from 'src/app/_helpers/cofirmPasswordValidator';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';

var emailPhonePattern =
  '^[\\w]+(?:\\.[\\w])*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  newPassword: boolean = true;
  confirmNewPassword: boolean = true;
  hide = true;
  public showPassword: boolean = true;
  public showPasswordOnPress: boolean = false;
  oldpassword: boolean = false;
  changePasswordForm: FormGroup;
  updateProfileForm: FormGroup;
  itSupportForm: FormGroup;
  error: any;
  //url; //Angular 8
  url: any; //Angular 11, for stricter type
  msg: any; //Message
  adminImg: any;
  selectedFile: any;
  img: any;
  submitted: boolean = false;
  submittedSupport: boolean = false;
  images: any;
  selectedFileDocument = 'null';
  fileName: any;
  attachmentFile: boolean = false;
  uploadFlie: boolean = true;
  issue = [
    { key: 'Web Admin', value: 'Web Admin' },
    { key: 'Website', value: 'Website' },
    { key: 'Android', value: 'Andriod' },
    { key: 'iOS', value: 'iOS' },
  ];
  adminEmail: any;
  typeOfBug: any;
  raiseAnIssue: any;
  raiseAnIssueId: any;
  checkedRadio: any;
  firstName: any;
  secondName: any;
  MoblieNumber: any;
  itSupportDetails: any;
  role: any;
  roles: any;
  total: any;
  open: any;
  invalid: any;
  closed: any;
  queryParams: { pageLimit: any; pageNumber: any };
  pageSize: any;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  constructor(
    private fb: FormBuilder,
    private service: AdminService,
    private dialog: MatDialog
  ) {
    this.roles = localStorage.getItem('ROLE');
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmPasswordValidator('newPassword', 'confirmPassword'),
      }
    );
    this.updateProfileForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      file: [''],
    });
    this.itSupportForm = this.fb.group({
      issueType: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      email: [''],
      file: [''],
    });
  }

  services: any[] = [
    'Hospital & Medical',
    'Parents Home',
    'Birthday Cake',
    'Cementery',
    'Weddings',
    'Monthly Pension',
    'Ambulance Service',
    'Pastor’s Fund',
  ];
  close() {
    this.uploadFlie = true;
    this.attachmentFile = false;
  }
  raiseAnissue(event: any) {
    this.raiseAnIssueId = event.value;
  }
  typeOfBugIssue(event: any) {
    this.typeOfBug = event.value;
  }

  ngOnInit(): void {
    this.service.adminDetails().subscribe((results) => {
      // this.adminImg = results.data.profile;
      // this.url = this.adminImg;
      this.adminEmail = results[0].email;
      this.firstName = results[0].firstName ? results[0].firstName : '';
      this.secondName = results[0].lastName ? results[0].lastName : '';
      this.role = results[0].role;
      console.log('====================================');
      console.log(this.role);
      console.log('====================================');
      if (results[0].phone === 'undefined') {
        this.MoblieNumber = '';
      } else {
        this.MoblieNumber = results[0].phone;
      }
    });
    // this.service.itSupportTitle().subscribe((res) => {
    //   this.raiseAnIssue = res.data;
    // });
  }
  tabClick(tab: any) {
    this.changePasswordForm.reset();
  }

  onFileSelectedDocument(event: any) {
    // this.selectedFileDocument = event.target.files[0];
    // console.log(this.selectedFileDocument);
    // console.log(event.target.files[0].name);
    // this.fileName = event.target.files[0].name;
    // if (event.target.files[0].name != null) {
    //   this.uploadFlie = false;
    //   this.attachmentFile = true;
    // }
    const file: File = event.target.files[0];
    this.selectedFileDocument = event.target.files[0];

    if (file) {
      this.fileName = file.name;
    }
    if (event.target.files[0].name != null) {
      this.uploadFlie = false;
      this.attachmentFile = true;
    }
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = '';
      this.url = reader.result as string;

      this.selectedFile = <File>event.target.files[0];
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.updateProfileForm.get('file').setValue(file);
    }
    //  this.updateProfileForm.get('file').setValue(file);
  }

  // validations for numberOnly
  numberOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  // validations for numberOnly
  alphabetOnly(event: any): boolean {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  save() {
    if (this.updateProfileForm.invalid) {
      this.submitted = true;
      // const formData = new FormData();
      // formData.append('file', this.images);
      // console.log(this.images.name);
    } else {
      // const formData = new FormData();
      // // formData.append('file', this.updateProfileForm.get('file').value);
      // formData.append('firstName', this.updateProfileForm.value.firstName);
      // formData.append('lastName', this.updateProfileForm.value.lastName);
      // // formData.append('email', this.updateProfileForm.value.email);
      // formData.append('phone', this.updateProfileForm.value.phone);
      let obj = {
        firstName: this.updateProfileForm.value.firstName,
        lastName: this.updateProfileForm.value.lastName,
        email: this.updateProfileForm.value.email,
        phone: this.updateProfileForm.value.phone,
      };
      this.service.adminUpdateProfile(obj).subscribe(
        (data) => {
          // this.dialog.open(ProfilesuccessDialogComponent, {
          //   disableClose: true,
          // });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1500);

          this.service.adminDetails().subscribe((results) => {
            this.adminEmail = results[0].email;
            this.firstName = results[0].firstName ? results[0].firstName : '';
            this.secondName = results[0].lastName ? results[0].lastName : '';
            this.role = results[0].role;
            Swal.fire({
              text: 'updated successfully',
              showConfirmButton: true, // Hide the "OK" button
              icon: 'success', // You can change the icon type
              timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
            });
          });
        },
        (err) => {
          this.error = err.error.message;
        }
      );
    }
  }

  get changePasswordErrorHandler(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }
  get updateProfile(): { [key: string]: AbstractControl } {
    return this.updateProfileForm.controls;
  }
  get updateItSupport(): { [key: string]: AbstractControl } {
    return this.itSupportForm.controls;
  }
  // onFileSelected(event: any) {
  //   this.selectedFile = event.target.files[0];
  //   console.log(this.selectedFile.name);
  //   this.img = this.selectedFile.name;
  //   if (this.img != null) {
  //     this.adminImg = this.img;
  //   }
  // }

  //selectFile(event) { //Angular 8

  changePassword() {
    if (this.changePasswordForm.invalid) {
      this.oldpassword = true;
    } else {
      this.oldpassword = false;
      let obj = {
        oldPassword: this.changePasswordForm.value.oldPassword,
        newPassword: this.changePasswordForm.value.newPassword,
        confirmNewPassword: this.changePasswordForm.value.newPassword,
      };

      this.service.adminChangePassword(obj).subscribe(
        (data) => {
          // this.error = true;
          Swal.fire({
            text: 'Passsword changed successfully',
            showConfirmButton: true, // Hide the "OK" button
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
          });
          this.changePasswordForm.reset();
        },
        (err) => {
          console.log(err.error.error);
          this.error = err.error.error;
          setTimeout(() => {
            this.error = '';
          }, 3000);
        }
      );
    }
  }
}
