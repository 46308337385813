<div class="main settings-page">
  <div class="container-fluid bg-white">
    <div class="user-list-header d-flex pt-3 pb-1 ml-2">
      <div class="user-list-heading d-flex">
        <!-- <img
          src="../../assets/images/setting-i.svg"
          class="user-list mr-2"
        /> -->
        <h6 class="mt-2">Settings</h6>
      </div>
    </div>
  </div>

  <div class="mt-3 ml-0 mb-5 pb-4">
    <mat-tab-group (selectedTabChange)="tabClick($event)">
      <mat-tab label="Account Settings">
        <form [formGroup]="updateProfileForm">
          <div
            class="user-header-details-img-section mt-4 ml-4 mb-5 pb-4 bg-white"
          >
            <div class="upload pt-4 pl-4">
              <!-- <img
                *ngIf="url != null && url != ''"
                [src]="url"
                width="100"
                height="100"
                alt=""
                class="upload-img"
              />
              <img
                *ngIf="url == null || url == ''"
                src="../../assets/images/place-holder.jpg"
                width="100"
                height="100"
                alt=""
                class="upload-img"
              /> -->
              <!-- <ngx-avatar *ngIf="url == null " class="mr-3 mt-md-2" initialsSize="1" size="40" name="firstName"></ngx-avatar> -->

              <!-- <img *ngIf="ussrl != null" [src]="adminImg" width=100 height=100 alt="" class="upload-img"> -->
              <!-- <div class="round">
                <input
                  type="file"
                  accept=".png"
                  (change)="onFileSelected($event)"
                />
                <img
                  src="../../assets/images/camera.svg"
                  width="100"
                  height="100"
                  alt=""
                  class="camera-img"
                />
              </div> -->
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="profile-form-fields pl-3">
                  <input
                    placeholder=" "
                    formControlName="firstName"
                    type="text"
                    (keypress)="alphabetOnly($event)"
                    [(ngModel)]="firstName"
                  />
                  <label>First name</label>
                </div>
                <!-- <div *ngIf="updateProfile.firstName.errors?.required && submitted"
                    class="invalid-feedback d-block ml-3" >
                    First Name is required
                </div> -->
              </div>
              <div class="col-md-6">
                <div class="profile-form-fields pr-3">
                  <input
                    placeholder=" "
                    formControlName="lastName"
                    type="text"
                    (keypress)="alphabetOnly($event)"
                    [(ngModel)]="secondName"
                  />
                  <label class="secondary-fields">Last name</label>
                </div>
                <!-- <div *ngIf="updateProfile.lastName.errors?.required && submitted"
                    class="invalid-feedback d-block ml-0" >
                    Last Name is required
                </div> -->
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="profile-form-fields pl-3">
                  <input
                    placeholder=" "
                    formControlName="email"
                    type="text"
                    [(ngModel)]="adminEmail"
                   readonly
                  />
                  <label>Email Address</label>
                </div>
                <div
                  *ngIf="updateProfile.email.errors?.required && submitted"
                  class="invalid-feedback d-block ml-3"
                >
                  Email is required
                </div>
                <div
                  *ngIf="updateProfile.email.errors?.email && submitted"
                  class="invalid-feedback ml-3 d-block"
                >
                  Email is Not-Valid
                </div>
              </div>
              <div class="col-md-6">
                <div class="profile-form-fields pr-3">
                  <input
                    placeholder=" "
                    (keypress)="numberOnly($event)"
                    formControlName="phone"
                    type="text"
                    [maxLength]="10"
                    [(ngModel)]="MoblieNumber"
                  />
                  <label class="secondary-fields">Mobile Number</label>
                </div>
                <!-- <div *ngIf="updateProfile.phone.errors?.required && submitted"
                    class="invalid-feedback d-block ml-0" >
                    Mobile  Number is required
                </div> -->
              </div>
            </div>
            <div class="save-btn mt-5 mb-lg-3 pl-4">
              <button type="button" class="btn" (click)="save()">Save</button>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Change Password">
        <div
          class="user-header-details-img-section setting-password mt-4 ml-4 mb-5 pb-4 pt-3 bg-white"
        >
          <form [formGroup]="changePasswordForm">
            <div class="password-s-field my-3">
              <mat-form-field class="pl-3 pr-3">
                <mat-label>Old Password</mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  formControlName="oldPassword"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </button>
              </mat-form-field>
              <div
                *ngIf="
                  changePasswordErrorHandler.oldPassword.errors?.required &&
                  oldpassword
                "
                class="invalid-feedback d-block ml-3"
              >
                Old password is required
              </div>
              <div
                *ngIf="
                  this.error != null ||
                  changePasswordForm.get('oldPassword') != null
                "
                class="invalid-feedback d-block ml-3"
              >
                {{ error }}
              </div>
              <!-- <mat-form-field class="pl-3 pr-3"><input matInput [type]="pwToggle.type" placeholder="Old Password" value="" /><button mat-icon-button matSuffix appOldPasswordToggle #pwToggle="appOldPasswordToggle"><mat-icon>{{ pwToggle.icon }}</mat-icon></button></mat-form-field> -->
            </div>
            <div class="password-s-field my-3">
              <mat-form-field class="pl-3 pr-3">
                <mat-label>New Password</mat-label>
                <input
                  matInput
                  [type]="newPassword ? 'password' : 'text'"
                  formControlName="newPassword"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="newPassword = !newPassword"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="newPassword"
                >
                  <mat-icon>{{
                    newPassword ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </button>
              </mat-form-field>
              <div
                *ngIf="
                  changePasswordErrorHandler.newPassword.errors?.required &&
                  oldpassword
                "
                class="invalid-feedback d-block ml-3"
              >
                New password is required
              </div>
              <!-- <mat-form-field class="pl-3 pr-3"><input matInput [type]="pwToggle.type" placeholder="Password" value="" /><button mat-icon-button matSuffix appNewPasswordToggle #pwToggle="appNewPasswordToggle"><mat-icon>{{ pwToggle.icon }}</mat-icon></button></mat-form-field> -->
            </div>
            <div class="password-s-field my-3">
              <mat-form-field class="pl-3 pr-3">
                <mat-label>Confirm New Password</mat-label>
                <input
                  matInput
                  [type]="confirmNewPassword ? 'password' : 'text'"
                  formControlName="confirmPassword"
                />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="confirmNewPassword = !confirmNewPassword"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    confirmNewPassword ? "visibility_off" : "visibility"
                  }}</mat-icon>
                </button>
              </mat-form-field>
              <div
                *ngIf="
                  changePasswordErrorHandler.confirmPassword.errors?.required &&
                  oldpassword
                "
                class="invalid-feedback d-block ml-3"
              >
                Confirm password is required
              </div>
              <div
                class="invalid-feedback d-block ml-3"
                *ngIf="
                  changePasswordForm.get('confirmPassword')?.errors
                    ?.confirmPasswordValidator
                "
              >
                Passsword and Confirm Password didn't match.
              </div>
              <!-- <div
                class="invalid-feedback d-block ml-3"
                *ngIf="
                  error != ''
                "
              >
                {{Passsword and Confirm Password didn't match.}}
              </div> -->
              <!-- <mat-form-field class="pl-3 pr-3"><input matInput [type]="pwToggle.type" placeholder="Confirm New Password" value="" /><button mat-icon-button matSuffix appPasswordToggle #pwToggle="appPasswordToggle"><mat-icon>{{ pwToggle.icon }}</mat-icon></button></mat-form-field> -->
            </div>
          </form>
          <div class="save-btn mt-5 mb-lg-3 pl-4">
            <button type="button" class="btn" (click)="changePassword()">
              Change Password
            </button>
          </div>
        </div>
      </mat-tab>
      <!-- <mat-tab label="IT Team Support" *ngIf="roles != 'superAdmin'">
        <div class="mt-4 ml-4 pl-2">
          <div class="website-it-support-heading pb-md-3">
            <h4>Reach out IT Team for support</h4>
            <p>
              Please tell us your problem & get a call back from our Calvary
              team.
            </p>
          </div>

          <div class="form_details-support">
            <form [formGroup]="itSupportForm">
              <div class="radio-button-section mt-4">
                <div class="issue_header">
                  <h5>Where did you face this issue?</h5>
                </div>

                <mat-radio-group
                  formControlName="issueType"
                  (change)="typeOfBugIssue($event)"
                >
                  <mat-radio-button
                    *ngFor="let type of issue"
                    [disableRipple]="true"
                    [checked]="type.key === 'Web Admin'"
                    value="{{ type.value }}"
                    name="Website"
                    class="mr-3"
                    >{{ type.key }}</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <div class="Email-field pt-4 it-support" style="width: 34%">
                <mat-form-field>
                  <mat-label>Mobile Number</mat-label>
                  <input
                    matInput
                    maxlength="10"
                    (keydown)="numberOnly($event)"
                    [(ngModel)]="MoblieNumber"
                    formControlName="mobile"
                  />
                </mat-form-field>
                <div
                  *ngIf="
                    updateItSupport.mobile.errors?.required && submittedSupport
                  "
                  class="invalid-feedback d-block"
                >
                  Please enter Mobile Number
                </div>
              </div>
              <div
                style="scroll-behavior: auto"
                class="Email-field mr-5 pr-5 pt-4 it-support"
              >
                <mat-form-field>
                  <mat-label>Title</mat-label>
                  <mat-select
                    formControlName="title"
                    (selectionChange)="raiseAnissue($event)"
                  >
                    <mat-option
                      [value]="issue.name"
                      *ngFor="let issue of raiseAnIssue"
                      >{{ issue.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="
                    updateItSupport.title.errors?.required && submittedSupport
                  "
                  class="invalid-feedback d-block"
                >
                  Please Select
                </div>
              </div>
              <div class="text-area mr-5 pr-5 mt-4">
                <textarea
                  style="resize: none"
                  class="text-area text-option"
                  formControlName="description"
                  placeholder="Enter issue in (600 words)"
                  id="exampleFormControlTextarea1"
                  (keypress)="alphabetOnly($event)"
                  pattern="\S(.*\S)?"
                  rows="6"
                  ngxTrim="focusout"
                  maxlength="3644"
                ></textarea>
                <div
                  *ngIf="
                    updateItSupport.description.errors?.required &&
                    submittedSupport
                  "
                  class="invalid-feedback d-block"
                >
                  Please Write The Issue
                </div>
              </div>
              <div class="attachement-flie-section mt-4 form-group">
                <h5>Attachment</h5>
                <div *ngIf="uploadFlie">
                  <input
                    style="display: none"
                    type="file"
                    (change)="onFileSelectedDocument($event)"
                    #fileInput
                  />
                    <div
            *ngIf="f.attachment.errors?.required && submitted"
            class="invalid-feedback d-block"
          >
            Please Add The Attachment
          </div>  
                  <button class="btn btn-upload" (click)="fileInput.click()">
                    <img
                      src="../../assets/images/upload-1.svg"
                      class="mr-2"
                    />Upload Attachment
                  </button>
                </div>
              </div>
              <div class="row mt-1" *ngIf="attachmentFile">
                <div class="col-md-6">
                  <div class="file-upload-view-section">
                    <div class="d-flex">
                      <div class="mr-auto">
                        <div class="image-section d-flex">
                          <div class="img-section">
                            <img
                              src="../../assets/images/attach-file.png"
                              class="mr-2 flie-img"
                            />
                          </div>

                          <div class="view_details">
                            <h5>{{ fileName }}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="close-img cursor" (click)="close()">
                        <img
                          src="../../assets/images/close-pdf.png"
                          width="30px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </form>
            <div class="it-support-submit mt-4">
              <button class="btn" (click)="submitItSupport()">Submit</button>
            </div>
          </div>
        </div>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</div>
