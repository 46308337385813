import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-cus',
  templateUrl: './cus.component.html',
  styleUrls: ['./cus.component.css'],
})
export class CusComponent implements OnInit {
  customers: any = [
    {
      n: 'John',
      p: 99999999999,
      b: 999,
      v: '12/8/2024',
    },
    {
      n: 'John',
      p: 99999999999,
      b: 999,
      v: '12/8/2024',
    },
  ];
  locations: any;
  dummydata: FormGroup;
  loading: boolean;
  cus: boolean = false;

  constructor(
    private router: Router,
    private service: AdminService,
    private fb: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.dummydata = this.fb.group({
      location: ['', [Validators.required]],
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],

      count: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.service.locationsNames().subscribe((locations) => {
      console.log(locations);
      this.locations = locations.data;
    });
  }
  back() {
    this.router.navigate(['/testing']);
  }
  generate() {
    if (this.dummydata.valid) {
      this.loading = true;
      let obj = {
        locationName: this.dummydata.value.location,
        count: this.dummydata.value.count,

        dateRange: {
          start: this.datepipe.transform(
            this.dummydata.value.start,
            'yyyy/MM/dd'
          ),
          end: this.datepipe.transform(this.dummydata.value.end, 'yyyy/MM/dd'),
        },
      };
      this.service.generateCustomers(obj).subscribe((result) => {
        console.log(result);
        this.customers = result.customers;
        this.loading = false;
        this.cus = true;
      });
      console.log(obj);
    }
  }
}
