<div class="main mt-5 pt-5">
<div class="row justify-content-around">
<div classs="col-6">
   <div>
    <form [formGroup]="referalForm">
    <div class="inputdata">
        <label>heading</label>
        <input type="text" class="form-control" formControlName="heading" (input)="setup($event,'head')"/>
    </div>
    <div class="inputdata">
        <label>Step1</label>
        <input type="text" class="form-control" formControlName="step1" (input)="setup($event,'s1')"/>
    </div>
    <div class="inputdata">
        <label>Step2</label>
        <input type="text" class="form-control" formControlName="step2" (input)="setup($event,'s2')"/>
    </div>
    <div class="inputdata">
        <label>Step3</label>
        <input type="text" class="form-control" formControlName="step3" (input)="setup($event,'s3')"/>
    </div>
    <div class="inputdata">
        <label>Step4</label>
        <input type="text" class="form-control" formControlName="step4" (input)="setup($event,'s4')"/>
    </div>
     </form>
   </div>
</div>
<div classs="col-6">
    <div class="card mb-5">
        <div class="card-body ref">
            <div class="d-flex back">
                <img class="ml-3" src="../../../assets/images/back.svg">
                <p class="mt-3 ml-2">Back</p>
            </div>
            <h6 class="pt-3">{{this.heading}}</h6>
            <p>Set up simple local referral program and grow your customers with click of a button</p>
            <div class="d-flex mt-2">
                <div class="r-img">
                    <img id="r-1" src="../../../assets/images/Frame 1000006435.svg">
                    <img id="r-2" src="../../../assets/images/Line 3.svg">

                </div>
                <div class="ref-1 ml-5 mt-1">
                    <h3>{{this.step1}}</h3>
                </div>
            </div>
            <div class="d-flex mt-2">
                <div class="r-img">
                    <img id="r-1" src="../../../assets/images/Frame 1000006435 (1).svg">
                    <img id="r-2" src="../../../assets/images/Line 3.svg">

                </div>
                <div class="ref-1 ml-5 mt-1">
                    <h3>{{this.step2}}</h3>
                </div>
            </div>
            <div class="d-flex mt-2">
                <div class="r-img">
                    <img id="r-1" src="../../../assets/images/Frame 1000006435 (2).svg">
                    <img id="r-2" src="../../../assets/images/Line 3.svg">

                </div>
                <div class="ref-1 ml-5 mt-1">
                    <h3>{{this.step3}}</h3>
                </div>
            </div>
            <div class="d-flex mt-2">
                <div class="r-img">
                    <img src="../../../assets/images/Frame 1000006435 (3).svg">

                </div>
                <div class="ref-1 ml-3 mt-1">
                    <h3>{{this.step4}}</h3>
                </div>
            </div>
            <!-- <div class="d-flex mt-2" *ngFor="let item of refferalItems ">
                <div class="r-img">
                    <img id="r-1" src="{{item.img}}">
                    <img id="r-2" src="../../../assets/images/Line 3.svg">

                </div>
                <div class="ref-1 ml-5 mt-1">
                    <h3>{{item.name}}</h3>
                </div>
            </div> -->
            <div class="ml-3">
            <p class="ref-2 mt-3">Referrar: A person who refers their friends </p>
    <p class="ref-2">Referee: A person received the referral invitation </p>
    <div class="c-btn pb-4">
        <button class="cursor">Continue Setup</button>
    </div>
    </div>
        </div>
    </div>
    
</div>
</div>
</div>