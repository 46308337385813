import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
export interface PeriodicElement {
  lid: any;
  time: any;
  flow: any;
  type: any;
  sent: any;
  delivered: any;
  opened: any;
  click: any;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {
    lid: '455rfssr67hgr4rffe',
    time: '12/23 10:00pm',
    flow: 'Review',
    type: 'SMS',
    sent: 'success',
    delivered: 'success',
    opened: 'yes',
    click: 'yes',
  },
  {
    lid: '455rfssr67hgr4rffe',
    time: '12/23 10:00pm',
    flow: 'Review',
    type: 'SMS',
    sent: 'success',
    delivered: 'success',
    opened: 'yes',
    click: 'yes',
  },
  {
    lid: '455rfssr67hgr4rffe',
    time: '12/23 10:00pm',
    flow: 'Review',
    type: 'SMS',
    sent: 'success',
    delivered: 'success',
    opened: 'yes',
    click: 'yes',
  },
  {
    lid: '455rfssr67hgr4rffe',
    time: '12/23 10:00pm',
    flow: 'Review',
    type: 'SMS',
    sent: 'success',
    delivered: 'success',
    opened: 'yes',
    click: 'yes',
  },
];

@Component({
  selector: 'app-logins',
  templateUrl: './logins.component.html',
  styleUrls: ['./logins.component.css'],
})
export class LoginsComponent implements OnInit {
  freetrailChartConversion: any;
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Step1',
    'Step2',
    'Step3',
    'Step4',
    'status',
  ];
  Language: any = ['This month', 'This week', 'Today'];
  loader: boolean = false;
  userQuery!: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean = false;
  highcharts = Highcharts;
  loading: boolean;
  chartData: any;
  count: any = [];
  data: any = [];
  time: any = [];

  constructor(private service: AdminService) {}

  ngOnInit(): void {
    // this.items = [
    //   { name: 'Sent', number: 600 },
    //   { name: 'Delivered', number: 400 },
    //   { name: 'Failed', number: 100 },
    //   {
    //     name: 'Unknown',
    //     number: 50,
    //   },
    // ];
    // this.freetrailChartConversionBtn();

    this.userApisList();
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);
  }
  userApisList() {
    this.loading = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.liveFeedLogins().subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.tabeldata;
      this.loading = false;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalSubscriber;
      this.chartData = res.response;
      this.chartData.forEach((res: any) => {
        this.count.push(res.delivered);
        this.data.push(res.failed);
        this.time.push(res.time);
      });
      this.freetrailChartConversionBtn();

      this.items = [
        { name: 'Sent', number: res.totalSent },
        { name: 'Delivered', number: res.totalDelivered },
        { name: 'Failed', number: res.totalFailed },
        {
          name: 'Unknown',
          number: res.totalUnknown,
        },
      ];
    });
  }
  freetrailChartConversionBtn() {
    this.freetrailChartConversion = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'SMSes Live Analytics',
        align: 'left',
      },

      xAxis: {
        categories: this.time,
        crosshair: true,
        accessibility: {
          description: 'Countries',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      tooltip: {
        valueSuffix: '',
      },
      colors: ['#068466', '#f28383'],

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: 'Sent',
          data: this.count,
        },
        {
          name: 'Failed',
          data: this.data,
        },
      ],

      // chart: {
      //   type: 'bar',
      // },
      // accessibility: {
      //   announceNewData: {
      //     enabled: true,
      //   },
      // },
      // xAxis: {
      //   type: 'categories',
      //   categories: this.data,
      // },
      // yAxis: {
      //   title: {
      //     text: 'Referral program set up activity',
      //   },
      // },
      // legend: {
      //   enabled: true,
      //   reversed: true,
      // },
      // plotOptions: {
      //   series: {
      //     pointPadding: 0, // Adjust these values
      //     groupPadding: 0.2, // to control the bar height
      //     borderWidth: 0, // Optional: remove border to visually increase height
      //     dataLabels: {
      //       enabled: true,
      //       format: '{point.y}',
      //     },
      //   },
      // },
      // colors: ['#068466'],

      // tooltip: {
      //   headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      //   pointFormat:
      //     '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>',
      // },

      // series: [
      //   {
      //     name: this.data,
      //     data: this.count,
      //     // data: [
      //     //   {
      //     //     name: 'Smses Sent',
      //     //     y: 63.06,
      //     //     drilldown: 'Smses Sent',
      //     //   },
      //     //   {
      //     //     name: 'Opens',
      //     //     y: 19.84,
      //     //     drilldown: 'Opens',
      //     //   },
      //     //   {
      //     //     name: 'Clicks',
      //     //     y: 4.18,
      //     //     drilldown: 'Clicks',
      //     //   },
      //     //   {
      //     //     name: 'Invited',
      //     //     y: 4.12,
      //     //     drilldown: 'Invited',
      //     //   },
      //     //   {
      //     //     name: 'Leads',
      //     //     y: 2.33,
      //     //     drilldown: 'Leads',
      //     //   },
      //     //   {
      //     //     name: 'First Visits',
      //     //     y: 2.33,
      //     //     drilldown: 'First Visits',
      //     //   },
      //     // ],
      //   },
      // ],
    };
  }
}
