

<div class="main">
    <div class="free-trails_section">
<div class="media-email-field mt-4 ml-4 pl-4" style="width: 100%">
    <p>Free Trail</p>
                             <select class="" [(ngModel)]="freeTrailCardEvent" (change)="freeTrailEvent($event)">
                                <option value="" disabled>select</option>
                                <option >freeTrail</option>
                               </select>
                               </div>
<div class="media-email-field mt-4 ml-4 pl-4" style="width: 100%">
    <p>Subscription</p>
                             <select class="" [(ngModel)]="subCardEvent" (change)="subEvent($event)" >
                                <option value="" hidden [selected]="true">select</option>
                                <option >Subscription</option>
                               </select>
                               </div>

<app-loader *ngIf="loader"></app-loader>
                    <div class="modifications card-mode mt-5 ml-5" *ngIf="freeTrailCard">
                        <div class="card">
                            <div class="card-body">
                                <div class="deck-heder">
                                    <p>Modifications</p>
                                </div>
                                <div class="d-flex mt-4 ">
                                    <h5 class="mr-4">FreeTrail No of days</h5>
                                    <select class="ml-3" (change)="freeTrailNoOfDays($event)" >
                                <option value="" hidden [selected]="true">select</option>
                                <option [value]="day" *ngFor="let day of daysOfMonth">{{day}}</option>
                               </select>
                                </div>
<h6>{{trailerror}}</h6>
                                <div class="update-btn">
                                    <button type="button" class="cursor" (click)="updateFreeTrail()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>    
                    <div class="modifications card-mode s-mode mt-5 ml-5" *ngIf="susbscriptionCard">
                       <form [formGroup]="subscriptions">
              
                        <div class="card">
                            <div class="card-body">
                                <div class="deck-heder">
                                    <p>Modifications</p>
                                </div>
                                <div class="d-flex mt-4 ">
                                    <h5 class="price"> Price</h5>
                                  <input class="form-control" formControlName="price" placeholder="Enter subscription price">
                                </div>
                                  <div *ngIf="
                                  updatesubscriptions.price?.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block text-right">
                                Price is required
                              </div>
                                <div class="d-flex mt-4 ">
                                    <h5 class="city">City</h5>
                                    <select class="" formControlName="city">
                                <option value="" hidden [selected]="true">select city</option>
                                                             <option [value]="city" *ngFor="let city of citys">{{city}}</option>

                               </select>
                                
                                </div>
                                 <div *ngIf="
                                  updatesubscriptions.city?.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block text-right">
                                City is required
                              </div>
                                <div class="d-flex mt-4 ">
                                    <h5 class="mr-4">Category</h5>
                                    <select class="ml-3" formControlName="category" >
                                <option value="" hidden [selected]="true">select category</option>
                                                                <option [value]="category" *ngFor="let category of category">{{category}}</option>

                               </select>
                               
                                </div>
 <div *ngIf="
                                  updatesubscriptions.category?.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block text-right">
                                Category is required
                              </div>
                                <div class="d-flex mt-4 ">
                                    <h5 class="mr-5 pr-2">Type</h5>
                                    <select class="ml-4 pl-2" formControlName="type" >
                                <option value="" hidden [selected]="true">select type</option>
                                                                <option [value]="category" *ngFor="let category of type">{{category}}</option>

                               </select>
                               
                                </div>
 <div *ngIf="
                                  updatesubscriptions.type?.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block text-right">
                                type is required
                              </div>
                                <div class="d-flex mt-4 ">
                                    <h5 class="mr-4">Discount</h5>
                                    <select class="ml-4" formControlName="discount" >
                                <option value="" hidden [selected]="true">select type</option>
                                                                <option [value]="category" *ngFor="let category of Discount">{{category}}</option>

                               </select>
                               
                                </div>
 <div *ngIf="
                                  updatesubscriptions.discount?.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block text-right">
                                type is required
                              </div>
                                <div class="update-btn">
                                    <button type="button" class="cursor" (click)="updateSubs()">Update</button>
                                </div>
                            </div>
                        </div>
                              </form>
                    </div>    
    </div>
</div>
