<div class="main">
    <div class="header_L d-flex justify-content-between">
        <p>Update</p>
        <img src="../../../../assets/images/close2.svg" (click)="close()">
    </div>
    <!-- <app-loader *ngIf="loader"></app-loader> -->
    <div class="main_header_l px-4 mt-4 pb-4">
        <form [formGroup]="reviewForm">
    
        <div class=" mt-4">
            
            <div class="L-input">
                <h6>Display location Name</h6>
                <input type="text"  class="form-control" formControlName="followers"  >
            </div>
        </div>
        <div class=" mt-4">
            
            <div class="L-input">ks
                <h6>Sms display Name</h6>
                <input type="text"  formControlName="new" class="form-control" >
        <div *ngIf="c.new.errors?.required && submitted" class="invalid-feedback m-t d-block">Field is required </div>

            </div>
        </div>
        </form>
         <div class="submit">
            <a class="cursor" (click)="submit()">Update</a>
        </div>
        </div>
        </div>
      