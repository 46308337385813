import { DatePipe, JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-smsreq',
  templateUrl: './smsreq.component.html',
  styleUrls: ['./smsreq.component.css'],
})
export class SmsreqComponent implements OnInit {
  smsReq: boolean = true;
  success: boolean = false;
  locations: any;
  dummydata: FormGroup;
  loading: boolean;
  Submitted: boolean;
  constructor(
    private router: Router,
    private service: AdminService,
    private fb: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.dummydata = this.fb.group({
      location: ['', [Validators.required]],
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],

      sms: ['', [Validators.required]],
      clicks: ['', [Validators.required]],
      review: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.service.locationsNames().subscribe((locations) => {
      console.log(locations);
      this.locations = locations.data;
    });
  }
  back() {
    this.router.navigate(['/testing']);
  }
  submit() {
    // console.log(this.dummydata.value);
    // console.log(
    //   this.datepipe.transform(this.dummydata.value.start, 'dd/MM/yyyy')
    // );
    if (this.dummydata.valid) {
      this.loading = true;
      let obj = {
        locationName: this.dummydata.value.location,
        smsSent: this.dummydata.value.sms,
        clicks: this.dummydata.value.clicks,
        reviewed: this.dummydata.value.review,
        dateRange: {
          start: this.datepipe.transform(
            this.dummydata.value.start,
            'yyyy/MM/dd'
          ),
          end: this.datepipe.transform(this.dummydata.value.end, 'yyyy/MM/dd'),
        },
      };
      this.service.generateSms(obj).subscribe((result) => {
        console.log(result);
        this.loading = false;

        this.success = true;
        this.smsReq = false;
      });
      console.log(obj);
    } else {
      this.Submitted = true;
    }
  }
  add() {
    this.dummydata.reset();
    this.success = false;
    this.smsReq = true;
  }
  get c(): { [key: string]: AbstractControl } {
    return this.dummydata.controls;
  }
}
