<div class="main">
    <div class="header_L d-flex justify-content-between">
        <p>Add URLS</p>
        <img src="../../../../assets/images/close2.svg" (click)="close()">
    </div>
    <app-loader *ngIf="loader"></app-loader>
    <div class="main_header_l px-4 mt-4 pb-4">
        <form [formGroup]="reviewForm">
       
        <div class="mt-4">
          
            <div class="L-input">
                <h6> Instagram Proflie</h6>

                <input type="text" formControlName="id" class="form-control" readonly>
            </div>
        </div>
        <div class=" mt-4">
            
            <div class="L-input">
                <h6>Embed URL</h6>
                <input type="text" formControlName="embedded" class="form-control" >
        <div *ngIf="c.embedded.errors?.required && submitted" class="invalid-feedback m-t d-block">Field is required </div>

            </div>
        </div>
        <!-- <div class=" mt-4">
            
            <div class="L-input">
                <h6>Follow URL</h6>
                <input type="text" formControlName="followUrl" class="form-control" >
        <div *ngIf="c.followUrl.errors?.required && submitted" class="invalid-feedback m-t d-block">Field is required </div>

            </div>
        </div> -->
        </form>
         <div class="submit">
            <a class="cursor" (click)="submit()">Save urls</a>
        </div>
        </div>
        </div>
      