<div class="main">
    <div class="header_L d-flex justify-content-between">
        <p>Add Review Details</p>
        <img src="../../../../assets/images/close2.svg" (click)="close()">
    </div>
    <app-loader *ngIf="loader"></app-loader>
    <div class="main_header_l px-4 mt-4 pb-4">
        <form [formGroup]="reviewForm">
        <div class="d-flex">
            <div class="L-h1 mr-3 mt-2">
                <h6>Location Name</h6>
            </div>
            <div class="L-input">
                <!-- <select formControlName="name" class="form-control" [(ngModel)]="locationName">
                  <option>{{locationName}}</option>
                </select> -->
                <input type="text" formControlName="name" readonly class="form-control">
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="L-h1 mr-4 pr-4 mt-2">
                <h6>Location ID</h6>
            </div>
            <div class="L-input">
                <input type="text" formControlName="id" class="form-control" readonly>
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="L-h1 mr-4 pr-2 mt-2">
                <h6>Reviewed by</h6>
            </div>
            <div class="L-input pl-1">
                 <!-- <select  class="form-control" placeholder="Please select" formControlName="reviewName">
                  <option value="" selected hidden disabled>Please select</option>
                  <option [value]="review" *ngFor="let review of customer">{{review}}</option>
                </select> -->
                <input type="text" class="form-control" formControlName="reviewName" placeholder="Enter Reviewer Name">
        <div *ngIf="c.reviewName.errors?.required && subscribeSubmitted" class="invalid-feedback m-t d-block">Reviewer Name is required </div>

            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="L-h1 mr-5 pr-5 mt-2">
                <h6>Stars</h6>
            </div>
            <div class="L-input">
                <star-rating [starType]="'svg'" formControlName="stars"  size="large" space="around"   [hoverEnabled]="true"
            (starClickChange)="onClick($event)"
            (ratingChange)="onRatingChange($event)"
            (hoverRatingChange)="onHoverRatingChange($event)"></star-rating>
        <div *ngIf="stars" class="invalid-feedback m-t d-block">Stars is required </div>

            </div>
        </div>
          <div class="d-flex mt-4">
            <div class="L-h1 mr-5 pr-4 mt-2">
                <h6>Review</h6>
            </div>
            <div class="L-input pl-2">
                <textarea type="text" class="form-control " formControlName="review" placeholder="Enter Review"></textarea>
        <div *ngIf="c.review.errors?.required && subscribeSubmitted" class="invalid-feedback m-t d-block">Review is required </div>

            </div>
        </div>
</form>
        <div class="submit">
            <button class="cursor" (click)="submit()">Submit</button>
        </div>
    </div>
</div>