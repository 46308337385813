

<div class="sidenavbar-heade main">
 
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Visitors</h1>
        </div>
       <div class="d-flex   language-header">
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
            
                <option  *ngFor="let item of Language" [selected]="item == 'This month'" value="{{item}}">{{item}}</option>
            </select>
        </div>
     </div>

    
       <div class="row card-section mt-md-4">
    <div class="col-md-3" *ngFor="let item of items">
    
      <div class="card mr-md-5" style="height: 100px;">
            <div class="card-body review_one">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
    
    </div>
    </div>
     
    <div class="col-md-8 mb-md-5">
        <div class="vistorchart">
    <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="vistorsChart" 
             style="width: 800px; height: 500px;display:block"></highcharts-chart>
        </div>
  
    </div>
  </div>
  
      
  

      <div >
        <div class="type">

            <div class="d-flex search_view">
                <div class="mr-auto">
                <input (keyup)="applyFilter($event)" placeholder="Search a customer" >

                </div>
                <div>
                <img src="../../../assets/images/search.png">

                </div>
            </div>
            <div class="type d-flex justify-content-xl-around">
                
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Locations</p>
                    <select (change)="location($event)">
                        <option>select</option>
                        <option>saloon</option>
                        <option>medical</option>
                        <option>restaurent</option>
                        <option>general store</option>
                        <option>coaching</option>
                        <option>food corner</option>
                        <option>pc world</option>
                        <option>health center</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">City</p>
                    <select (change)="city($event)">
                        <option>select</option>

                        <option>vizag</option>
                        <option>hyderabad</option>
                        <option>mumbai</option>
                        <option>dharmsala</option>
                        <option>kolkata</option>
                        <option>srinagar</option>
                        <option>delhi</option>
                        <option>pune</option>
                        <option>gurgaon</option>
                        <option>banglore</option>
                        <option>ranchi</option>
                        <option>indore</option>
                        <option>kanpur</option>
                        <option>lucknow</option>
                        <option>bhopal</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Reviews</p>
                    <select (change)="review($event)">
                        <option>select</option>

                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                        <option>18</option>
                        <option>20</option>
                    </select>
                </div>
                
            </div>
        </div>
      </div>
     <div class="table-data">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> Cid </th>
    <td mat-cell *matCellDef="let element"> {{element._id}} </td>
  </ng-container>

 
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> V_name </th>
    <td mat-cell *matCellDef="let element"> {{element.first_name}}</td>
  </ng-container>
 
  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> Phone </th>
    <td mat-cell *matCellDef="let element">{{element.phone}}</td>
  </ng-container>

 
  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> No of locations </th>
    <td mat-cell *matCellDef="let element"> {{element.no_of_location}} </td>
  </ng-container>
  <ng-container matColumnDef="City">
    <th mat-header-cell *matHeaderCellDef> No of locations </th>
    <td mat-cell *matCellDef="let element"> {{element.city}} </td>
  </ng-container>
  <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> Visits </th>
    <td mat-cell *matCellDef="let element"> {{element.visits}} </td>
  </ng-container>
  <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> Reviews </th>
    <td mat-cell *matCellDef="let element"> {{element.reviews}} </td>
  </ng-container>
  <ng-container matColumnDef="subscription">
    <th mat-header-cell *matHeaderCellDef> Referrals </th>
    <td mat-cell *matCellDef="let element"> {{element.referrals}} </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> location </th>
    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">view </div></td>
  </ng-container>
 

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>





     <div class="visitors_city">
        <div class="city_v d-flex mt-md-4">
           <div class="city-header customer_header mr-auto">
            <h1>Visits by City</h1>
           </div>
           <div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">state</p>
                    <select>
                        <option>Telagana</option>
                        <option>Andra pradesh</option>
                    </select>
                </div>
           </div>
        </div>

        <div class="row mr-3">
            <div class="col-md-6">
                <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartfirstCity" 
             style="display:block"></highcharts-chart>
            </div>
            <div class="col-md-6">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartSecondCity" 
             style="display:block"></highcharts-chart>
            </div>
        </div>
        <div class="row mt-md-4 mr-3">
            <div class="col-md-6">
                <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartThirdCity" 
             style="display:block"></highcharts-chart>
            </div>
            <div class="col-md-6">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartFourthCity" 
             style="display:block"></highcharts-chart>
            </div>
        </div>
     </div>
      
</div>
   