import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
export interface PeriodicElement {
  Cid: any;
  Cname: any;
  ctype: any;
  Locations: any;
  singupdate: any;
  freetrail: any;
  subscription: any;
  status: any;
  clicks: any;
  leads: any;
  firstvisits: any;
  conversion: any;
  Revenue: any;
  action: any;
}

@Component({
  selector: 'app-cancelation',
  templateUrl: './cancelation.component.html',
  styleUrls: ['./cancelation.component.css'],
})
export class CancelationComponent implements OnInit {
  loading: boolean;
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Locations',
    'singupdate',
    'freetrail',
    'subscription',
    'status',
    'clicks',

    'action',
  ];
  displayedColumns1: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Locations',
    'singupdate',
    'freetrail',
    'subscription',
    'status',
    'clicks',

    'action',
  ];
  Language: any = ['This month', 'This week', 'Today'];
  loader: boolean = false;
  userQuery!: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: any[] = [];
  ELEMENT_DATA1: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
  dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean = false;
  highcharts = Highcharts;
  chartOptionsRevered: any;
  chartOptionsfreetrailCancel: any;
  chartOptionsSubscriptioncancel: any;
  chartOptionsCancellationtimeline: any;
  chartoptionsCancellationsByBusinessCategories: any;
  chartoptionsCancellationsByCity: any;
  chartoptionsCancellationsReason: any;
  cancelationsCity: any;
  ratingCountView: any = [];
  ratingCountCity: any = [];
  cancellationReasonsCount: any;
  cancellationReasonsCountView: any = [];
  cancellationReason: any = [];
  subscriptionResponseCountView: any = [];
  subscriptionResponse: any = [];
  freetrailResponseCountView: any = [];
  freetrailResponse: any = [];
  constructor(private service: AdminService) {}

  ngOnInit(): void {
    this.userApisList();
    this.chartOptionsRevered = {
      chart: {
        type: 'area',
      },
      title: {
        text: 'Cancellations',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Cancellations',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#160FE2', '#076611ab'],
      series: [
        {
          name: 'Cancellations',
          data: [100, 1000, 2500, 500],
        },
      ],
    };

    this.chartOptionsCancellationtimeline = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Avg 2-months retention ',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: [
          'Within one month',
          'Within 2 months',
          'Within 3 months',
          'Within 4 months',
          'Within 5 months',
          'Within 6 months',
        ],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Avg 2-months retention ',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#dbb0e8a1'],
      series: [
        {
          name: ' Avg 2-months retention',
          data: [40000, 20000, 10000, 20000, 10000, 15000],
        },
      ],
    };
  }
  chartoptionsCancellationsByBusinessCategoriesBtn() {
    this.chartoptionsCancellationsByBusinessCategories = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'categories',
        categories: ['Food', 'Saloon', 'Beauty', 'Dental', 'Skincare'],
      },
      yAxis: {
        title: {
          text: 'Referral program set up activity',
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.2, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
      },
      colors: ['#d8ccff'],

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>',
      },

      series: [
        {
          // name: this.data,
          // data: this.count,
          data: [
            {
              name: 'Food',
              y: 150000,
              drilldown: 'Food',
            },
            {
              name: 'Saloon',
              y: 100000,
              drilldown: 'Saloon',
            },
            {
              name: 'Beauty',
              y: 50000,
              drilldown: 'Beauty',
            },
            {
              name: 'Dental',
              y: 60000,
              drilldown: 'Dental',
            },
            {
              name: 'Skincare',
              y: 20000,
              drilldown: 'Skincare',
            },
          ],
        },
      ],
    };
    this.chartoptionsCancellationsByCity = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'categories',
        categories: this.ratingCountCity,
      },
      yAxis: {
        title: {
          text: 'Cancellation by city',
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.2, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
      },
      colors: ['#ffc5c5'],

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>',
      },

      series: [
        {
          name: this.ratingCountCity,
          data: this.ratingCountView,
          // data: [
          //   {
          //     name: 'Hyderabad',
          //     y: 150000,
          //     drilldown: 'Hyderabad',
          //   },
          //   {
          //     name: 'Mumbai',
          //     y: 100000,
          //     drilldown: 'Mumbai',
          //   },
          //   {
          //     name: 'Chennai',
          //     y: 50000,
          //     drilldown: 'Chennai',
          //   },
          //   {
          //     name: 'Bengalore',
          //     y: 60000,
          //     drilldown: 'Bengalore',
          //   },
          //   {
          //     name: 'Pune',
          //     y: 20000,
          //     drilldown: 'Pune',
          //   },
          // ],
        },
      ],
    };
    this.chartoptionsCancellationsReason = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'categories',
        categories: this.cancellationReason,
      },
      yAxis: {
        title: {
          text: 'Top reasons Why they cancel',
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.2, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
      },
      colors: ['#ffc5c5'],

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
      },

      series: [
        {
          name: this.cancellationReason,
          data: this.cancellationReasonsCountView,
          // data: [
          //   {
          //     name: 'very expensive',
          //     y: 150000,
          //     drilldown: 'very expensive',
          //   },
          //   {
          //     name: 'not valuable',
          //     y: 100000,
          //     drilldown: 'not valuable',
          //   },
          //   {
          //     name: 'using alternative',
          //     y: 50000,
          //     drilldown: 'using alternative',
          //   },
          //   {
          //     name: 'poor product and bugs',
          //     y: 60000,
          //     drilldown: 'poor product and bugs',
          //   },
          //   {
          //     name: 'temporary cancel',
          //     y: 20000,
          //     drilldown: 'temporary cancel',
          //   },
          // ],
        },
      ],
    };
    this.chartOptionsfreetrailCancel = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'freetrail Cancellations',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: this.freetrailResponse,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'freetrail Cancellations',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#dbb0e8a1'],
      series: [
        {
          // name: this.freetrailResponse,
          data: this.freetrailResponseCountView,
        },
      ],
    };
    this.chartOptionsSubscriptioncancel = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Subscription Cancellations',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: this.subscriptionResponse,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Subscription Cancellations',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#dbb0e8a1'],
      series: [
        {
          // name: this.subscriptionResponse,
          data: this.subscriptionResponseCountView,
        },
      ],
    };
  }
  userApisList() {
    this.loading = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.cancellation(this.userQuery).subscribe((res) => {
      console.log(res);
      // this.ELEMENT_DATA = res.data.leads;
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;
      this.totalSummary = res.totalActiveFreeTrails;

      this.cancelationsCity = res.cancellationsByCity;
      this.cancelationsCity.forEach((response: any) => {
        this.ratingCountView.push(response.cancellationCount);
        this.ratingCountCity.push(response.city);
        console.log(this.ratingCountView);
      });
      this.cancellationReasonsCount = res.cancellationReasonsCount;
      this.cancellationReasonsCount.forEach((response: any) => {
        this.cancellationReasonsCountView.push(response.count);
        this.cancellationReason.push(response.reason);
        console.log(this.ratingCountView);
      });
      res.subscriptionResponse.forEach((response: any) => {
        this.subscriptionResponseCountView.push(response.value);
        this.subscriptionResponse.push(response.key);
        console.log(this.ratingCountView);
      });
      res.freetrailResponse.forEach((response: any) => {
        this.freetrailResponseCountView.push(response.value);
        this.freetrailResponse.push(response.key);
        console.log(this.ratingCountView);
      });
      this.chartoptionsCancellationsByBusinessCategoriesBtn();

      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
      // this.totalSummary = res.data.totalLeads;
      this.items = [
        {
          name: 'Total Cancelled Subscription',
          number: res.totalCancelledSubscription,
        },
        {
          name: 'Total Active Subscription',
          number: res.activeSubscription,
        },
        {
          name: 'Total Active Freetrails ',
          number: res.totalActiveFreeTrails,
        },

        {
          name: 'Total Cancelled Freetrails ',
          number: res.totalCancelledFreeTrails,
        },
      ];
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.loading = true;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    console.log(this.pageSize);
    this.loader = true;
    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage,
    };

    this.service.cancellation(this.userQuery).subscribe((res) => {
      console.log(res);
      // this.ELEMENT_DATA = res.data.leads;
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;
      this.cancelationsCity = res.cancellationsByCity;
      this.totalSummary = res.totalActiveFreeTrails;
      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
      // this.totalSummary = res.data.totalLeads;
    });
  }
}
