import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { PeriodicElement } from '../../customers/customers.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
import { RefdialogComponent } from './refdialog/refdialog.component';
import { MatDialog } from '@angular/material/dialog';
// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }

const ELEMENT_DATA: any[] = [];
const ELEMENT_DATA1: any[] = [];
@Component({
  selector: 'app-ref',
  templateUrl: './ref.component.html',
  styleUrls: ['./ref.component.css'],
})
export class RefComponent implements OnInit {
  formone: boolean = false;
  customData: any = [
    {
      id: 0,
      name: 'leads',
    },
    {
      id: 1,

      name: 'First visits data',
    },
    {
      id: 2,

      name: 'Rewards data',
    },
  ];
  customers: any = [
    {
      n: 'John',
      p: 99999999999,
      b: 999,
      v: '12/8/2024',
    },
    {
      n: 'John',
      p: 99999999999,
      b: 999,
      v: '12/8/2024',
    },
  ];
  leads: boolean = false;
  leadsBar: boolean = true;
  fir: boolean = false;
  activeLink: any;
  firstvisited: boolean;
  displayedColumns: string[] = [
    'position',
    'name',
    'weight',
    'symbol',
    'firstvisited',
    'checked',
    'status',
  ];
  dataSource = ELEMENT_DATA;
  displayedColumns1: string[] = [
    'id',
    'phone',
    'name',
    'program',
    'hold',
    'value',
    'status',
  ];
  dataSource1 = ELEMENT_DATA1;

  selection = new SelectionModel<PeriodicElement>(true, []);

  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  refferal: boolean;
  locations: any;
  dummydata: FormGroup;
  loading: boolean;
  locationName: any;
  customersNumber: any;
  cus: boolean;
  leadsError: any;
  leadsData1: any;
  refLOads: boolean;
  leadsLOads: boolean;
  Error: any;
  constructor(
    private router: Router,
    private service: AdminService,
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private dialog: MatDialog
  ) {
    this.dummydata = this.fb.group({
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],

      count: ['', [Validators.required]],
      customerMobile: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.activeLink = this.customData[0].id;

    this.service.locationsNames().subscribe((locations) => {
      console.log(locations);
      this.locations = locations.data;
    });
  }
  review(e: any) {
    console.log(e.target.value);
    this.locationName = e.target.value;
    let obj = {
      locationName: this.locationName,
    };
    this.service.customersData(obj).subscribe(
      (locations) => {
        console.log(locations.mobileNumbers);
        this.customersNumber = locations.mobileNumbers;
      },
      (error) => {
        this.Error = 'No customers found for this location';
      }
    );
    if (this.activeLink == 1) {
      this.leadsData();
    } else if (this.activeLink == 2) {
      this.refferalData();
    }
  }
  updateThreshold(data: any) {
    this.dialog.open(RefdialogComponent, {
      disableClose: true,
      data: { id: data },
    });
  }
  leadsData() {
    this.leadsLOads = true;
    let obj = {
      locationName: this.locationName,
    };
    this.service.leadsData(obj).subscribe(
      (locations) => {
        console.log(locations.mobileNumbers);
        // this.leadsData1 = locations.customers;
        this.dataSource = locations.data;
        this.leadsLOads = false;
      },
      (error) => {
        this.leadsError = error.error.message;
      }
    );
  }
  refferalData() {
    this.refLOads = true;

    let obj = {
      locationName: this.locationName,
    };
    this.service.referredData(obj).subscribe(
      (locations) => {
        console.log(locations.mobileNumbers);
        this.dataSource1 = locations.data;
        this.refLOads = false;

        // this.customersNumber = locations.mobileNumbers;
      },
      (error) => {
        this.leadsError = error.error.message;
      }
    );
  }
  getRandomThreshold(): number {
    return Math.floor(Math.random() * 5) + 1;
  }
  lead(id: any) {
    console.log(id);
    this.activeLink = id;
    if (id == 0) {
      this.leadsBar = true;
      this.firstvisited = false;
      this.refferal = false;
    } else if (id == 1) {
      this.refferal = false;
      this.leadsBar = false;
      this.firstvisited = true;
      this.leadsData();
    } else if (id == 2) {
      this.refferal = true;
      this.leadsBar = false;
      this.firstvisited = false;
      this.refferalData();
    }
  }
  updateVisited(id: any) {
    this.service.updateLeads(id).subscribe((data) => {
      this.leadsData();
      Swal.fire({
        text: 'Succefully Updated',
        showConfirmButton: true, // Hide the "OK" button
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
      });
    });
  }
  generate() {
    if (this.dummydata.valid) {
      this.loading = true;
      let obj = {
        count: this.dummydata.value.count as number,
        customerMobile: this.dummydata.value.customerMobile,
        locationName: this.locationName,
        dateRange: {
          start: this.datepipe.transform(
            this.dummydata.value.start,
            'yyyy/MM/dd'
          ),
          end: this.datepipe.transform(this.dummydata.value.end, 'yyyy/MM/dd'),
        },
      };
      this.service.generateLeads(obj).subscribe((result) => {
        console.log(result);
        this.leadsData1 = result.customers;

        this.loading = false;
        this.cus = true;
      });
      console.log(obj);
    }
  }
  back() {
    this.router.navigate(['/testing']);
  }
}
