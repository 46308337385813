import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-updatefollowersdialog',
  templateUrl: './updatefollowersdialog.component.html',
  styleUrls: ['./updatefollowersdialog.component.css'],
})
export class UpdatefollowersdialogComponent implements OnInit {
  instaId: any;
  instaFollowers: any;
  locationId: any;
  submitted: boolean;
  close() {
    this.dilaog.closeAll();
  }
  loader: any;
  reviewForm: FormGroup;

  constructor(
    private dilaog: MatDialog,
    private fb: FormBuilder,
    private service: AdminService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reviewForm = this.fb.group({
      new: ['', Validators.required],
      id: [''],
      followers: [''],
    });
    this.instaId = this.data.instaId;
    this.instaFollowers = this.data.followers;
    this.locationId = this.data.id;
  }

  ngOnInit(): void {
    this.reviewForm.controls['id'].setValue(this.instaId);
    this.reviewForm.controls['followers'].setValue(this.instaFollowers);
  }
  submit() {
    console.log(this.reviewForm);
    if (this.reviewForm.invalid) {
      this.submitted = true;
    } else {
      this.service
        .updatefollowers(this.locationId, this.reviewForm.value.new)
        .subscribe((data) => {
          console.log(data);
          Swal.fire({
            text: 'Succefully Added',
            showConfirmButton: true, // Hide the "OK" button
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
          });
          this.dilaog.closeAll();
        });
    }
  }

  get c(): { [key: string]: AbstractControl } {
    return this.reviewForm.controls;
  }
}
