import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-view-reviews',
  templateUrl: './view-reviews.component.html',
  styleUrls: ['./view-reviews.component.css'],
})
export class ViewReviewsComponent implements OnInit {
  checked: any = 2;
  items: any;
  locationId: any;
  reviews: any;
  error: any;
  loader: boolean;
  getRandomName() {
    const names = [
      'John',
      'Emma',
      'Olivia',
      'Liam',
      'Noah',
      'Ava',
      'Isabella',
      'Sophia',
      'Mason',
      'Mia',
      'James',
      'Charlotte',
      'Elijah',
      'Amelia',
      'Benjamin',
      'Harper',
      'Lucas',
      'Evelyn',
      'Henry',
      'Abigail',
      'Alexander',
      'Ella',
    ];
    return names[Math.floor(Math.random() * names.length)];
  }
  constructor(
    private dilaog: MatDialog,
    private service: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.locationId = this.data.id;
  }

  ngOnInit(): void {
    this.loader = true;
    let obj = {
      locationId: this.locationId,
    };
    this.service.reviewsManual(obj).subscribe(
      (review) => {
        this.loader = false;

        console.log(review);
        this.reviews = review.reviews;
      },
      (error) => {
        this.loader = false;

        this.error = error.error.message;
      }
    );
    this.items = Array.from({ length: 10 }, (_, index) => ({
      name: this.getRandomName(),
      rating: Math.floor(Math.random() * 5) + 1, // Random rating between 1 and 5
      description: `I took veg burger but too worst.everytime I expect to eat soft wheat burger in kfc but this time they served me with old stock burger`,
    }));

    console.log(this.items);
  }
  close() {
    this.error = null;

    this.dilaog.closeAll();
  }
}
