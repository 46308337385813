<div class="main">


    <app-loader *ngIf="loading"></app-loader>
    <div class="cus d-flex">
        <p>Id:&nbsp;</p>
        <h6>{{id}}</h6>
    </div>
    <div class="cus d-flex">
        <p>referred_by:&nbsp;</p>
        <h6>{{referred_by}}</h6>
    </div>
    <div class="cus d-flex">
        <p>referred_mobileNumber:&nbsp;</p>
        <h6>{{referred_mobileNumber}}</h6>
    </div>
    <div class="cus d-flex">
        <p>referrer_threshold:&nbsp;</p>
        <h6>{{referrer_threshold}}</h6>
    </div>
    <h5 class="mt-4">How many of friends have visited via Customer ID referral link?</h5>
     <div class="text-center">
  <input placeholder [value]="referrer_threshold" (input)="refe($event)">
    <br>
    <button class="cursor submit" (click)="update()">Update</button>
     </div>
  
</div>