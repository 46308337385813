import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css'],
})
export class SidenavbarComponent implements OnInit {
  sidemenuMainItems = [
    {
      icon: '../.',
      title: 'Customers',
      link: '/Customers',
      active: false,
    },
    {
      icon: '',
      title: 'Reviews',
      link: '/Reviews',
      active: false,
    },
    {
      icon: '../../../../assets/images/group.png',
      title: 'Visitors',
      link: '/visitors',
      active: false,
    },
    {
      icon: '../../assets/images/panel_images/tv-program.svg',
      title: 'Freetrails',
      link: '/Freetrails',
      active: false,
    },

    {
      icon: '../../assets/images/panel_images/prayer.svg',
      title: 'topics',
      active: false,
    },
    {
      icon: '../../assets/images/panel_images/donate.svg',
      title: 'power users',
      link: '/powerUser',
      active: false,
    },

    {
      icon: '../../assets/images/panel_images/cms.svg',
      title: 'Referrals',

      active: false,
    },

    {
      icon: '../../assets/images/panel_images/sf.svg',
      title: 'Customer Support',

      active: false,
    },
  ];

  storelogo = '../../assets/images/panel_images/sidenav-img.svg';
  // activeRoute: string;
  // activeRouteColor: string;
  // activeRouteColor1: string;
  // activeRouteColor2: string;
  routeParam: any;
  routeNavigate: any;
  customerNewList: any = false;
  freeTrailNewList: boolean = false;
  referrallist: boolean = false;
  livefeed: boolean;
  reviewlist: any = false;

  // Define a mapping of routes to colors
  // routeColors: any = {
  //   'loopkup-admin/dashboard': ['#0841db', '#000000', '#000000'],
  //   'loopkup-admin/summaries': ['#000000', '#0841db', '#000000'],
  //   'loopkup-admin/users': ['#000000', '#000000', '#0841db'],
  //   'loopkup-admin/publishers': ['#000000', '#000000', '#0841db'],
  // };
  constructor(private router: Router, private activateRoute: ActivatedRoute) {}

  ngOnInit() {
    this.routeParam = window.location.pathname.replace('/', '');
    // this.activateRoute.url.subscribe((url) => console.log(url));
    console.log(this.router.url);
    console.log('URL:' + window.location.href);
    console.log('Path:' + window.location.pathname);
    console.log('Host:' + window.location.host);
    console.log('Hostname:' + window.location.hostname);
    console.log('Origin:' + window.location.origin);
    console.log('Port:' + window.location.port);
    console.log('Search String:' + window.location.search);
    this.routeNavigate = this.routeParam;
    if (
      this.routeNavigate === 'Refferal/leads' ||
      this.routeNavigate === 'Refferal/Activations' ||
      this.routeNavigate === 'Refferal/Program-setup'
    ) {
      this.referrallist = true;
    }
    if (
      this.routeNavigate === 'Reviews/All-Locations' ||
      this.routeNavigate === 'Reviews/Weekly'
    ) {
      this.reviewlist = true;
    }
  }

  route(route: any) {
    this.router.navigate([`${route}`]);
    this.routeNavigate = route;
    // if (route == 'Customers') {
    //   this.customerNewList = true;
    //   this.freeTrailNewList = false;
    // } else if (route == 'Freetrails') {
    //   this.freeTrailNewList = !this.freeTrailNewList;
    //   this.customerNewList = false;
    // }
    this.customerNewList =
      route === 'Customers' ? !this.customerNewList : false;
    this.freeTrailNewList =
      route === 'Freetrails' ? !this.freeTrailNewList : false;
    // this.referrallist = route === 'Refferal/leads' ? !this.referrallist : false;
    if (
      route === 'Refferal/leads' ||
      route === 'Refferal/Activations' ||
      route === 'Refferal/Program-setup'
    ) {
      this.referrallist = true;
    }
    if (route === 'Reviews/All-Locations' || route === 'Reviews/Weekly') {
      this.reviewlist = true;
    }
    if (
      route === 'livefeed/reviews' ||
      route === 'livefeed/referral' ||
      route === 'livefeed/staff' ||
      route === 'livefeed/logins' ||
      route === 'livefeed'
    ) {
      this.livefeed = true;
    }
  }
}
