
<div class="main">
    <div class="selcect_btn" *ngIf="selectType">
      <div class="d-flex">
        <button  class="cursor button_M" (click)="merchant()">Merchants/ Staff</button>
        <button class="cursor button_M" (click)="visitors()">Visitors</button>
      </div>
    </div>
<app-loader *ngIf="loading"></app-loader>

    <div>
 <div class="selcect_btn merchant" *ngIf="selectTypeMerchant">
        <p class="ml-2">Select the campaign type</p>
      <div class="d-flex ">
        <button  class="cursor button_Merchant">Push</button>
        <button class="cursor button_Merchant" (click)="sms()">SMS</button>
      </div>
      <div class="d-flex">
        <button  class="cursor button_Merchant">Email</button>
        <button class="cursor button_Merchant">Whatsapp</button>
      </div>
    </div>
    <div class="selcect_btn merchant" *ngIf="selectTypeMerchantSMS">
        <p class="ml-2">Send SMS to merchant/Staff</p>
       <div>
       <h3 class="select_label">Requests_Sent</h3>
            <select  class="selectpicker" >
              <option value="">{{Req}}</option>
                <!-- <option>Requests_Sent</option> -->
            </select>
        </div>
       <div>
       <h3 class="select_label">Clicks_status</h3>
            <select  class="selectpicker" >
              <option value="">{{clicks}}</option>
                <!-- <option>Clicks_status</option> -->
            </select>


            <h6>Sent {{Req}} review requests and {{clicks}} of them have clicked</h6>

        <button  class="cursor button_M send_sms" (click)="sendSms()">Send SMS</button>

        </div>
    </div>
    <div class="selcect_btn merchant" *ngIf="selectTypeMerchantSMSNOW">
        <p class="ml-2">Send SMS to merchant/Staff</p>
      
       
        <div class="sendnow">
            <p>Hello {{location}}</p>
            <br>
            <p>
                 Last week, we sent of {{Req}} review requests to your customers and {{clicks}} of have clicked and you receive Keep growing your reviews with LocalFirst</p>
                 <br>
                 <p>Powered by</p>
                 <p>LocalFirst</p>
        </div>
        <button  class="cursor button_M send_sms" (click)="sendnow()">Send Now</button>

    </div>
    <div class="selcect_btn merchant" *ngIf="success">
        <h5 class="ml-2">Successfully Send SMS to merchant/Staff</h5>
      
       
        <a (click)="back()" class="cursor">Go back</a>

    </div>
    </div>
    <div>
 
    <div class="selcect_btn merchant" *ngIf="selectTypeVisitorSMS">
        <p class="ml-2">Send Review Reminder SMS</p>
       <div>
       
            <select  class="selectpicker" >
              <option value="">Select Visitors</option>
                <option>Select Visitors</option>
            </select>
        </div>
       <div>
       
            <select  class="selectpicker" >
              <option value="">Clicks_status</option>
                <option>Clicks_status</option>
            </select>


      

        </div>
       <div>
       
            <select  class="selectpicker" >
              <option value="">Reminder status</option>
                <option>Reminder status</option>
            </select>


      

        </div>
              <h6>We found 200 Visitors for Hyderbad with Not Clicked status</h6>

        <button  class="cursor button_M send_sms" (click)="sendSmsVisitor()">Send SMS</button>
    </div>
    <div class="selcect_btn merchant" *ngIf="selectTypeVisitorSMSNOW">
        <p class="ml-2">Review Reminder SMS Preview</p>
      
       
        <div class="sendnow">
            <p> Hi  </p>
            <br>
            <p>
               Just wanted to send you a friendly reminder to write us a review for our business  Review link: Pls, ignore if you’ve already given the review</p>
                 <br>
                 <p class="m-0">Powered by</p>
                 <p>LocalFirst</p>
        </div>
        <button  class="cursor button_M send_sms" (click)="sendnowVisitor()">Send Now</button>

    </div>
    <div class="selcect_btn merchant" *ngIf="successVisitor">
        <h5 class="ml-2">Review_Reminder SMS sent to 30 visitors</h5>
      
       
        <a (click)="backVisitor()" class="cursor">Go back</a>

    </div>
    </div>
   

    <a (click)="close()" class="cursor close">Close</a>
</div>