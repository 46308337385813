import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css'],
})
export class TestingComponent implements OnInit {
  items: any = [
    {
      name: 'SMS Requests',
      route: 'testing/smsRequests',
    },
    {
      name: 'Referralls',
      route: 'testing/refferal',
    },
    {
      name: 'Reviews',
      route: 'testing/smsrequest',
    },
    {
      name: 'Customers',
      route: 'testing/customers',
    },
  ];
  constructor(private router: Router) {}

  ngOnInit(): void {}

  route(route: any) {
    this.router.navigate([`${route}`]);
  }
}
