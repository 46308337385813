import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.css'],
})
export class OperationsComponent implements OnInit {
  daysOfMonth: any[] = [];
  citys: any = [];
  category: any = [];
  susbscriptionCard: boolean = false;
  freeTrailCard: boolean = false;
  freeTrailCardEvent: string | null = '';
  subCardEvent: string | null = '';
  trail: any = '';
  trailerror: string;
  loader: boolean;
  subscriptions: FormGroup;
  pushSubmitted: any;
  type: any = [];
  Discount: any = [];
  constructor(private service: AdminService, private fb: FormBuilder) {
    this.subscriptions = this.fb.group({
      category: ['', [Validators.required]],
      city: ['', [Validators.required]],
      price: ['', [Validators.required]],
      type: ['', [Validators.required]],
      discount: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.daysOfMonth = Array.from(
      { length: 30 },
      (_, i) => `${i + 1} day${i + 1 > 1 ? 's' : ''}`
    );

    console.log(this.daysOfMonth);

    for (let i = 1; i <= 100; i++) {
      this.Discount.push(`${i}%`);
    }
    this.city();
  }
  freeTrailEvent(e: any) {
    // this.trail = e.target.value;
    console.log(this.trail);
    this.subCardEvent = '';
    this.freeTrailCard = true;
    this.susbscriptionCard = false;
  }
  freeTrailNoOfDays(e: any) {
    this.trail = e.target.value;
    console.log(this.trail.slice(0, 2));
    if (this.trail != '') {
      this.trailerror = '';
    }
  }
  updateFreeTrail() {
    // return;
    if (this.trail == '') {
      this.trailerror = 'Please select';
    } else {
      this.loader = true;
      this.service.updatefreetrail(this.trail.slice(0, 2)).subscribe((data) => {
        console.log(data);
        this.loader = false;

        Swal.fire({
          text: 'Succefully update freeTrail',
          showConfirmButton: true, // Hide the "OK" button
          icon: 'success', // You can change the icon type
          timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        });
        this.trail = '';
        this.freeTrailCard = false;
        this.freeTrailCardEvent = '';
      });
    }
  }
  updateSubs() {
    // return;
    if (this.subscriptions.invalid) {
      this.pushSubmitted = true;
    } else {
      this.loader = true;
      this.pushSubmitted = false;
      let obj = {
        name: 'subscription',
        category: this.subscriptions.value.category,
        price: `${this.subscriptions.value.price}/Day`,
        city: this.subscriptions.value.city,
      };
      this.service
        .updateSubs(
          this.subscriptions.value.category,
          this.subscriptions.value.price,
          this.subscriptions.value.city,
          this.subscriptions.value.type,
          this.subscriptions.value.discount
        )
        .subscribe((data) => {
          console.log(data);
          this.loader = false;
          this.subscriptions.reset();
          Swal.fire({
            text: 'Succefully updated subscription',
            showConfirmButton: true, // Hide the "OK" button
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
          });
          this.subCardEvent = '';
          this.susbscriptionCard = false;
        });
    }
  }
  subEvent(e: any) {
    this.freeTrailCard = false;
    this.susbscriptionCard = true;
    this.freeTrailCardEvent = '';
  }
  get updatesubscriptions(): { [key: string]: AbstractControl } {
    return this.subscriptions.controls;
  }
  city() {
    this.service.subData().subscribe((options) => {
      console.log(options);
      options.data.forEach((option: any) => {
        this.category.push(option.category);
        this.citys.push(option.city);
        this.type.push(option.type);
      });
    });
    // this.service.categoryOptions().subscribe((options) => {
    //   console.log(options);
    //   this.category = options.category;
    // });
  }
}
