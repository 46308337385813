






<div class="ml-5 mt-3 main">
     <div class="d-flex">
                      <div class="push-img">
                        <img src="../../assets/images/subscribe (1).png" width="40px" class="mr-3" />
                        <!-- <img src="../../assets/images/panel_images/pray (1).svg" class="mr-3"> -->
                      </div>
                      <div class="push-header mt-1">
                        <h5>Push Notifications</h5>
                        
                      </div>
                    </div>

<div class="push-nofications-section pt-2">
                    <div class="row event-row">
                      <div class="col-md-8 push-notofications-col">
                        <form [formGroup]="PushNotifications">
                          <div class="d-flex ml-4 mt-2">
                            
                            <div class="checkbox-section radio-button-section mt-3">
                              <mat-radio-group name="radiobtn2" formControlName="deviceType1"  [(ngModel)]="type" (change)="radio($event)">
                                <mat-radio-button 
             [disableRipple]="false"
                                  value="all" class="example-margin mr-3">All</mat-radio-button>
                              </mat-radio-group>
                              <!-- <div *ngIf="
                                  updatePushNotificaitons.deviceType.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Please select
                              </div> -->
                            </div>
                           <div class="media-email-field mt-0 ml-5 pl-3" style="width: 100%">
                               <select class=""    formControlName="deviceType" [(ngModel)]="type1" (change)="locationsChange($event)">
                                <option value="">select</option>
                                <option [value]="city" *ngFor="let city of locations">{{city}}</option>
                               </select>
                                <div *ngIf="
                                  updatePushNotificaitons.deviceType.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Please select
                              </div>
                                <div *ngIf="
                                  updatePushNotificaitons.deviceType1.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Please select
                              </div>
                               </div>
                          </div>
                          <p *ngIf="locationsEvent !=''" class="e"> you have selected {{locationsEvent}}</p>
                          <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>Title:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-5 pl-5" style="width: 100%">
                              <mat-form-field>
                                <mat-label>Enter Title</mat-label>
                                <input formControlName="title" appInputRestriction 
                                  matInput />
                              </mat-form-field>
                              <div *ngIf="
                                  updatePushNotificaitons.title.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Title is required
                              </div>
                              <div *ngIf="
                                  updatePushNotificaitons.title.errors
                                    ?.pattern && pushSubmitted
                                " class="invalid-feedback d-block">
                                Title is required
                              </div>
                            </div>
                          </div>
                          <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>Description:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                                <textarea class="" appInputRestriction
                                  formControlName="description"></textarea>
                              <div *ngIf="
                                  updatePushNotificaitons.description.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Description is required
                              </div>
                            </div>
                          </div>
                           <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>Redirected:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-4 pl-4" style="width: 100%">
                               <select class=""    formControlName="redirected">
                                <option value="" [selected]="true">select</option>
                                <option [value]="route" *ngFor="let route of redirected">{{route}}</option>
                               </select>
                              <div *ngIf="
                                  updatePushNotificaitons.city?.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Field is required
                              </div>
                            </div>
                          </div>
                          <!-- <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>Location:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-5 pl-3" style="width: 100%">
                              <mat-form-field>
                                <mat-label>Enter location name</mat-label>
                                <input matInput appInputRestriction
                                  formControlName="location" />
                              </mat-form-field>
                              <div *ngIf="
                                  updatePushNotificaitons.location.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                Location is required
                              </div>
                            </div>
                          </div> -->
                          <!-- <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>redirected:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-5 pl-5" style="width: 100%">
                               <select class=""    formControlName="city">
                                <option value="" [selected]="true">select</option>
                                <option [value]="city" *ngFor="let city of citys">{{city}}</option>
                               </select>
                              <div *ngIf="
                                  updatePushNotificaitons.city?.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                City is required
                              </div>
                            </div>
                          </div>
                          <div class="d-flex ml-4 mt-3">
                            <div class="push-heading pr-1">
                              <p>Category:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-4 pl-4" style="width: 100%">
                             <select class="" formControlName="category">
                                <option value="" [selected]="true">select</option>
                                <option [value]="category" *ngFor="let category of category">{{category}}</option>
                               </select>
                              <div *ngIf="
                                  updatePushNotificaitons.category?.errors
                                    ?.required && pushSubmitted
                                " class="invalid-feedback d-block">
                                category is required
                              </div>
                            </div>
                          </div>
                          <div class="d-flex ml-4  mt-3">
                            <div class="push-heading pr-3">
                              <p>Reviews:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-4 pl-4" style="width: 100%">
                            <select class="" formControlName="review">
                                <option value="" [selected]="true">select</option>
                                <option [value]="review" *ngFor="let review of review">{{review}}</option>
                               </select>
                              <div *ngIf="
                                  updatePushNotificaitons.review?.errors
                                    ?.pattern && pushSubmitted
                                " class="invalid-feedback d-block">
                                review is required
                              </div>
                            </div>
                          </div>
                          <div class="d-flex ml-4 mt-3">
                            <div class="push-heading">
                              <p>Destination:</p>
                            </div>
                            <div class="media-email-field mt-0 ml-4 pl-3" style="width: 100%">
                              <mat-form-field>
                                <mat-label>Enter URL</mat-label>
                                <input matInput appInputRestriction  
                                  formControlName="url" />
                              </mat-form-field>
                              <div *ngIf="
                                  updatePushNotificaitons.url?.errors
                                    ?.pattern && pushSubmitted
                                " class="invalid-feedback d-block">
                                Url is invalid
                              </div>
                            </div>
                          </div>
                          <div class="d-flex ml-4 mt-4">
                            <div class="source-heading" style="width: 150px">
                              <p>Upload Image:</p>
                            </div>
                            <div class="upload-img">
                              <input style="display: none" formControlName="file" type="file"
                                accept="image/png, image/jpeg" (change)="
                                  onFileSelectedPushNotifications($event)
                                " #fileInput90 />
                              <button class="file-btn btn" (click)="fileInput90.click()">
                                <img *ngIf="pushnotificationsUrl == null"
                                  src="../../assets/images/image (13).png" class="i-img" width="30px"/>
                                <img *ngIf="pushnotificationsUrl != null" [src]="pushnotificationsUrl"
                                  height="50" alt="" class="upload-img-1" width="50px"/>
                              </button>
                              <p *ngIf="pushnotificationsUrl == null" class="text-centre">
                                Upload Image
                              </p>
                            </div>
                          </div>
                          <div *ngIf="
                              updatePushNotificaitons.file.errors
                                ?.required && pushSubmitted
                            " class="push-invalid invalid-feedback d-block">
                            File is required
                          </div> -->
                      
                          <div class="button-section-tv-programs ml-4 pl-2 mt-5 pb-4">
                            <button type="button" class="btn create-btn"  (click)="
                             CreatePushNotifications()
                              ">
                              Create
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
</div>
