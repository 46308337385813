<div class="main">
    <div class="header_L d-flex justify-content-between">
        <p>Sms Analytics</p>
        <img src="../../../../assets/images/close2.svg" (click)="close()">
    </div>
    <app-loader *ngIf="loader"></app-loader>
    <div class="main_header_l px-4 mt-4 pb-4">
        <form [formGroup]="reviewForm">
       
        <div class="d-flex mt-4">
            <div class="L-h1 mr-4 pr-4 mt-2">
                <h6>Location ID</h6>
            </div>
            <div class="L-input">
                <input type="text" formControlName="id" class="form-control" readonly>
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="L-h1 mr-5 pr-4 mt-2">
                <h6>SmsSent</h6>
            </div>
            <div class="L-input">
                <input type="text" formControlName="SmsSent" class="form-control" >
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="L-h1 mr-5 pr-5 mt-2">
                <h6>Open</h6>
            </div>
            <div class="L-input">
                <input type="text" formControlName="Open" class="form-control" >
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="L-h1 mr-4 pr-5 mt-2">
                <h6>Delivered</h6>
            </div>
            <div class="L-input">
                <input type="text" formControlName="Delivered" class="form-control" >
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="L-h1 mr-5 pr-5 mt-2">
                <h6>Clicks</h6>
            </div>
            <div class="L-input">
                <input type="text" formControlName="Clicks" class="form-control" >
            </div>
        </div>
        
        <div class="d-flex mt-4">
            <div class="L-h1 mr-5 pr-5 mt-2">
                <h6>Type</h6>
            </div>
            <div class="L-input">
                <select  class="form-control" placeholder="Please select" formControlName="reviewName">
                  <option value="" selected hidden disabled>Please select</option>
                  <option [value]="review" *ngFor="let review of customer">{{review}}</option>
                </select>
        <!-- <div *ngIf="stars" class="invalid-feedback m-t d-block">Stars is required </div> -->

            </div>
        </div>
          <!-- <div class="d-flex mt-4">
            <div class="L-h1 mr-5 pr-4 mt-2">
                <h6>Review</h6>
            </div>
            <div class="L-input pl-2">
                <textarea type="text" class="form-control " formControlName="review" placeholder="Enter Review"></textarea>
        <div *ngIf="c.review.errors?.required && subscribeSubmitted" class="invalid-feedback m-t d-block">Review is required </div>

            </div>
        </div> -->
        <!-- <h4 class="succesfully mt-md-3" *ngIf="csvsucceed">CSV file uploaded successfully</h4> -->
    <!-- <p class="ml-md-5">Upload csv</p> -->
    <div class="pl-1 mt-3">
      <input style="display: none" accept=".csv" formControlName="review"  (change)="onFileSelectedTvProgram($event)" #fileInput type="file">
      <button class="btn-upload" (click)="fileInput.click()">
        <img src="../../assets/images/upload.svg" class="mr-2">Upload CSV</button>
    </div>
    <div class="row mt-3" *ngIf="attachmentFile">
      <div class="col-md-5">
        <div class="file-upload-view-section">
          <div class="d-flex">
            <div class="mr-auto">
              <div class="image-section d-flex">
                <div class="img-section">
                  <img src="../../assets/images/attach-file.png" class="mr-2 flie-img">
                </div>
                <div class="view_details">
                  <h5>{{fileName}}</h5>
                </div>
              </div>
            </div>
            <!-- <div class="close-img cursor">
              <img src="../../assets/images/close-pdf.png" width="30px">
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-6"></div>
    </div>
     
 
</form>
        <div class="submit">
            <button class="cursor" (click)="submit()">Submit</button>
        </div>
    </div>
</div>