import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-tracking-dialog',
  templateUrl: './tracking-dialog.component.html',
  styleUrls: ['./tracking-dialog.component.css'],
})
export class TrackingDialogComponent implements OnInit {
  favoriteSeason: string;
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  Onboarding: any[] = [
    {
      name: 'Mobile number verification',
      boolean: true,
    },
    {
      name: 'Basic details',
      boolean: true,
    },
    {
      name: 'Google myBiz authentication',
      boolean: false,
    },
  ];
  firstDay: any[] = [
    {
      name: 'First review request sent',
      boolean: true,
    },
    {
      name: 'First referral request sent',
      boolean: false,
    },
    {
      name: 'Team invited',
      boolean: false,
    },
  ];
  days: any[] = [
    {
      name: 'number of review requests sent',
      boolean: true,
    },
    {
      name: ' number of reviews received',
      boolean: false,
    },
    {
      name: 'referral requests sent',
      boolean: false,
    },
    {
      name: '  leads generated',
      boolean: true,
    },
    {
      name: 'number of customer visits',
      boolean: false,
    },
  ];
  trail: any[] = [
    {
      name: 'Attempted feee trail',
      boolean: false,
      failed: true,
    },
    {
      name: 'Activated',
      boolean: false,
      failed: true,
    },
    {
      name: 'Canceled',
      boolean: false,
      failed: false,
    },
    {
      name: 'Expired',
      boolean: true,
      failed: false,
    },
  ];
  sub: any[] = [
    {
      name: 'activate',
      boolean: false,
      failed: true,
    },
    {
      name: 'Expired',
      boolean: false,
      failed: true,
    },
    {
      name: 'Cancelled',
      boolean: false,
      failed: false,
    },
    {
      name: 'Supended',
      boolean: true,
      failed: false,
    },
  ];
  pay: any[] = [
    {
      name: 'Failed',
      boolean: false,
      failed: true,
    },
    {
      name: 'Successful',
      boolean: false,
      failed: true,
    },
    {
      name: 'Pending',
      boolean: false,
      failed: false,
    },
    {
      name: 'in-progress',
      boolean: true,
      failed: false,
    },
  ];
  Day: any[] = [
    {
      name: 'Review Requests Sent',
      boolean: false,
      failed: true,
    },
    {
      name: 'New Reviews Received',
      boolean: false,
      failed: true,
    },
    {
      name: 'Referral Requests Sent',
      boolean: false,
      failed: false,
    },
    {
      name: 'Leads Generated',
      boolean: true,
      failed: false,
    },
    {
      name: 'New Visits from the Leads',
      boolean: false,
      failed: false,
    },
    {
      name: 'New Customers visits recorded',
      boolean: true,
      failed: false,
    },
  ];
  firstaday: any = false;
  sevendays: any = false;
  id: any;
  onboarding: any;
  merchantName: any;
  Payments: any;
  Subscription: any;
  SeventhDay: any;
  loader: boolean;
  FirstDay: any;
  SecondDay: any;
  ThirdDay: any;
  FourthDay: any;
  FifthDay: any;
  SixthDay: any;
  FifteenDay: any;
  twentyDay: any;
  twentyFiveDay: any;
  ThirtyDay: any;
  constructor(private route: ActivatedRoute, private service: AdminService) {}

  ngOnInit(): void {
    this.loader = true;

    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      console.log(this.id);
    });
    this.service.tracking(this.id).subscribe((tracking) => {
      console.log(tracking);
      this.loader = false;
      this.merchantName = tracking.locationName;
      this.Payments = tracking.Payments;
      this.FirstDay = tracking.FirstDay;
      this.SecondDay = tracking.SecondDay;
      this.ThirdDay = tracking.ThirdDay;
      this.FourthDay = tracking.FourthDay;
      this.FifthDay = tracking.FifthDay;
      this.SixthDay = tracking.SixthDay;
      this.SeventhDay = tracking.SeventhDay;
      this.FifteenDay = tracking.FifteenDay;
      this.twentyDay = tracking.twentyDay;
      this.twentyFiveDay = tracking.twentyFiveDay;
      this.ThirtyDay = tracking.ThirtyDay;
      this.Subscription = tracking.Subscription;
      // this.SeventhDay = tracking.SeventhDay;
      this.onboarding = tracking.onboarding;
      this.trail = tracking.FreeTrail;
    });
    setTimeout(() => {
      this.firstaday = true;
    }, 3000);
    setTimeout(() => {
      this.sevendays = true;
    }, 6000);
  }
}
