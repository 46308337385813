import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  ClickEvent,
  HoverRatingChangeEvent,
  RatingChangeEvent,
} from 'angular-star-rating';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-smsanalytics-model',
  templateUrl: './smsanalytics-model.component.html',
  styleUrls: ['./smsanalytics-model.component.css'],
})
export class SmsanalyticsModelComponent implements OnInit {
  reviewForm: FormGroup;
  locationName: any;
  locationId: any;
  onClickResult: ClickEvent;
  onHoverRatingChangeResult: HoverRatingChangeEvent;
  onRatingChangeResult: RatingChangeEvent;
  subscribeSubmitted: any;
  starsSubmitted: any = 0;
  stars: boolean;
  loader: boolean;
  customer: any = ['reviews', 'referrals'];
  checked = false;
  indeterminate = false;
  fileName: any;
  selectedFile: File;
  uploadFlie: boolean = true;
  attachmentFile: boolean = false;
  uploadBtn: boolean = false;
  csvsucceed: boolean = false;
  constructor(
    private dilaog: MatDialog,
    private fb: FormBuilder,
    private service: AdminService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reviewForm = this.fb.group({
      id: ['', [Validators.required]],
      reviewName: [''],
      SmsSent: [''],
      Open: [''],
      Delivered: [''],
      Clicks: [''],
      review: [''],
    });
    console.log(this.data);
    this.locationName = this.data.name;
    this.locationId = this.data.id;
    // this.customer = this.data.customer;
  }

  ngOnInit(): void {
    this.service.smsId(this.locationId).subscribe((result: any) => {
      console.log(result);
      this.loader = false;
      this.reviewForm.controls['id'].setValue(this.locationId);
      this.reviewForm.controls['SmsSent'].setValue(
        result?.locations[0]?.smsSent
      );
      this.reviewForm.controls['Open'].setValue(result?.locations[0]?.open);
      this.reviewForm.controls['Delivered'].setValue(
        result?.locations[0]?.delivered
      );
      this.reviewForm.controls['Clicks'].setValue(result?.locations[0]?.clicks);
      this.reviewForm.controls['reviewName'].setValue(
        result?.locations[0]?.type
      );
    });
  }

  get c(): { [key: string]: AbstractControl } {
    return this.reviewForm.controls;
  }
  onFileSelectedTvProgram(event: any): void {
    this.selectedFile = <File>event.target.files[0];
    this.fileName = event.target.files[0].name;
    if (event.target.files[0].name != null) {
      this.uploadFlie = false;
      this.attachmentFile = true;
      this.uploadBtn = true;
    }
  }
  uploadCsv() {
    const formData = new FormData();
    // formData.append('file', this.selectedFile, this.fileName);
    // this.collectionServices.uploadCsv(formData).subscribe(
    //   (data) => {
    //     console.log(data);
    //     this.csvsucceed = true;
    //     this.uploadFlie = true;
    //     this.attachmentFile = false;
    //     this.uploadBtn = false;
    //     setTimeout(() => {
    //       this.csvsucceed = false;
    //     }, 3000);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }
  submit() {
    if (this.starsSubmitted == 0) {
      this.stars = true;
    }
    if (this.reviewForm.invalid) {
      this.subscribeSubmitted = true;
    } else {
      this.subscribeSubmitted = false;
      this.loader = true;
      // console.log('Submitted', this.starsSubmitted?.rating);
      // this.reviewForm.controls['stars'].setValue(this.starsSubmitted?.rating);
      // let obj = {
      //   locationName: this.reviewForm.value.name,
      //   locationId: this.reviewForm.value.id,
      //   reviewed_by: this.reviewForm.value.reviewName,
      //   review: this.reviewForm.value.review.trim(),
      //   stars: this.reviewForm.value.stars,
      // };
      const formData = new FormData();
      if (this.fileName != null) {
        formData.append('file', this.selectedFile, this.fileName);
      }
      // formData.append('locationName', this.reviewForm.value.name);
      formData.append('locationId', this.reviewForm.value.id);
      formData.append('type', this.reviewForm.value.reviewName);
      formData.append('smsSent', this.reviewForm.value.SmsSent);
      formData.append('open', this.reviewForm.value.Open);
      formData.append('delivered', this.reviewForm.value.Delivered);
      formData.append('clicks', this.reviewForm.value.Clicks);
      this.service.smsAnalytics(formData).subscribe(
        (review) => {
          this.dilaog.closeAll();
          this.loader = false;

          this.reviewForm.reset();
          Swal.fire({
            text: 'Succefully Added',
            showConfirmButton: true, // Hide the "OK" button
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
          });
          this.service.refresh();
        },
        (err) => {
          Swal.fire({
            text: err.error.msg,
            showConfirmButton: true, // Hide the "OK" button
            icon: 'error', // You can change the icon type
            timer: 5000, // Set the timer to 3000 milliseconds (3 seconds)
          });
          this.dilaog.closeAll();
        }
      );

      console.log('Submitted', this.reviewForm.value);
    }
  }
  close() {
    this.dilaog.closeAll();
  }
  onClick = ($event: ClickEvent) => {
    console.log('onClick $event: ', $event.rating);
    this.onClickResult = $event;
    this.starsSubmitted = $event;
    if (this.starsSubmitted != 0) {
      this.stars = false;
    }
  };

  onRatingChange = ($event: RatingChangeEvent) => {
    console.log('onRatingUpdated $event: ', $event);
    this.onRatingChangeResult = $event;
  };

  onHoverRatingChange = ($event: HoverRatingChangeEvent) => {
    console.log('onHoverRatingChange $event: ', $event);
    this.onHoverRatingChangeResult = $event;
  };
}
