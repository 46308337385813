import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './login-page/login-page/login-page.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginGuard } from './routes/login.guard';
import { CustomersComponent } from './components/customers/customers.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { VisitorsComponent } from './components/visitors/visitors.component';
import { FreetrailsComponent } from './components/freetrails/freetrails.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { InternalTeamComponent } from './components/internal-team/internal-team.component';
import { CreateadminComponent } from './components/internal-team/createadmin/createadmin.component';
import { UpdateUserComponent } from './components/internal-team/update-user/update-user.component';
import { SupportComponent } from './components/support/support.component';
import { SettingsComponent } from './components/settings/settings.component';
import { RefferalsComponent } from './components/refeerals/refferals/refferals.component';
import { LeadsComponent } from './components/refeerals/leads/leads.component';
import { ActivationComponent } from './components/refeerals/activation/activation.component';
import { CancelationComponent } from './components/cancelation/cancelation.component';
import { PushnotificationComponent } from './components/pushnotification/pushnotification.component';
import { ModificationsComponent } from './components/modifications/modifications.component';
import { LivefeedComponent } from './components/liveFeed/livefeed/livefeed.component';
import { ReferalsComponent } from './components/liveFeed/referals/referals.component';
import { StaffComponent } from './components/liveFeed/staff/staff.component';
import { LoginsComponent } from './components/liveFeed/logins/logins.component';
import { ReviewComponent } from './components/liveFeed/review/review.component';
import { OperationsComponent } from './components/operations/operations.component';
import { TranscationsComponent } from './components/transcations/transcations.component';
import { RevnueComponent } from './components/revnue/revnue.component';
import { LocationsComponent } from './components/locations/locations.component';
import { TrackingDialogComponent } from './components/customers/tracking-dialog/tracking-dialog.component';
import { MerchantsComponent } from './components/merchants/merchants.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SmsanalyticsComponent } from './components/smsanalytics/smsanalytics.component';
import { TestingComponent } from './components/testing/testing.component';
import { SmsreqComponent } from './components/testing/smsreq/smsreq.component';
import { CusComponent } from './components/testing/cus/cus.component';
import { RefComponent } from './components/testing/ref/ref.component';
import { WeeklyreviewsComponent } from './components/reviews/weeklyreviews/weeklyreviews.component';
import { InstagramfollowersComponent } from './components/instagramfollowers/instagramfollowers.component';
import { RefferalmsgComponent } from './components/refferalmsg/refferalmsg.component';

const LoginPageModule = () =>
  import('../app/login-page/login-page.module').then((x) => x.LoginPageModule);

const routes: Routes = [
  { path: 'admin', loadChildren: LoginPageModule },
  {
    path: 'Customers',
    component: CustomersComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Customers/tracking',
    component: TrackingDialogComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'testing',
    component: TestingComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'testing/smsRequests',
    component: SmsreqComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'testing/customers',
    component: CusComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'testing/refferal',
    component: RefComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'smsAnalytics',
    component: SmsanalyticsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'referalMsg',
    component: RefferalmsgComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'instagram',
    component: InstagramfollowersComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'merchants',
    component: MerchantsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'operation',
    component: OperationsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'transcation',
    component: TranscationsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'locations',
    component: LocationsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'renvue',
    component: RevnueComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Reviews/All-Locations',
    component: ReviewsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Reviews/Weekly',
    component: WeeklyreviewsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'livefeed/reviews',
    component: ReviewComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'livefeed/referral',
    component: ReferalsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'livefeed/staff',
    component: StaffComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'livefeed/logins',
    component: LoginsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'livefeed',
    component: LivefeedComponent,
    canActivate: [LoginGuard],
  },
  // {
  //   path: 'Activations',
  //   component: ActivationComponent,
  //   canActivate: [LoginGuard],
  // },
  {
    path: 'cancellation',
    component: CancelationComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'pushNotification',
    component: PushnotificationComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'modification',
    component: ModificationsComponent,
    canActivate: [LoginGuard],
  },
  // {
  //   path: 'Refferal',
  //   component: RefferalsComponent,
  //   canActivate: [LoginGuard],
  //   children: [

  //   ],
  // },
  {
    path: 'Refferal/leads',
    component: LeadsComponent,
  },
  {
    path: 'Refferal/Activations',
    component: ActivationComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Refferal/Program-setup',
    component: RefferalsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'visitors',
    component: VisitorsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Subscription',
    component: SubscriptionComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'internal-team',
    component: InternalTeamComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'update-user',
    component: UpdateUserComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'create',
    component: CreateadminComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'Freetrails',
    component: FreetrailsComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [LoginGuard],
  },
  {
    path: '**',
    loadChildren: LoginPageModule,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
