import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-refdialog',
  templateUrl: './refdialog.component.html',
  styleUrls: ['./refdialog.component.css'],
})
export class RefdialogComponent implements OnInit {
  id: any;
  referred_by: any;
  referred_mobileNumber: any;
  referrer_threshold: any;
  value: any;
  loading: boolean;
  constructor(
    private dilaog: MatDialog,
    private service: AdminService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.id = this.data.id.referralProgramId;
    this.referred_by = this.data.id.referred_by;
    this.referred_mobileNumber = this.data.id.referred_mobileNumber;
    this.referrer_threshold = this.data.id.referrer_threshold;
    console.log(this.data);
  }
  refe(e: any) {
    this.value = e.target.value;
  }
  update() {
    this.loading = true;
    this.service
      .updateThreshold(
        this.id,
        this.value ? this.value : this.referrer_threshold
      )
      .subscribe((data) => {
        this.loading = false;

        Swal.fire({
          text: 'Succefully Updated',
          showConfirmButton: true, // Hide the "OK" button
          icon: 'success', // You can change the icon type
          timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        });
        this.dilaog.closeAll();
      });
  }
}
