import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-sidenavheader',
  templateUrl: './sidenavheader.component.html',
  styleUrls: ['./sidenavheader.component.css'],
})
export class SidenavheaderComponent implements OnInit {
  firstName: any;
  secondName: any;
  constructor(private router: Router, private service: AdminService) {}

  ngOnInit(): void {
    this.service.adminDetails().subscribe((results) => {
      // this.adminImg = results.data.profile;
      // this.url = this.adminImg;

      this.firstName = results[0].firstName ? results[0].firstName : '';
      this.secondName = results[0].lastName ? results[0].lastName : '';
    });
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/admin/login']);
  }
}
