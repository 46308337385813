<div class="main mx-3">
        <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
<a class="cursor" (click)="route(item.route)">

                <h3>{{item.name}}</h3>

</a>
            </div>
        </div>
     </div>
</div>