import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
export interface PeriodicElement {
  Cid: any;
  Cname: any;
  ctype: any;
  Locations: any;
  singupdate: any;
  City: any;
  freetrail: any;
  subscription: any;
  status: any;
  action: any;
}
// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: '762893758783',
//     Locations: '465',
//     singupdate: '56',
//     freetrail: '78',
//     subscription: 'Active',
//     status: 'Hyderbad,Telagana',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: '762893758783',
//     Locations: '465',
//     singupdate: '56',
//     freetrail: '78',
//     subscription: 'Active',
//     status: 'Hyderbad,Telagana',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: '762893758783',
//     Locations: '465',
//     singupdate: '56',
//     freetrail: '78',
//     subscription: 'Active',
//     status: 'Hyderbad,Telagana',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: '762893758783',
//     Locations: '465',
//     singupdate: '56',
//     freetrail: '78',
//     subscription: '89',
//     status: 'Hyderbad,Telagana',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: '762893758783',
//     Locations: '465',
//     singupdate: '56',
//     freetrail: '78',
//     subscription: '89',
//     status: 'Hyderbad,Telagana',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: '762893758783',
//     Locations: '465',
//     singupdate: '56',
//     freetrail: '78',
//     subscription: 'Active',
//     status: 'Hyderbad,Telagana',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'HM',
//     ctype: 'shop',
//     Locations: '465',
//     singupdate: '56',
//     freetrail: '78',
//     subscription: '89',
//     status: 'Hyderbad,Telagana',
//     action: 'view',
//   },
// ];

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.css'],
})
export class VisitorsComponent implements OnInit {
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Locations',
    'City',
    'singupdate',
    'freetrail',
    'subscription',
    'status',
    'action',
  ];
  Language: any = ['This month', 'This week', 'Today'];
  loader: boolean = false;
  userQuery!: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean = false;
  userData: any;
  highcharts = Highcharts;
  chartOptionsRevered: any;
  chartOptionsBarRevered: any;
  vistorsChart: any;
  chartfirstCity: any;
  chartSecondCity: any;
  chartThirdCity: any;
  chartFourthCity: any;
  locationParams: any = '';
  cityParams: any = '';
  reviewParams: any = '';
  currentMonth: any;
  constructor(private service: AdminService) {}

  ngOnInit(): void {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth(); // This gets the month index (0-11)
    const monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentMonth = monthsArray[monthIndex]; // Get the month name using the index
    this.currentMonth = currentMonth;
    this.userApisList();
    this.visitorsChart();
    this.chartSecondCity = {
      chart: {
        type: 'spline',
        backgroundColor: '#D8D8D8 ',
        polar: true,
      },
      title: {
        text: 'Hyderabad',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Goals',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#076611ab'],
      series: [
        {
          name: 'Hyderabad',
          data: [200, 400, 600, 800, 1300, 1500, 2000, 2200],
        },
      ],
    };
    this.chartfirstCity = {
      chart: {
        type: 'spline',
        backgroundColor: '#D8D8D8 ',
        polar: true,
      },
      title: {
        text: 'Vizag',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Goals',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#076611ab'],
      series: [
        {
          name: 'Vizag',
          data: [200, 400, 600, 800, 1300, 1500, 2000, 2200],
        },
      ],
    };
    this.chartthirdcity();
  }
  chartthirdcity() {
    this.chartThirdCity = {
      chart: {
        type: 'spline',
        backgroundColor: '#D8D8D8 ',
        polar: true,
      },
      title: {
        text: 'Mumbai',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Goals',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#076611ab'],
      series: [
        {
          name: 'Mumbai',
          data: [200, 800, 1600, 1800, 2300, 700, 5000, 1200],
        },
      ],
    };
    this.chartFourthCity = {
      chart: {
        type: 'spline',
        backgroundColor: '#D8D8D8 ',
        polar: true,
      },
      title: {
        text: 'Rajamundry',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Goals',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#076611ab'],
      series: [
        {
          name: 'Rajamundry',
          data: ['"week 1",2200', 2000, 1600, 800, 1200, 1900, 2000, 2200],
        },
      ],
    };
  }
  location(e: any) {
    this.locationParams = e.target.value;
    this.userQuery = {
      location: this.locationParams,
      city: this.cityParams,
      review: this.reviewParams,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.visitor(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalVisitors;
      // this.loading = false;

      this.ELEMENT_DATA = res.visitors;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  city(e: any) {
    this.cityParams = e.target.value;
    this.userQuery = {
      location: this.locationParams,
      city: this.cityParams,
      review: this.reviewParams,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.visitor(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalVisitors;
      // this.loading = false;

      this.ELEMENT_DATA = res.visitors;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  review(e: any) {
    this.reviewParams = e.target.value;
    this.userQuery = {
      location: this.locationParams,
      city: this.cityParams,
      review: this.reviewParams,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.visitor(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalVisitors;
      // this.loading = false;

      this.ELEMENT_DATA = res.visitors;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  userApisList() {
    this.loader = true;

    this.userQuery = {
      active: this.language,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    // this.ELEMENT_DATA = result.topics;
    // this.userData = result.topics;
    // this.totalSummary = result.totalTopics;
    // this.newUser = result.totalSubtopicCount;
    // this.userDataCount = result.userDataCount;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.visitor(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalVisitors;
      // this.loading = false;

      this.ELEMENT_DATA = res.visitors;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.items = [
        { name: 'Total visitors', number: res.totalVisitors },

        // { name: 'Normal Sing up', number: this.userDataCount },
      ];
    });
  }
  visitorsChart() {
    this.userQuery = {
      month: this.currentMonth,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.visitor(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalVisitors;
      // this.loading = false;

      this.ELEMENT_DATA = res.visitors;
      this.vistorsChart = {
        chart: {
          type: 'column',
          backgroundColor: '#D8D8D8 ',
          polar: true,
        },

        title: {
          text: 'Visitors activity',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
        },

        dataLabels: {
          style: {
            color: '#000000',
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        colors: ['#068466'],
        series: [
          {
            name: '',
            data: [800, 600, 400, 300, 100],
          },
        ],
      };
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    this.loader = true;
    this.userQuery = {
      location: this.locationParams,
      city: this.cityParams,
      review: this.reviewParams,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.visitor(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalVisitors;
      // this.loading = false;

      this.ELEMENT_DATA = res.visitors;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.items = [
        { name: 'Total visitors', number: res.totalVisitors },

        // { name: 'Normal Sing up', number: this.userDataCount },
      ];
    });
  }

  langugeChange(e: any) {
    this.language = e.target.value;
    this.userApisList();
  }
  toggleChanged(e: any) {
    console.log('toggl', e.target.checked);
  }
  deleteUser(id: any) {
    // this.adminService.deleteUser(id).subscribe((res) => {
    //   console.log('delete ', res);
    //   this.userDelete = true;
    //   Swal.fire({
    //     text: 'User delete succefully',
    //     showConfirmButton: true, // Hide the "OK" button
    //     icon: 'success', // You can change the icon type
    //     timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
    //   });
    //   this.userApisList();
    //   setTimeout(() => {
    //     this.userDelete = false;
    //   }, 3000);
    // });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
