import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  showLogin: boolean = true;
  sidebarOpen: boolean = true;
  notificationsOpen: boolean = false;
  showDashboard: boolean = false;
  constructor() {}

  ngOnInit(): void {}
  ngDoCheck(): void {
    if (localStorage.getItem('token') !== null) {
      this.showDashboard = true;

      this.showLogin = false;
    } else {
      this.showLogin = true;
      this.showDashboard = false;
    }
  }
}
