<!-- [ngClass]="message === 'true'?'active-sidebar':'close-sidebar'" -->
<div class="sidemenu-wrap">
  <span class="d-xl-none close-sidebar"><i class="fa fa-times"></i></span>
  <div class="sidebar-logo">
    <a class="d-flex">
      <!-- <img src="../../../assets/images/automati-nav.png"> -->
     <h4>Localfirst</h4> 
       </a>
  </div>

  <div class="menu-wrap">
    <ul>
        <ng-container >
              <li>
        <a (click)="route('locations')" [class.active-route]="routeNavigate === 'locations'"
     [class.inactive-route]="routeNavigate !== 'locations'">
          <img class="images" src="../../../assets/images/free-trial.png" alt="" />
          
Locations        </a>
       
      </li>
          <li >
        <a (click)="route('merchants')" [class.active-route]="routeNavigate === 'merchants'"
     [class.inactive-route]="routeNavigate !== 'merchants'">
          <img class="images" src="../../../assets/images/costumer.png" alt="" />
         Merchants
        </a>
     
      </li>
         <li>
        <a (click)="route('Reviews/All-Locations')">
          <img class="images" src="../../../assets/images/review.png" alt="" />
          
          Reviews
        </a>
      
      </li>
       <ul class="new_drop_list pt-0  re list" *ngIf="reviewlist">
        <li><a (click)="route('Reviews/All-Locations')" class="pt-0" [class.active-route]="routeNavigate === 'Reviews/All-Locations'"
     [class.inactive-route]="routeNavigate !== 'Reviews/All-Locations'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       All Locations
        </a></li>

          <li><a (click)="route('Reviews/Weekly')" [class.active-route]="routeNavigate === 'Reviews/Weekly'"
     [class.inactive-route]="routeNavigate !== 'Reviews/Weekly'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Weekly
        </a></li>
          
         
        
          
        </ul>
        <li>
        <a (click)="route('Refferal/Program-setup')" >
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Refferals
        </a>
      <ul class="new_drop_list  re list" *ngIf="referrallist">
        <li><a (click)="route('Refferal/Program-setup')" [class.active-route]="routeNavigate === 'Refferal/Program-setup'"
     [class.inactive-route]="routeNavigate !== 'Refferal/Program-setup'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Program setup
        </a></li>

          <li><a (click)="route('Refferal/leads')" [class.active-route]="routeNavigate === 'Refferal/leads'"
     [class.inactive-route]="routeNavigate !== 'Refferal/leads'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Leads
        </a></li>
          
          <li><a (click)="route('Refferal/Activations')" [class.active-route]="routeNavigate === 'Refferal/Activations'"
     [class.inactive-route]="routeNavigate !== 'Refferal/Activations'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Activations
        </a></li>
        
          
        </ul>
      </li>
     
      
      <li><a (click)="route('pushNotification')" [class.active-route]="routeNavigate === 'pushNotification'"
     [class.inactive-route]="routeNavigate !== 'pushNotification'">
          <img class="images" src="../../../assets/images/smartphone (1).png" alt="" />
          
       Push notifications
        </a></li>
          <li>
        <a (click)="route('livefeed')" >
          <img class="images" src="../../../assets/images/live (1).png" alt="" />
          
       Livefeed
        </a>
      <ul class="new_drop_list  re list" *ngIf="livefeed">
        <li><a (click)="route('livefeed/reviews')" [class.active-route]="routeNavigate === 'livefeed/reviews'"
     [class.inactive-route]="routeNavigate !== 'livefeed/reviews'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Reviews
        </a></li>
             
        <li><a (click)="route('livefeed/referral')" [class.active-route]="routeNavigate === 'livefeed/referral'"
     [class.inactive-route]="routeNavigate !== 'livefeed/referral'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Referrals
        </a></li>
        <li><a (click)="route('livefeed/staff')" [class.active-route]="routeNavigate === 'livefeed/staff'"
     [class.inactive-route]="routeNavigate !== 'livefeed/staff'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Staff
        </a></li>
        <li><a (click)="route('livefeed/logins')" [class.active-route]="routeNavigate === 'livefeed/logins'"
     [class.inactive-route]="routeNavigate !== 'livefeed/logins'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
       Logins
        </a></li>
 
        
          
        </ul>
      </li>
         <li>
        <a (click)="route('Freetrails')" [class.active-route]="routeNavigate === 'Freetrails'"
     [class.inactive-route]="routeNavigate !== 'Freetrails'">
          <img class="images" src="../../../assets/images/free-trial.png" alt="" />
          
          Freetrails
        </a>
       
      </li>
       <li>
        <a (click)="route('Subscription')" [class.active-route]="routeNavigate === 'Subscription'"
     [class.inactive-route]="routeNavigate !== 'Subscription'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
          Subscription
        </a>
      
      </li>
        <li >
        <a (click)="route('Customers')" [class.active-route]="routeNavigate === 'Customers'"
     [class.inactive-route]="routeNavigate !== 'Customers'">
          <img class="images" src="../../../assets/images/costumer.png" alt="" />
         Customers
        </a>
        <!-- <ul class="new_drop_list" *ngIf="customerNewList">

          <li>Reg Vs Activated</li>
          <li>Active Vs Free trails</li>
          <li>Free trails Vs Cancels</li>
          <li>Free trails Vs Subscriptinos</li>
          <li>Subscriptions vs cancellations</li>
        </ul> -->
      </li>
      <li>
        <a (click)="route('instagram')" [class.active-route]="routeNavigate === 'instagram'"
     [class.inactive-route]="routeNavigate !== 'instagram'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
         Instagram Followers
        </a>
      
      </li>
      <li>
        <a (click)="route('referalMsg')" [class.active-route]="routeNavigate === 'referalMsg'"
     [class.inactive-route]="routeNavigate !== 'referalMsg'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
         Refferal Msg change
        </a>
      
      </li>
      <li>
        <a (click)="route('support')" [class.active-route]="routeNavigate === 'support'"
     [class.inactive-route]="routeNavigate !== 'support'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
         Support
        </a>
      
      </li>
           <li >
        <a (click)="route('smsAnalytics')" [class.active-route]="routeNavigate ==='smsAnalytics'" [class.inactive-route]="routeNavigate !== 'smsAnalytics'">
          <img class="images" src="../../../assets/images/costumer.png" alt="" />
        SmsAnalytics
        </a>
     
      </li>
     
       <li>
        <a (click)="route('settings')" [class.active-route]="routeNavigate === 'settings'"
     [class.inactive-route]="routeNavigate !== 'settings'">
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
          Settings
        </a>
      
      </li>
      </ng-container>
      
    </ul>
    <p>Disabled</p>
    <ul class="disabledw">
      <ng-container>
           <li >
        <a >
          <img class="images" src="../../../assets/images/costumer.png" alt="" />
         Reports
        </a>
     
      </li>
        <li >
        <a  >
          <img class="images" src="../../../assets/images/costumer.png" alt="" />
         Testing
        </a>
     
      </li>
        <!-- <li >
        <a (click)="route('modification')" [class.active-route]="routeNavigate === 'modification'"
     [class.inactive-route]="routeNavigate !== 'modification'">
          <img class="images" src="../../../assets/images/costumer.png" alt="" />
         modification
        </a>
     
      </li> -->
  
  
     
         <li>
        <li>
        <a>
          <img class="images" src="../../../assets/images/free-trial.png" alt="" />
          
          Cancellation
        </a>
       
      </li>
    
        
         <li>
        <a >
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
          Operations
        </a>
      
      </li>
         <li>
        <a >
          <img class="images" src="../../../assets/images/transaction.png" alt="" />
          
          Transcations
        </a>
      
      </li>
         <li>
        <a >
          <img class="images" src="../../../assets/images/earning.png" alt="" />
          
          Revenue
        </a>
      
      </li>
   
     <li>
        <a (click)="route('internal-team')" disabled>
          <img class="images" src="../../../assets/images/subscribe.png" alt="" />
          
          Internal-team
        </a>
      
      </li>
      
      </ng-container>
     
    </ul>
  </div>
 
</div>
