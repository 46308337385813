import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-revnue',
  templateUrl: './revnue.component.html',
  styleUrls: ['./revnue.component.css'],
})
export class RevnueComponent implements OnInit {
  highcharts = Highcharts;
  items: any[] = [];
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'freetrail',

    'ctype',
    'Locations',
    'singupdate',
    'subscription',

    'action',
  ];

  ELEMENT_DATA1: Element[] = [];
  ELEMENT_DATA: Element[] = [];

  dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
  dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
  chartOptionsRevered: any;
  chartOptionsActiveSubscriptions: any;
  Language: any = ['This month', 'This week', 'Today'];
  chartfirstCity: any;
  chartSecondCity: any;
  chartThirdCity: any;
  chartFourthCity: any;
  currentMonth: string;
  month: any;
  monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  query: any;
  currentCount: any;
  subscribers: any = [];
  pageSize: any = 10;
  currentPage: any = 0;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  loader: boolean = false;
  totalSub: any;
  Totalsubscribers: any = [];
  loading: boolean;
  constructor(private service: AdminService) {}

  ngOnInit(): void {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth(); // This gets the month index (0-11)
    const monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentMonth = monthsArray[monthIndex]; // Get the month name using the index
    this.currentMonth = currentMonth;
    // this.loader = true;

    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.revenue().subscribe((result: any) => {
      console.log(result);
      this.loader = false;
      // this.ELEMENT_DATA = result.response.subscriptionPlans;
      this.ELEMENT_DATA1 = result.dashBoardData;
      this.loading = false;

      // this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
      this.items = [
        { name: 'Total revenue', number: result.totalReferralRevenues },
        { name: 'Total ', number: 0 },
        // { name: 'Active', number: 900 },
        // { name: 'Cancelled', number: result.cancelledSubscription },
        // { name: 'Expired', number: result.expiredSubscription },
        // { name: 'Normal Sing up', number: this.userDataCount },
      ];
    });

    // this.chartthirdcity();
    // this.areaChart();
  }

  areaChart() {
    this.query = {
      month: this.currentMonth,
      // type: 'month',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.subsribers(this.query).subscribe((result) => {
      console.log(result);
      this.items = [
        { name: 'Total subscriptions', number: result.totalSubscriptions },
        { name: 'Active', number: result.activeSubscription },
        { name: 'Cancelled', number: result.cancelledSubscription },
        { name: 'Expired', number: result.expiredSubscription },
        // { name: 'Normal Sing up', number: this.userDataCount },
      ];
      this.currentCount = result.weeklyCounts.activeWeeklyCount;
      this.currentCount.forEach((response: any) => {
        this.subscribers.push(response.count);
        console.log(this.subscribers);
      });
      this.totalSub = result.weeklyCounts.totalSubscriptionweeklycount;
      this.totalSub.forEach((response: any) => {
        this.Totalsubscribers.push(response.count);
        console.log(this.subscribers);
      });
      this.chartOptionsRevered = {
        chart: {
          type: 'area',
        },
        title: {
          text: 'Subscriptions Growth',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
          gridLineWidth: 0,
        },
        legend: {
          reversed: true,
        },
        colors: ['#1F46E8'],
        series: [
          {
            name: 'Subscriptions Growth',
            data: this.Totalsubscribers,
          },
        ],
      };
      this.chartOptionsActiveSubscriptions = {
        chart: {
          type: 'spline',
        },
        title: {
          text: 'Active Subscriptions',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
          gridLineWidth: 0,
        },
        legend: {
          reversed: true,
        },
        colors: ['#068466'],
        series: [
          {
            name: 'Active Subscriptions',
            data: this.subscribers,
          },
        ],
      };
    });
  }

  chartthirdcity() {
    this.chartSecondCity = {
      chart: {
        type: 'spline',
        backgroundColor: '#D8D8D8 ',
        polar: true,
      },
      title: {
        text: 'Hyderabad',
        align: 'left',
        x: 30,
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Goals',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#076611ab'],
      series: [
        {
          name: 'Hyderabad',
          data: [200, 400, 600, 800, 1300, 1500, 2000, 2200],
        },
      ],
    };
    this.chartfirstCity = {
      chart: {
        type: 'spline',
        backgroundColor: '#D8D8D8 ',
        polar: true,
      },
      title: {
        text: 'Vizag',
        align: 'left',
        x: 30,
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Goals',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#076611ab'],
      series: [
        {
          name: 'Vizag',
          data: [200, 400, 600, 800, 1300, 1500, 2000, 2200],
        },
      ],
    };
    this.chartThirdCity = {
      chart: {
        type: 'spline',
        backgroundColor: '#D8D8D8 ',
        polar: true,
      },
      title: {
        text: 'Mumbai',
        align: 'left',
        x: 30,
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Goals',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#076611ab'],
      series: [
        {
          name: 'Mumbai',
          data: [200, 800, 1600, 1800, 2300, 700, 5000, 1200],
        },
      ],
    };
    this.chartFourthCity = {
      chart: {
        type: 'spline',
        backgroundColor: '#D8D8D8 ',
        polar: true,
      },
      title: {
        text: 'Rajamundry',
        align: 'left',
        x: 30,
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: ['week 1', 'week 2', 'week 3', 'week 4'],
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Goals',
        },
      },
      legend: {
        reversed: true,
      },
      colors: ['#076611ab'],
      series: [
        {
          name: 'Rajamundry',
          data: ['"week 1",2200', 2000, 1600, 800, 1200, 1900, 2000, 2200],
        },
      ],
    };
  }
  langugeChange(e: any) {
    this.month = e.target.value;
    this.subscribers = [];
    this.Totalsubscribers = [];

    this.query = {
      month: this.month,
      // type: 'month',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.subsribers(this.query).subscribe((result) => {
      this.currentCount = result.weeklyCounts.activeWeeklyCount;
      this.currentCount.forEach((response: any) => {
        this.subscribers.push(response.count);
        console.log(this.subscribers);
      });
      this.totalSub = result.weeklyCounts.totalSubscriptionweeklycount;
      this.totalSub.forEach((response: any) => {
        this.Totalsubscribers.push(response.count);
        console.log(this.subscribers);
      });
      this.chartOptionsRevered = {
        chart: {
          type: 'area',
        },
        title: {
          text: 'Subscriptions Growth',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
          gridLineWidth: 0,
        },
        legend: {
          reversed: true,
        },
        colors: ['#1F46E8'],
        series: [
          {
            name: 'Subscriptions Growth',
            data: this.Totalsubscribers,
          },
        ],
      };
      this.chartOptionsActiveSubscriptions = {
        chart: {
          type: 'spline',
        },
        title: {
          text: 'Active Subscriptions',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
          gridLineWidth: 0,
        },
        legend: {
          reversed: true,
        },
        colors: ['#068466'],
        series: [
          {
            name: 'Active Subscriptions',
            data: this.subscribers,
          },
        ],
      };
    });
  }
}
