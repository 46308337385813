import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

// import { DashboardServicesService } from 'src/app/_services/dashboard-services.service';
// import { SuccescreateDialogComponent } from '../succescreate-dialog/succescreate-dialog.component';
// import { CheckboxDialogComponent } from '../checkbox-dialog/checkbox-dialog.component';
const emailPhonePattern =
  '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$';
const phonePattern = '^[1-9][0-9]{9}$';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css'],
})
export class UpdateUserComponent implements OnInit {
  createSuccesfully: boolean = false;
  createUserLoader: boolean = false;
  managementOption: any = [
    { value: false, key: 'View' },
    { value: false, key: 'Edit' },
    { value: false, key: 'View analytics' },
  ];
  tvProgramsAndDontionsCheckboxValues: any = [
    { value: false, key: 'View' },
    { value: false, key: 'View analytics' },
  ];
  cmsCheckboxValues: any = [
    { value: false, key: 'View' },
    { value: false, key: 'Edit' },
  ];
  spiritualFitnessCheckboxValues: any = [{ value: false, key: 'View' }];
  createUserManagment: FormGroup;
  subRubrosSelec: any[];
  memberslistPermission: any = [];
  itSupportForm: any;
  submitted: boolean = false;
  url: any = '../../assets/images/account.png';
  selectedFile: File;
  updateProfileForm: any;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  supportQuery: any = {};
  sameEmailAddress: any;
  sameEmailAddressError: any;
  errorMessege: any;
  customerView: any;
  customerEdit: any;
  customeranalyse: any;
  apiData: any;
  trailView: any;
  trailEdit: any;
  trailanalyse: any;
  members_List: any = {};
  subView: any;
  subEdit: any;
  subanalyse: any;
  visitorEdit: any;
  visitoranalyse: any;
  visitorView: any;
  reviewView: any;
  reviewEdit: any;
  reviewanalyse: any;
  supportView: any;
  supportEdit: any;
  supportanalyse: any;
  cmsView: any;
  cmsEdit: any;
  cmsanalyse: any;
  spiritualFitnessView: any;
  firstName: any;
  firstNameUser: any;
  lastName: any;
  role: any;
  queryParamsDetails: any;
  userDetailsIdParams: string;
  query: {};
  totalData: any;
  fliteredId: any;
  email: any;
  CustomersChecked: any;
  ReviewsChecked: any;
  TrailChecked: any;
  SubChecked: any;
  visitorsChecked: any;
  phone: any;
  constructor(
    private service: AdminService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.role = localStorage.getItem('ROLE');
    this.createUserManagment = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      role: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(phonePattern)]],
      email: ['', [Validators.required, Validators.pattern(emailPhonePattern)]],
      Subscribe: this.fb.array([]),
      Customers: this.fb.array([]),
      Reviews: this.fb.array([]),
      Visitors: this.fb.array([]),
      Trail: this.fb.array([]),
      Subscribes: this.fb.array([]),
      file: [''],
    });
  }

  ngOnInit() {
    //   this.supportQuery = {};
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsIdParams = params.get('id');
      console.log(this.userDetailsIdParams);
      this.query = {
        _id: this.userDetailsIdParams,
        pageLimit: 20,
        pageNumber: 1,
      };
      this.service.internalTeam(this.query).subscribe((res) => {
        console.log(res);
        console.log(this.fliteredId);
        // this.profile = res.message.profile;
        this.firstName = res.formattedUsers[0].firstName;
        this.lastName = res.formattedUsers[0].lastName;
        this.phone = res.formattedUsers[0].phone;
        this.email = res.formattedUsers[0].email;
        this.role = res.formattedUsers[0].role;
        this.createUserManagment.get('firstName').setValue(this.firstName);
        this.createUserManagment.get('lastName').setValue(this.lastName);
        this.createUserManagment.get('phone').setValue(this.phone);
        this.createUserManagment.get('email').setValue(this.email);
        this.createUserManagment.get('role').setValue(this.role);
        this.CustomersChecked = res.formattedUsers[0].permissions.customers;
        this.ReviewsChecked = res.formattedUsers[0].permissions.reviews;
        this.TrailChecked = res.formattedUsers[0].permissions.freeTrail;
        this.SubChecked = res.formattedUsers[0].permissions.subscriptions;
        this.visitorsChecked = res.formattedUsers[0].permissions.visitors;
        this.customerView = res.formattedUsers[0].permissions.customers.view;
        this.customerEdit = res.formattedUsers[0].permissions.customers.edit;
        this.customeranalyse =
          res.formattedUsers[0].permissions.customers.analytics;
        this.reviewView = res.formattedUsers[0].permissions.reviews.view;
        this.reviewEdit = res.formattedUsers[0].permissions.reviews.edit;
        this.reviewanalyse =
          res.formattedUsers[0].permissions.reviews.analytics;
        this.subView = res.formattedUsers[0].permissions.subscriptions.view;
        this.subEdit = res.formattedUsers[0].permissions.subscriptions.edit;
        this.subanalyse =
          res.formattedUsers[0].permissions.subscriptionsanalytics;
        this.visitorView = res.formattedUsers[0].permissions.visitors.view;
        this.visitorEdit = res.formattedUsers[0].permissions.visitors.edit;
        this.visitoranalyse =
          res.formattedUsers[0].permissions.visitors.analytics;
        this.trailView = res.formattedUsers[0].permissions.freeTrail.view;
        this.trailEdit = res.formattedUsers[0].permissions.freeTrail.edit;
        this.trailanalyse =
          res.formattedUsers[0].permissions.freeTrail.analytics;
      });
    });

    setTimeout(() => {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = true;
        element.nativeElement.checked = false;
      });
    });
  }
  deActivateUser() {
    // this.dialog.open(CheckboxDialogComponent, {
    //   disableClose: true,
    // });
  }
  sameEmailEvent(e: any) {
    this.sameEmailAddressError = this.sameEmailAddress.forEach(
      (result: any) => {
        // console.log(result.email);
        if (result.email == e.target.value) {
        }
        if (e.target.value == '') {
          this.errorMessege = '';
        }
      }
    );
  }
  createUser() {
    if (
      !this.customerView &&
      !this.customerEdit &&
      !this.customeranalyse &&
      !this.reviewView &&
      !this.reviewEdit &&
      !this.reviewanalyse &&
      !this.subView &&
      !this.subEdit &&
      !this.subanalyse &&
      !this.visitorView &&
      !this.visitorEdit &&
      !this.visitoranalyse &&
      !this.trailView &&
      !this.trailEdit &&
      !this.trailanalyse
    ) {
      this.submitted = true;
      this.deActivateUser();
      console.log(this.createUserManagment);
    } else if (this.createUserManagment.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      this.createUserLoader = true;
      let obj: any = {
        customers: {
          view: this.customerView ? true : false,
          edit: this.customerEdit ? true : false,
          analytics: this.customeranalyse ? true : false,
        },
        freeTrail: {
          view: this.trailView ? true : false,
          edit: this.trailEdit ? true : false,
          analytics: this.trailanalyse ? true : false,
        },
        reviews: {
          view: this.reviewView ? true : false,
          edit: this.reviewEdit ? true : false,
          analytics: this.reviewanalyse ? true : false,
        },
        subscriptions: {
          view: this.subView ? true : false,
          edit: this.subEdit ? true : false,
          analytics: this.subanalyse ? true : false,
        },
        visitors: {
          view: this.visitorView ? true : false,
          edit: this.visitorEdit ? true : false,
          analytics: this.visitoranalyse ? true : false,
        },
      };
      const formData = new FormData();
      // formData.append('file', this.createUserManagment.get('file').value);
      formData.append('firstName', this.createUserManagment.value.firstName);
      formData.append('lastName', this.createUserManagment.value.lastName);
      formData.append('email', this.createUserManagment.value.email);
      formData.append('phone', this.createUserManagment.value.phone);
      formData.append('role', this.createUserManagment.value.role);
      formData.append('status', 'active');
      formData.append('permissions', JSON.stringify(obj));
      console.log(obj);
      console.log(formData);
      this.service.updateUser(formData, this.userDetailsIdParams).subscribe(
        (res) => {
          setTimeout(() => {
            console.log(res);
            this.createUserLoader = false;

            Swal.fire({
              icon: 'success',
              title: 'Update succefully',
              showConfirmButton: false,
              timer: 1500,
            });
          }, 1000);

          this.router.navigate(['internal-team']);
          this.createUserManagment.reset();
          this.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });
        },
        (error) => {
          if (error.error.message == 'Email already exits') {
            this.errorMessege = 'Email Already Exists';
            this.createUserLoader = false;
            console.error(error.error.message);
          }
        }
      );
    }
  }
  create() {
    this.createSuccesfully = true;
    setTimeout(() => {
      this.createSuccesfully = false;
    }, 4000);
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      // this.msg = '';
      this.url = reader.result as string;
      this.selectedFile = <File>event.target.files[0];
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createUserManagment.get('file').setValue(file);
      console.log(this.createUserManagment.get('file').value);
    }
    //  this.updateProfileForm.get('file').setValue(file);
  }
  onChangeCustomer(event: any): void {
    if (event.target.value == 'View') {
      // console.log(this.memberslistEdit);
      this.customerView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.customerEdit = event.currentTarget.checked;
      this.customerView = this.customerEdit;
    } else {
      this.customeranalyse = event.currentTarget.checked;
    }
  }
  onChangeTrail(event: any): void {
    if (event.target.value == 'View') {
      this.trailView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.trailEdit = event.currentTarget.checked;
      this.trailView = this.trailEdit;
    } else {
      this.trailanalyse = event.currentTarget.checked;
    }
  }
  onChangeSub(event: any): void {
    if (event.target.value == 'View') {
      this.subView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.subEdit = event.currentTarget.checked;
    } else {
      this.subanalyse = event.currentTarget.checked;
    }
  }
  onChangeVisitor(event: any): void {
    if (event.target.value == 'View') {
      this.visitorView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.visitorEdit = event.currentTarget.checked;
    } else {
      this.visitoranalyse = event.currentTarget.checked;
    }
  }
  onChangeReview(event: any): void {
    if (event.target.value == 'View') {
      this.reviewView = event.currentTarget.checked;
    } else if (event.target.value == 'Edit') {
      this.reviewEdit = event.currentTarget.checked;
      this.reviewView = this.reviewEdit;
    } else {
      this.reviewanalyse = event.currentTarget.checked;
    }
  }

  get userManagementControls(): { [key: string]: AbstractControl } {
    return this.createUserManagment.controls;
  }
  // validations for numberOnly
  numberOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  // validations for numberOnly
  alphabetOnly(event: any): boolean {
    var inp = String.fromCharCode(event.keyCode);

    console.log(event.target.value);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
