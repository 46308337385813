import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-namesdialog',
  templateUrl: './namesdialog.component.html',
  styleUrls: ['./namesdialog.component.css'],
})
export class NamesdialogComponent implements OnInit {
  instaId: any;
  instaFollowers: any;
  locationId: any;
  submitted: boolean;
  smsLocation: any;
  locationName: any;
  close() {
    this.dilaog.closeAll();
  }
  loader: any;
  reviewForm: FormGroup;

  constructor(
    private dilaog: MatDialog,
    private fb: FormBuilder,
    private service: AdminService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reviewForm = this.fb.group({
      new: ['', Validators.required],
      id: [''],
      followers: [''],
    });
    console.log(data);
    this.locationName = this.data.locationName;
    // this.smsLocation = this.data.smsLocation;
    this.locationId = this.data.id;
  }

  ngOnInit(): void {
    this.reviewForm.controls['followers'].setValue(this.locationName);
  }
  submit() {
    console.log(this.reviewForm);
    if (this.reviewForm.invalid) {
      this.submitted = true;
    } else {
      this.service
        .updateSmsName(
          this.locationId,
          this.reviewForm.value.new,
          this.reviewForm.value.followers
        )
        .subscribe((data) => {
          console.log(data);
          Swal.fire({
            text: 'Succefully Added',
            showConfirmButton: true, // Hide the "OK" button
            icon: 'success', // You can change the icon type
            timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
          });
          this.dilaog.closeAll();
        });
    }
  }

  get c(): { [key: string]: AbstractControl } {
    return this.reviewForm.controls;
  }
}
