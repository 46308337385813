

<div class="sidenavbar-heade main">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <app-loader *ngIf="loading"> </app-loader>
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Reviews</h1>
        </div>
      
     </div>

     <!-- <div class="card-section my-md-4 d-flex">
        <div class="card mr-md-5" >
            <div class="card-body review_one">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
        </div>
        <div class="card mr-md-5"  >
            <div class="card-body review_two">
                <p>Latest reviews</p>

                 <div class="first_review first"></div>
                 <div  class="first_review second"></div>
                 <div  class="first_review third"></div>
            </div>
        </div>
     </div> -->
     <div class="d-flex justify-content-end mr-md-5 mb-3">
      <p class="mt-2 mr-2 fliter">fliterBy</p>
 <div>
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of fliterBy" [selected]="item == 'All'" value="{{item}}">{{item}}</option>
            </select>
        </div>
        <div class="ml-4">
   <mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date">
    <input matEndDate placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
</div>
     </div>
     
       <div class="row card-section mt-md-4">
    <div class="col" *ngFor="let item of items">
    
      <div class="card" style="height: 100px;">
            <div class="card-body review_one">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
    
    </div>
    </div>
     
    <div class="col-md-5">
      <!-- <div class="card mb-3" >
      <div class="card-body" style="height: 370px;">
                <p>Latest reviews</p>

                 <div class="first_review first"></div>
                 <div  class="first_review second"></div>
                 <div  class="first_review third"></div>
            </div>
      </div> -->
    </div>
  </div>
      <p class="succesfully" *ngIf="userDelete">User delete successfully</p>
       <!-- <div class="d-flex justify-content-end mr-md-5 mb-3">
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of monthsArray" [selected]="item == this.currentMonth" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
         <!-- <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsBarRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div> -->
      <!-- <div class="get">
                <p>15% peoplwe write reviews</p>
            </div> -->
        <div class="chart-customer mt-4">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsSubscriptioncancel" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
      <!-- <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div> -->
        <!-- <div class="get">
                <p>most people write reviews between 7 to 9pm</p>
            </div>
        <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsReviews" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div> -->
  

      <div >
        <div class="type d-flex">

            <div class="d-flex search_view">
                <div class="mr-auto">
                <input (keyup)="applyFilter($event)" placeholder="Search a locationName" >

                </div>
                <div>
                <img src="../../../assets/images/search.png">

                </div>
            </div>

           
             <!-- <div class="type d-flex justify-content-xl-around">
               <div class="d-flex type_view mb-3">
                    <p class="mr-3">Type</p>
                    <select (change)="typeName($event)">
                        <option>select</option>
                        <option>saloon</option>
                        <option>medical</option>
                        <option>restaurent</option>
                        <option>general store</option>
                        <option>coaching</option>
                        <option>food corner</option>
                        <option>pc world</option>
                        <option>health center</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Locations</p>
                    <select (change)="location($event)">
                        <option>Select</option>
                        <option>Single</option>
                        <option>Double</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">reviews</p>
                    <select (change)="freetrail($event)">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div>
                <!-- <div class="d-flex type_view mb-3">
                    <p class="mr-3">Subscription</p>
                    <select (change)="subscription($event)">
                        <option>Not started</option>
                        <option>Completed</option>
                        <option>Expired</option>
                        <option>Cancelled</option>
                    </select>
                </div> -->
                <!-- <div class="d-flex type_view mb-3">
                    <p class="mr-3">Status</p>
                    <select (change)="staus($event)">
                        <option>Free trail running</option>
                        <option>Subscription Running</option>
                        <option>Subscription Running</option>
                        <option>Subscription Cancelled</option>
                        <option>Subscription expired</option>
                        <option>Free trail expired</option>
                        <option>Payment failed</option>
                    </select>
                </div> 
            </div> -->
        </div>
      </div>
     <div class="table-data">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> L-id </th>
    <td mat-cell *matCellDef="let element"> 
      <p class="width">
      {{element.locationId}}

      </p>
     </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> L-name </th>
    <td mat-cell *matCellDef="let element">
      <p class="width">
      {{element.locationName}}

      </p>
      
    
    
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef>Customer_ph </th>
    <td mat-cell *matCellDef="let element">
      
        <p class="width">
      {{element.visitorPhone}}
  

      </p>
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> Customer_name </th>
    <td mat-cell *matCellDef="let element"> 
      
        <p class="width">
      {{element.visitorName}} 

      </p>
    </td>
  </ng-container>
  <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> Visit_date </th>
    <td mat-cell *matCellDef="let element">
      
        <p class="width">
          {{element.visitedDate | date }} 

      </p>
      
    </td>
  </ng-container>
  <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> Review_Req_sent </th>
    <td mat-cell *matCellDef="let element"> 
      
        <p class="width">

      {{element.googleReviewSent ? element.googleReviewSent :'N/A'}}
      </p>
     </td>
  </ng-container>
  <ng-container matColumnDef="freetrail1">
    <th mat-header-cell *matHeaderCellDef> beforeReview </th>
    <td mat-cell *matCellDef="let element">
      
        <p class="width">
      {{element.beforeReview ? element.beforeReview :'N/A'}}

      </p>
     </td>
  </ng-container>
  <ng-container matColumnDef="freetrail2">
    <th mat-header-cell *matHeaderCellDef> afterReview </th>
    <td mat-cell *matCellDef="let element"> 
      
        <p class="width">

      {{element.afterReview ? element.afterReview :'N/A'}} 
      </p>
    </td>
  </ng-container>
  <ng-container matColumnDef="freetrail3">
    <th mat-header-cell *matHeaderCellDef> changeInReview </th>
    <td mat-cell *matCellDef="let element"> 
      <p class="width">

        {{element.changeInReview ? element.changeInReview :'N/A'}} 
      </p>
    </td>
  </ng-container>
  <ng-container matColumnDef="subscription">
    <th mat-header-cell *matHeaderCellDef> Clicked </th>
    <td mat-cell *matCellDef="let element">
      <p class="width">

        {{element.clicked}}
      </p>
       </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Clicked_at </th>
    <td mat-cell *matCellDef="let element"> 
      <p class="width">

        {{element.clikedAt}} 
      </p>
    </td>
  </ng-container>
  <ng-container matColumnDef="sms">
    <th mat-header-cell *matHeaderCellDef> Reminder_SMS </th>
    <td mat-cell *matCellDef="let element">
      <p class="width">

        {{element.Reminder_sms}} 
      </p>
      </td>
  </ng-container>
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef> Loc_category </th>
    <td mat-cell *matCellDef="let element"> 
      <p class="width">

        {{element.category ? element.category :'N/A'}}
      </p>
     </td>
  </ng-container>
 <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class=" button_M cursor">
        <button (click)="campign(element.clickedCount,element.googleReviewSent,element.locationName,element.merchantDeviceId,element.merchantPhone)">Campign</button>  
        
        </div>
      </td>
  </ng-container>
  <!-- Weight Column -->
  <!-- <ng-container matColumnDef="channel">
    <th mat-header-cell *matHeaderCellDef> Interests </th>
    <td mat-cell *matCellDef="let element"> 
      <div class="categroy" *ngFor="let item of element.topicData">
     <h4 >{{item.name ? item.name : 'N/A'}},</h4> 

      </div>
    
    </td>
  </ng-container> -->

  <!-- Symbol Column -->
  <!-- <ng-container matColumnDef="views">
    <th mat-header-cell *matHeaderCellDef> Total summary created </th>
    <td mat-cell *matCellDef="let element"> {{element.followtopicCount}} </td>
  </ng-container> -->
  <!-- Weight Column -->
  <!-- <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> Bookmarks </th>
    <td mat-cell *matCellDef="let element"> {{element.bookmarkDataCount}} </td>
  </ng-container> -->

  <!-- Symbol Column -->
  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> actions </th>
    <td mat-cell *matCellDef="let element"> <div class="d-flex">
        <div class="edit-img">
           <img  src="../../../assets/images/e-3.png">
        </div>
        <div class="toggle">
          <label class="toggle">
  <input type="checkbox" id="toggleButton" (change)="toggleChanged($event)">
  <span class="slider"></span>
</label>
        </div>
         <div class="edit-img">
           <a>
            <img class="cursor"  src="../../../assets/images/delete.png" (click)="deleteUser(element._id)">
            </a>
           
        </div>
       
       </div></td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>
</div>
</div>
   