import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/_services/admin.service';
import { UpdatefollowersdialogComponent } from './updatefollowersdialog/updatefollowersdialog.component';
import { AddfollowersdialogComponent } from './addfollowersdialog/addfollowersdialog.component';
import { Subscription } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-instagramfollowers',
  templateUrl: './instagramfollowers.component.html',
  styleUrls: ['./instagramfollowers.component.css'],
})
export class InstagramfollowersComponent implements OnInit {
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'Cname4',
    // 'Cname5',
    'Cname1',
    'Cname2',
    'Cname3',
    'action',
  ];

  pageSize: any = 10;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  loader: boolean = false;
  totalSub: any;
  Totalsubscribers: any = [];
  loading: boolean;
  totalSummary: any;
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  query: any;
  private refreshSubscription: Subscription;
  filterValue: any = '';

  constructor(private service: AdminService, private dialog: MatDialog) {
    this.refreshSubscription = this.service.getRefreshSignal().subscribe(() => {
      this.maincomponent();
    });
  }

  ngOnInit(): void {}
  maincomponent(): void {
    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.MerchantInstagram(this.query).subscribe((result: any) => {
      console.log(result);
      this.ELEMENT_DATA = result.data;
      this.totalSummary = result.totalCount;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    // console.log(filterValue);
    this.loader = true;

    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
      locationName: this.filterValue,
    };
    this.service.MerchantInstagram(this.query).subscribe((result: any) => {
      console.log(result);
      this.ELEMENT_DATA = result.data;
      this.totalSummary = result.totalCount;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.loading = true;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
      locationName: this.filterValue,
    };
    this.service.MerchantInstagram(this.query).subscribe((result: any) => {
      console.log(result);
      this.ELEMENT_DATA = result.data;
      this.totalSummary = result.totalCount;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  updateFollowers(id: any, followers: any, instaId: any) {
    this.dialog.open(UpdatefollowersdialogComponent, {
      // disableClose: true,
      data: {
        id: id,
        followers: followers,
        instaId: instaId,
      },
    });
  }
  addFollowers(id: any, followers: any, instaId: any) {
    this.dialog.open(AddfollowersdialogComponent, {
      // disableClose: true,
      // data: { id: id },
      data: {
        id: id,
        followers: followers,
        instaId: instaId,
      },
    });
  }
}
