<div class="main ml-4 mt-3">
    <div>
        <div class="cursor" (click)="back()">
            <a>Back</a>
        </div>

        <div class="sms-req">
           <h5>SMS Request</h5>
        </div>
        <app-loader *ngIf="loading"></app-loader>
<div *ngIf="smsReq" 
[formGroup]="dummydata"
> 
        <div 
        >
           <div class="L-input mt-4">
                <select  class="form-control" placeholder="Please select" formControlName="location">
                  <option value="" selected>Select the location name</option>
                  <option [value]="review" *ngFor="let review of locations">{{review}}</option>
                </select>
        <div *ngIf="c.location.errors?.required && Submitted" class="invalid-feedback m-t d-block">Location Name is required </div>

        <!-- <div *ngIf="stars" class="invalid-feedback m-t d-block">Stars is required </div> -->

            </div>

            <div  class="d-flex sms-1 mt-3">
                <h6>Location Name : </h6>
                <p> {{ this.dummydata.value.location}}</p>
            </div>

            <!-- <div  class="d-flex sms-1">
                <h6>This location registration date is :</h6>
                <p> </p>
            </div> -->
<div class="mt-4">
   <mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date" formControlName="start">
    <input matEndDate placeholder="End date" formControlName="end">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
        <div *ngIf="c.start.errors?.required && Submitted" class="invalid-feedback m-t d-block">Date is required </div>
        <!-- <div *ngIf="c.end.errors?.required && Submitted" class="invalid-feedback m-t d-block">Date is required </div> -->

</div>
       
    </div>
    <div class="d-flex">
        <div class="clic">
            <p>SMS sent</p>
            <input class="form-control c-1" formControlName="sms">
        <div *ngIf="c.sms.errors?.required && Submitted" class="invalid-feedback m-t d-block">count is required </div>

        </div>
        <div class="clic ml-5 pl-4">
            <p>Clicks</p>
            <input class="form-control c-1" formControlName="clicks">
        <div *ngIf="c.clicks.errors?.required && Submitted" class="invalid-feedback m-t d-block">count is required </div>

        </div>
        <div class="clic ml-5 pl-4">
            <p>Reviewed</p>
            <input class="form-control c-1" formControlName="review">
        <div *ngIf="c.review.errors?.required && Submitted" class="invalid-feedback m-t d-block">count is required </div>

        </div>
    </div>
        <div class="submit">
            <button class="cursor" (click)="submit()">Submit</button>
        </div>
        </div>
<div *ngIf="success"> 
        <div>

           <div class="L-input mt-4">
                          <select  class="form-control" placeholder="Please select">
                  <option value="" selected>
                    {{        this.dummydata.value.location}}
                    
                  </option>
                  <!-- <option [value]="review" *ngFor="let review of locations">{{review}}</option> -->
                </select>
        <!-- <div *ngIf="stars" class="invalid-feedback m-t d-block">Stars is required </div> -->

            </div>

            <div  class="d-flex sms-1 mt-3">
                <h6>Location Name :</h6>
                <p> 
                    {{        this.dummydata.value.location}}
</p>
            </div>
             <h3 class="succeed">Data Submitted successfully</h3>
            <div  class="d-flex sms-1 mt-1">
                <h6 class="m-0">SMS Sent count :&nbsp;</h6>
                <p class="m-0"> {{
        this.dummydata.value.sms}}</p>
            </div>

            <div  class="d-flex sms-1 mt-1">
                <h6 class="m-0"> Clicks count :&nbsp;</h6>
                <p class="m-0"> {{
        this.dummydata.value.clicks
                    }}</p>
            </div>

            <div  class="d-flex sms-1 mt-1">
                <h6 class="m-0">Reviewed count:&nbsp;</h6>
                <p class="m-0">{{
        this.dummydata.value.review
                    }}</p>
            </div>

            <div class="mt-5 pt-5">
  <a class="mt-5 pt-5" (click)="add()">Add another entry</a>

            </div>
        </div>

</div>
 