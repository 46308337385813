import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
@Component({
  selector: 'app-merchants',
  templateUrl: './merchants.component.html',
  styleUrls: ['./merchants.component.css'],
})
export class MerchantsComponent implements OnInit {
  highcharts = Highcharts;
  items: any[] = [];
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'email',
    'freetrail',
    'freetrail1',

    'ctype',
    'Locations',
    'singupdate',
    // 'subscription',

    'action',
  ];

  ELEMENT_DATA1: Element[] = [];
  ELEMENT_DATA: Element[] = [];

  dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
  dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
  chartOptionsRevered: any;
  chartOptionsActiveSubscriptions: any;
  Language: any = ['This month', 'This week', 'Today'];
  chartfirstCity: any;
  chartSecondCity: any;
  chartThirdCity: any;
  chartFourthCity: any;
  currentMonth: string;
  month: any;
  monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  query: any;
  currentCount: any;
  subscribers: any = [];
  pageSize: any = 10;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  loader: boolean = false;
  totalSub: any;
  Totalsubscribers: any = [];
  loading: boolean;
  totalSummary: any;
  chartOptionsBarRevered: any;
  address: any;
  state: any;
  constructor(private service: AdminService) {}

  ngOnInit(): void {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth(); // This gets the month index (0-11)
    const monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentMonth = monthsArray[monthIndex]; // Get the month name using the index
    this.currentMonth = currentMonth;
    this.loader = true;

    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.merchant(this.query).subscribe((result: any) => {
      console.log(result);
      this.loader = false;
      this.ELEMENT_DATA1 = result.data;
      // this.address = result.data[4].locations[0]?.formatted_address;
      // const addressParts = this.address.split(',');
      // // Assuming the second last part of the address is the state (in this case 'Delhi')
      // this.state = addressParts[addressParts.length - 3].trim();
      // console.log(this.state);
      this.totalSummary = result.totalMerchants;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
      this.items = [{ name: 'Total merchants', number: result.totalMerchants }];
    });

    this.splineChart();
    // this.areaChart();
  }
  splineChart() {
    this.chartOptionsBarRevered = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      title: {
        text: 'Customers by Business Categories',
      },
      xAxis: {
        type: 'categories',
        categories: ['Food', 'Saloon', 'Beauty', 'Dental', 'Skin Care'],
      },
      yAxis: {
        title: {
          text: 'Customers by Business Categories',
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.2, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
      },
      colors: ['#98b8eb'],

      tooltip: {
        // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
      },
      series: [
        {
          // name: this.data,
          // data: this.count,
          data: [
            {
              name: 'Food',
              y: 150000,
              drilldown: 'Food',
            },
            {
              name: 'Saloon',
              y: 10000,
              drilldown: 'Saloon',
            },
            {
              name: 'Baeuty',
              y: 50000,
              drilldown: 'Baeuty',
            },
            {
              name: 'Dental',
              y: 60000,
              drilldown: 'Dental',
            },
            {
              name: 'Skin care',
              y: 20000,
              drilldown: 'Skin care',
            },
          ],
        },
      ],
    };
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.loader = true;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage,
    };

    this.service.merchant(this.query).subscribe((result: any) => {
      console.log(result);
      this.loader = false;
      this.ELEMENT_DATA1 = result.data;
      this.totalSummary = result.totalMerchants;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
      this.items = [{ name: 'Total merchants', number: result.totalMerchants }];
    });
  }
}
