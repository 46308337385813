

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Subscriptions</h1>
        </div>
            <app-loader *ngIf="loader"></app-loader>

       <!-- <div class="d-flex   language-header">
       
            <select   class="selectpicker" >
                <option  *ngFor="let item of Language" [selected]="item == 'This month'" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
     </div>

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
        </div>
     </div>
     <!-- <div class="d-flex justify-content-end mr-md-5 mb-3">
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of monthsArray" [selected]="item == this.currentMonth" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
        <div><h2>Merchant Subscriptions</h2>
             </div>

         <div class="table-data mb-5 pb-3 mt-4">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> mId </th>
    <td mat-cell *matCellDef="let element">
      <p class="Id"> {{element.merchantId}}</p>  </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> Merchant phone </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element.mobileNumber}}</p></td>
  </ng-container>
  <ng-container matColumnDef="Cname1">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element.email}}</p></td>
  </ng-container>
  <ng-container matColumnDef="Cname2">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element.merchantName}}</p></td>
  </ng-container>
  <ng-container matColumnDef="Cname3">
    <th mat-header-cell *matHeaderCellDef> Location Name</th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element.locationName}}</p></td>
  </ng-container>


  <!-- Weight Column -->
  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> startDate </th>
    <td mat-cell *matCellDef="let element"><p class="Id">{{element?.startDate}}</p></td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> endDate </th>
    <td mat-cell *matCellDef="let element"> {{element?.endDate}} </td>
  </ng-container>

  <ng-container matColumnDef="subscription">
    <th mat-header-cell *matHeaderCellDef> status </th>
    <td mat-cell *matCellDef="let element"> {{element?.status}} </td>
  </ng-container>
    <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> ExpiringOn </th>
    <td mat-cell *matCellDef="let element"> {{element?.paymentStatus}} </td>
  </ng-container>
  <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> price </th>
    <td mat-cell *matCellDef="let element"> {{element?.isEmailSend}} </td>
  </ng-container>
  <!-- <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Invited </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> invite_clicks </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="leads">
    <th mat-header-cell *matHeaderCellDef> Leads </th>
    <td mat-cell *matCellDef="let element"> {{element.totalLeads}} </td>
  </ng-container>
  <ng-container matColumnDef="firstvisits">
    <th mat-header-cell *matHeaderCellDef> first_visits </th>
    <td mat-cell *matCellDef="let element"> {{element.firstVisit}} </td>
  </ng-container>
  <ng-container matColumnDef="conversion">
    <th mat-header-cell *matHeaderCellDef> Conversion </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="Revenue">
    <th mat-header-cell *matHeaderCellDef> Revenue </th>
    <td mat-cell *matCellDef="let element"> {{element.revenue}} </td>
  </ng-container> -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">view </div></td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> 
  <!-- <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> -->
     </div>
     <!-- <div class="d-flex justify-content-between" >
      <div>
        <h2 class="mt-3 ml-3">Subscription plans</h2>
      </div>
     
     </div> -->
 <!-- /*    <div class="table-data">
        <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> id </th>
    <td mat-cell *matCellDef="let element"> 
      <P class="Id">{{element._id}}</P> </td>
  </ng-container>

  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> type </th>
    <td mat-cell *matCellDef="let element"> 
      <p >{{element?.type}}</p></td>
  </ng-container>

  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> locations </th>
    <td mat-cell *matCellDef="let element">
      <p > {{element?.locations}}</p>
      
     </td>
  </ng-container>

  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> contacts </th>
    <td mat-cell *matCellDef="let element"> {{element?.contacts}} </td>
  </ng-container>
  <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> perdayPrice </th>
    <td mat-cell *matCellDef="let element"> {{element?.per_day_price}} </td>
  </ng-container>
  <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> referralRequests </th>
    <td mat-cell *matCellDef="let element"> {{element?.referral_requests}} </td>
  </ng-container>
  <ng-container matColumnDef="subscription">
    <th mat-header-cell *matHeaderCellDef> reviewRequests </th>
    <td mat-cell *matCellDef="let element"> {{element?.review_requests}} </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> dataExports </th>
    <td mat-cell *matCellDef="let element"> {{element.data_exports}} </td>
  </ng-container>
  <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> staffLogins </th>
    <td mat-cell *matCellDef="let element"> {{element.staff_logins}} </td>
  </ng-container>
  <ng-container matColumnDef="leads">
    <th mat-header-cell *matHeaderCellDef> weeklyReports? </th>
    <td mat-cell *matCellDef="let element"> {{element.weekly_reports}} </td>
  </ng-container>
  <ng-container matColumnDef="firstvisits">
    <th mat-header-cell *matHeaderCellDef> description </th>
    <td mat-cell *matCellDef="let element"><p class="Id"> {{element.description}}</p> </td>
  </ng-container>
 
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">view </div></td>
  </ng-container>
 
  <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
</table> -->
  <!-- <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p> -->
<!-- <mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> -->
     <!-- </div> -->
      <!-- <div class="chart-customer">
         
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>


      <div class="chart-customer">
        <div class="dollar_box d-flex">
            <img src="../../../assets/images/hand.png">
            <p class="ml-3">35,000</p>
        </div>
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsActiveSubscriptions" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
      
     <div class="visitors_city">
        <div class="city_v d-flex mt-md-4">
           <div class="city-header customer_header mr-auto">
            <h1>Subscriptinos by City</h1>
           </div>
           <div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">state</p>
                    <select>
                        <option>Telagana</option>
                        <option>Andra pradesh</option>
                    </select>
                </div>
             
           </div>
              <div class="d-flex type_view mb-3 ml-4 mr-4">
                    <p class="mr-3">Saloon</p>
                    <select>
                        <option>Saloon</option>
                        <option>Saloon</option>
                    </select>
                </div>
        </div>

        <div class="row mr-3">
            <div class="col-md-6">
                 <div class="dollar_box first_city d-flex">
            <img src="../../../assets/images/hand.png">
            <p>Visits</p>
            <p class="ml-3">35,000</p>
        </div>
                <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartfirstCity" 
             style="display:block"></highcharts-chart>
            </div>
            <div class="col-md-6">
                <div class="dollar_box second_city d-flex">
            <img src="../../../assets/images/hand.png">
            <p>Visits</p>
            <p class="ml-3">35,000</p>
        </div>
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartSecondCity" 
             style="display:block"></highcharts-chart>
            </div>
        </div>
        <div class="row mt-md-4 mr-3">
            <div class="col-md-6">
                <div class="dollar_box third_city d-flex">
            <img src="../../../assets/images/hand.png">
            <p>Visits</p>
            <p class="ml-3">35,000</p>
        </div>
                <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartThirdCity" 
             style="display:block"></highcharts-chart>
            </div>
            <div class="col-md-6">
                <div class="dollar_box four_city d-flex">
            <img src="../../../assets/images/hand.png">
            <p>Visits</p>
            <p class="ml-3">35,000</p>
        </div>
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartFourthCity" 
             style="display:block"></highcharts-chart>
            </div>
        </div>
     </div> -->
      </div>
      </div>