import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-refferalmsg',
  templateUrl: './refferalmsg.component.html',
  styleUrls: ['./refferalmsg.component.css'],
})
export class RefferalmsgComponent implements OnInit {
  categorys: any[] = [
    { label: 'Saloon', value: 'Saloon' },
    { label: 'Skin Care', value: 'Skin Care' },
    { label: 'Dental Clinic', value: 'Dental Clinic' },
    { label: 'Hotel', value: 'Hotel' },
    { label: 'Beauty Parlour', value: 'Beauty Parlour' },
    { label: 'Restaurant', value: 'Restaurant' },
    { label: 'Cafe', value: 'Cafe' },
    { label: 'Event Planning', value: 'Event Planning' },
    { label: 'Jewellery', value: 'Jewellery' },
    { label: 'Legal firm', value: 'Legal firm' },
    { label: 'Gym', value: 'Gym' },
    { label: 'Spa & Wellness', value: 'Spa & Wellness' },
    { label: 'Interior Designing', value: 'Interior Designing' },
    { label: 'Architect Firm', value: 'Architect Firm' },
    { label: 'CA Firm', value: 'CA Firm' },
    { label: 'Clothing & Textiles', value: 'Clothing & Textiles' },
    { label: 'Educational Institution', value: 'Educational Institution' },
    { label: 'Car & Bike repair', value: 'Car & Bike repair' },
    { label: 'Pet shop', value: 'Pet shop' },
    { label: 'Pub & Bar', value: 'Pub & Bar' },
    { label: 'Furniture showroom', value: 'Furniture showroom' },
  ];
  readonly: boolean = true;
  categorysData: any;
  categoryText: any;
  selectedItem: any = 'Please select';
  category: any;
  text: boolean;
  constructor(private service: AdminService) {}

  ngOnInit(): void {
    this.service.categorys().subscribe((category): any => {
      console.log(category);
      this.categorysData = category.data[0].categoryData;
    });
  }
  edit() {
    this.readonly = false;

    this.text = true;
    setTimeout(() => {
      this.text = false;
    }, 2000);

    // Swal.fire({
    //   text: 'Edit accessed',
    //   showConfirmButton: true, // Hide the "OK" button
    //   icon: 'success', // You can change the icon type
    //   timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
    // });
  }
  update() {
    let obj = {
      category: this.category,
      text: this.categoryText,
    };
    this.service.updateCategorys(obj).subscribe((result) => {
      console.log(result);
      Swal.fire({
        text: 'Succefully Added',
        showConfirmButton: true, // Hide the "OK" button
        icon: 'success', // You can change the icon type
        timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
      });
    });
  }
  categoryChange(e: any) {
    // console.log(e.target);
    console.log(e);
    this.category = e.category;
    this.categoryText = e.text;
  }
}
