<div class="main ml-4 mt-3">
    <div>
        <div class="cursor" (click)="back()">
            <a>Back</a>
        </div>

        <div class="sms-req">
           <h5>Referrals</h5>
        </div>
<div> 
        <div>
       <div class="d-flex justify-content-between">

            <div class="L-input mt-4">
                <select  class="form-control" placeholder="Please select" (change)="review($event)">
                  <option value="" selected>Select the location name</option>
                  <option [value]="review" *ngFor="let review of locations">{{review}}</option>
                </select>
        <!-- <div *ngIf="stars" class="invalid-feedback m-t d-block">Stars is required </div> -->

            </div>

            <div class="mr-5 mt-4">
  <a class="">Add custom data</a>

            </div>
       </div>
       <div  class="d-flex sms-1 mt-3">
                <h6>Location Name : </h6>
                <p> {{ locationName}}</p>
            </div>
         
<div *ngIf="formone" 

>
   
<div class="mt-4">
   <mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date">
    <input matEndDate placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
</div>
     <div class="d-flex">
        <div class="clic">
            <p>SMS sent</p>
            <input class="form-control c-1">
        </div>
        <div class="clic ml-5 pl-4">
            <p>Clicks</p>
            <input class="form-control c-1">
        </div>
        <div class="clic ml-5 pl-4">
            <p>leads</p>
            <input class="form-control c-1">
        </div>
        <div class="clic ml-5 pl-4">
            <p>First visits</p>
            <input class="form-control c-1">
        </div>
    </div>
        <div class="submit">
            <button class="cursor">Submit</button>
        </div>  
    </div>
    


    <div>

        <div>
            
        <div class="sms-req d">
           <a (click)="lead(c.id)" class="cursor"   [ngClass]="{ 'active': c.id === activeLink }" *ngFor="let c of customData">{{c.name}}</a>
        </div>




        <div class="leads_data" *ngIf="leadsBar" 
[formGroup]="dummydata"
        >
          <div class="mt-4">
   <mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date" formControlName="start">
    <input matEndDate placeholder="End date" formControlName="end">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
</div>
     <div  class="sms-1">
                <h6>For the above time period, Referal sms sent: 100, clicks: 50</h6>
            </div>
          
                    <div class="L-input mt-4">
                <select  class="form-control" placeholder="Please select" formControlName="customerMobile">
                  <option value="" selected>Select the Customer number</option>
                  <option [value]="review" *ngFor="let review of customersNumber">{{review}}</option>
                </select>
<p *ngIf="Error != ''" class="invalid-feedback m-t d-block">{{Error}}</p>

        <!-- <div *ngIf="stars" class="invalid-feedback m-t d-block">Stars is required </div> -->

            </div>
                 <div class="clic">
            <p>How many leads do you want to create?</p>
            <input class="form-control c-1 mt-3" formControlName="count">
        </div>
                <div class="mt-3">
  <a class="a cursor" (click)="generate()">Generate Leads</a>

            </div>

            <app-loader *ngIf="loading"></app-loader>
        <div class="mt-5 pt-5" *ngIf="cus">
             <h3 class="succeed">{{leadsData1.length}} Leads generated successfully</h3>

            <div class="d-flex" *ngFor="let v of leadsData1">
        <div class="clic">
            <p>L_id</p>
            <input class="form-control c-1 c-3" [value]="v._id">
        </div>
        <div class="clic ml-5 pl-4">
            <p>L_name</p>
            <input class="form-control c-1 c-3" [value]="v.name">
        </div>
        <div class="clic ml-5 pl-4">
            <p>L_Phone</p>
            <input class="form-control c-1 c-3" [value]="v.mobileNumber">
        </div>
        <div class="clic ml-5 pl-4">
            <p>L_date</p>
            <input class="form-control c-1 c-3" [value]="v.createdAt | date">
        </div>
    </div>
        </div>
        </div>   
        
              <div class="leads_data" *ngIf="firstvisited">
            <!-- <div class="mt-4">
   <mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date">
    <input matEndDate placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
</div> -->
            <app-loader *ngIf="leadsLOads"></app-loader>

     <div  class="sms-1">
                <h6>We found {{dataSource.length}} leads for this location</h6>
            </div>

            <div class="table-1">

<!-- <p *ngIf="leadsError != ''">{{leadsError}}</p> -->
                
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef> id. </th>
    <td mat-cell *matCellDef="let element"> {{element._id}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef> Phone </th>
    <td mat-cell *matCellDef="let element"> {{element.mobileNumber}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="symbol">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{element.createdAt | date}} </td>
  </ng-container>
  <ng-container matColumnDef="firstvisited">
    <th mat-header-cell *matHeaderCellDef> firstvisited </th>
    <td mat-cell *matCellDef="let element"> {{element.firstVisit}} </td>
  </ng-container>
  <ng-container matColumnDef="checked">
    <th mat-header-cell *matHeaderCellDef> Select </th>
    <td mat-cell  *matCellDef="let row">
    <section class="example-section">
       <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </section> </td>
  </ng-container>
<ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> update </th>
    <td mat-cell  *matCellDef="let element">
<a class="a cursor" (click)="updateVisited(element._id)">
    Update
</a>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


            </div>
            </div>
            <app-loader *ngIf="refLOads"></app-loader>
              <div class="leads_data" *ngIf="refferal">
            <!-- <div class="mt-4">
   <mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date">
    <input matEndDate placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
</div> -->
     <div  class="sms-1">
                <h6>{{dataSource1.length}} customers who referred their friends</h6>
            </div>

            <div class="table-1">
                <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> R_Id. </th>
    <td mat-cell *matCellDef="let element"> {{element.referralProgramId}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef> R_Phone </th>
    <td mat-cell *matCellDef="let element"> {{element.referred_mobileNumber}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> R_Name </th>
    <td mat-cell *matCellDef="let element"> {{element.referred_by}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="program">
    <th mat-header-cell *matHeaderCellDef> R_program </th>
    <td mat-cell *matCellDef="let element"> {{element.program}} </td>
  </ng-container>
  <ng-container matColumnDef="hold">
    <th mat-header-cell *matHeaderCellDef> R_threshold </th>
    <td mat-cell *matCellDef="let element"> {{element.referrer_threshold}} </td>
  </ng-container>
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef> RewardValue </th>
    <td mat-cell  *matCellDef="let element">
   {{element?.rewardvalue }}
</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> status </th>
    <td mat-cell  *matCellDef="let element">
<a class="a cursor" (click)="updateThreshold(element)">Update</a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
</table>


            </div>
            </div>
            </div>
        </div>
    </div>
</div>
            
        </div>
<div> 
       

</div>
 