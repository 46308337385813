

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->

    <app-loader *ngIf="loading"></app-loader>
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Cancellation</h1>
        </div>
       
     </div>

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.number  ? item.number : 0}}</p>

                <h3>{{item.name}}</h3>
            </div>
        </div>
     </div>
      <p class="succesfully" *ngIf="userDelete">User delete successfully</p>
      <div class="d-flex justify-content-end mr-md-5 mb-3">
       
            <!-- <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of monthsArray" [selected]="item == this.currentMonth" value="{{item}}">{{item}}</option>
            </select> -->
        </div>
        <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
      <div class="get">
                <p>5% of free trails gets cancelled</p>
            </div>
        <div class="chart-customer">
            
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsfreetrailCancel" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
      <div class="get">
                <p>5% of free subscription</p>
            </div>
        <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsSubscriptioncancel" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
       <div class="get">
                <p>Cancellation timeline</p>
            </div>
         <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsCancellationtimeline" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
        <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartoptionsCancellationsByBusinessCategories" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
        <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartoptionsCancellationsByCity" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
        <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartoptionsCancellationsReason" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div> 
</div>
      </div> 

      <div >
        
             <!-- <div class="d-flex justify-content-between" >
      <div>
        <h2 class="mt-3 ml-3">Cancellation Requests locations</h2>
      </div>
      <div class="d-flex search_view">
        <div>
                <img src="../../../assets/images/search.png">

                </div>
                <div class="">
                <input  >

                </div>
                
            </div>
             
     </div>
      <div class="type">

           
            <div class="type d-flex justify-content-xl-around"> -->
                 <!-- <div class="d-flex search_view">
                <div class="mr-auto">
                <input   placeholder="Search a customer" >

                </div>
                <div>
                <img src="../../../assets/images/search.png">

                </div>
            </div> -->
                <!-- <div class="d-flex type_view mb-3">
                    <p class="mr-3">Category</p>
                    <select >
                        <option>select</option>

                        <option>Not started</option>
                        <option>Completed</option>
                        <option>Expired</option>
                        <option>Cancelled</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">City</p>
                    <select >
                        <option>select</option>

                        <option>Not started</option>
                        <option>Completed</option>
                        <option>Expired</option>
                        <option>Cancelled</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Type</p>
                    <select >
                        <option>select</option>
                        <option>Free trail running</option>
                        <option>Subscription Running</option>
                        <option>Subscription Running</option>
                        <option>Subscription Cancelled</option>
                        <option>Subscription expired</option>
                        <option>Free trail expired</option>
                        <option>Payment failed</option>
                    </select>
                </div>
            </div>
        </div>
      </div> -->
     <!-- <div class="table-data mb-5 pb-3 mt-4">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> L-id </th>
    <td mat-cell *matCellDef="let element">
      <p class="Id"> {{element.merchantId}}</p>  </td>
  </ng-container>

  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> Owner </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element?.locationId}}</p></td>
  </ng-container>

  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> L_name </th>
    <td mat-cell *matCellDef="let element"><p class="Id">{{element?.referralProgramId}}</p></td>
  </ng-container>

  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> Free trail </th>
    <td mat-cell *matCellDef="let element"> {{element?.locationName}} </td>
  </ng-container>
  <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> Subcription </th>
    <td mat-cell *matCellDef="let element"> {{element?.smsSent}} </td>
  </ng-container>
  <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{element?.free_trail}} </td>
  </ng-container>
  <ng-container matColumnDef="subscription">
    <th mat-header-cell *matHeaderCellDef> Reason </th>
    <td mat-cell *matCellDef="let element"> {{element?.clicks}} </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Growth </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> Sales Rep </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
   
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">view </div></td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
   <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> -->
     </div>
     <div class="d-flex justify-content-between" >
      <div>
        <h2 class="mt-3 ml-3">Cancellation  locations</h2>
      </div>
      <div class="d-flex search_view">
        <div>
                <img src="../../../assets/images/search.png">

                </div>
                <div class="">
                <input  >

                </div>
                
            </div>
             
     </div>
      <div class="type">

           
            <div class="type d-flex justify-content-xl-around">
                 <!-- <div class="d-flex search_view">
                <div class="mr-auto">
                <input   placeholder="Search a customer" >

                </div>
                <div>
                <img src="../../../assets/images/search.png">

                </div>
            </div> -->
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Category</p>
                    <select >
                        <option>select</option>

                        <option>Not started</option>
                        <option>Completed</option>
                        <option>Expired</option>
                        <option>Cancelled</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">City</p>
                    <select >
                        <option>select</option>

                        <option>Not started</option>
                        <option>Completed</option>
                        <option>Expired</option>
                        <option>Cancelled</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Type</p>
                    <select >
                        <option>select</option>
                        <option>Free trail running</option>
                        <option>Subscription Running</option>
                        <option>Subscription Running</option>
                        <option>Subscription Cancelled</option>
                        <option>Subscription expired</option>
                        <option>Free trail expired</option>
                        <option>Payment failed</option>
                    </select>
                </div>
            </div>
        </div>
     <div class="table-data">
        <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> L-id </th>
    <td mat-cell *matCellDef="let element"> 
      <P class="Id">{{element.locationId}}</P> </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> Owner </th>
    <td mat-cell *matCellDef="let element"> 
      <p class="Id">{{element?.ownerName}}</p></td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> L_name </th>
    <td mat-cell *matCellDef="let element">
      <p class="Id"> {{element?.locationName}}</p>
      
     </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> Free trail </th>
    <td mat-cell *matCellDef="let element"> {{element?.FreeTrail}} </td>
  </ng-container>
  <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> Subcription </th>
    <td mat-cell *matCellDef="let element"> {{element?.Subscription}} </td>
  </ng-container>
  <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{element?.Date}} </td>
  </ng-container>
  <ng-container matColumnDef="subscription">
    <th mat-header-cell *matHeaderCellDef> Reason </th>
    <td mat-cell *matCellDef="let element"> {{element?.Subscription}} </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Growth </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>
  <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef>Sales Rep </th>
    <td mat-cell *matCellDef="let element"> {{element.mobileNumber}} </td>
  </ng-container>
 
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">view </div></td>
  </ng-container>
 
  <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
</table>
  <!-- <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p> -->
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>
<!-- </div>
</div> -->
   