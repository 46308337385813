import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
export interface PeriodicElement {
  Cid: any;
  Cname: any;
  ctype: any;
  Step1: any;
  Step2: any;
  Step3: any;
  Step4: any;
  freetrail: any;
  status: any;
  action: any;
}

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css'],
})
export class ActivationComponent implements OnInit {
  freetrailChartConversion: any;
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'ctype1',
    'ctype2',
    'ctype3',
    'ctype4',
    'Step1',
    'Step2',
    'Step3',
    'Step4',
    'status',
    'freetrail',
    'action',
  ];
  Language: any = ['This month', 'This week', 'Today'];
  loader: boolean = false;
  userQuery!: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean = false;
  highcharts = Highcharts;
  loading: boolean;
  data: any = [];
  chartData: any;
  count: any = [];
  constructor(private service: AdminService) {}

  ngOnInit(): void {
    this.userApisList();
  }
  userApisList() {
    this.loading = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.referralActivations(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;
      this.chartData = res.chartData;
      this.chartData.forEach((res: any) => {
        this.count.push(res.count);
        this.data.push(res.step);
      });
      this.freetrailChartConversionBtn();
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalReferral;
      this.items = [
        { name: 'Total Referral', number: res.totalReferral },
        { name: 'Total Reward', number: res.totalReward },
        // { name: 'Total Free', number: res.totalFree },
        { name: 'Total Active', number: res.totalActive },
        { name: 'Total Inactive', number: res.totalInactive },
      ];
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.loading = true;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    console.log(this.pageSize);
    this.loader = true;
    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage,
    };
    this.service.referralActivations(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalReferral;
    });
  }
  freetrailChartConversionBtn() {
    this.freetrailChartConversion = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'categories',
        categories: this.data,
      },
      yAxis: {
        title: {
          text: 'Referral program set up activity',
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.2, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
      },
      colors: ['#068466'],

      tooltip: {
        // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
      },

      series: [
        {
          name: this.data,
          data: this.count,
          // data: [
          //   {
          //     name: 'Step 1',
          //     y: 63.06,
          //     drilldown: 'Step 1',
          //   },
          //   {
          //     name: 'Step 2',
          //     y: 19.84,
          //     drilldown: 'Step 2',
          //   },
          //   {
          //     name: 'Step 3',
          //     y: 4.18,
          //     drilldown: 'Step 3',
          //   },
          //   {
          //     name: 'Step 4',
          //     y: 4.12,
          //     drilldown: 'Step 4',
          //   },
          //   {
          //     name: 'Step 5',
          //     y: 2.33,
          //     drilldown: 'Step 5',
          //   },
          //   {
          //     name: 'LIVE',
          //     y: 2.33,
          //     drilldown: 'LIVE',
          //   },
          // ],
        },
      ],
    };
  }
}
