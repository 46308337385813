import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  highcharts = Highcharts;
  items: any[] = [];
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'freetrail',

    'ctype',
    'Locations',
    'singupdate',
    'subscription',

    'action',
    'action2',
    'action3',
  ];

  ELEMENT_DATA1: Element[] = [];
  ELEMENT_DATA: Element[] = [];

  dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
  dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
  chartOptionsRevered: any;
  chartOptionsActiveSubscriptions: any;
  Language: any = ['weekly', 'daily', 'monthly'];
  chartfirstCity: any;
  chartSecondCity: any;
  chartThirdCity: any;
  chartFourthCity: any;
  currentMonth: string;
  month: any;
  monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  query: any;
  currentCount: any;
  subscribers: any = [];
  pageSize: any = 10;
  currentPage: any = 0;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  loader: boolean = false;
  totalSub: any;
  Totalsubscribers: any = [];
  loading: boolean;
  constructor(private service: AdminService) {}

  ngOnInit(): void {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth(); // This gets the month index (0-11)
    const monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentMonth = monthsArray[monthIndex]; // Get the month name using the index
    this.currentMonth = currentMonth;
    this.loader = true;

    this.query = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    // this.service.transcations().subscribe((result) => {
    //   console.log(result);
    //   this.loader = false;
    //   // this.ELEMENT_DATA = result.response.subscriptionPlans;
    //   this.ELEMENT_DATA1 = result.dashBoardData;

    //   // this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
    //   this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
    //   this.items = [
    //     { name: 'Total transcations', number: result.totalTransctions },
    //     { name: 'Total failed', number: result.failedTransctions },

    //   ];
    // });

    // this.chartthirdcity();
    // this.areaChart();
  }

  langugeChange(e: any) {
    this.month = e.target.value;
    this.subscribers = [];
    this.Totalsubscribers = [];

    this.query = {
      month: this.month,
      // type: 'month',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.subsribers(this.query).subscribe((result) => {
      this.currentCount = result.weeklyCounts.activeWeeklyCount;
      this.currentCount.forEach((response: any) => {
        this.subscribers.push(response.count);
        console.log(this.subscribers);
      });
      this.totalSub = result.weeklyCounts.totalSubscriptionweeklycount;
      this.totalSub.forEach((response: any) => {
        this.Totalsubscribers.push(response.count);
        console.log(this.subscribers);
      });
      this.chartOptionsRevered = {
        chart: {
          type: 'area',
        },
        title: {
          text: 'Subscriptions Growth',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
          gridLineWidth: 0,
        },
        legend: {
          reversed: true,
        },
        colors: ['#1F46E8'],
        series: [
          {
            name: 'Subscriptions Growth',
            data: this.Totalsubscribers,
          },
        ],
      };
      this.chartOptionsActiveSubscriptions = {
        chart: {
          type: 'spline',
        },
        title: {
          text: 'Active Subscriptions',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
          gridLineWidth: 0,
        },
        legend: {
          reversed: true,
        },
        colors: ['#068466'],
        series: [
          {
            name: 'Active Subscriptions',
            data: this.subscribers,
          },
        ],
      };
    });
  }
}
