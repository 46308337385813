import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import factory from 'highcharts/modules/map';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-weeklyreviewscampinsdialog',
  templateUrl: './weeklyreviewscampinsdialog.component.html',
  styleUrls: ['./weeklyreviewscampinsdialog.component.css'],
})
export class WeeklyreviewscampinsdialogComponent implements OnInit {
  selectType: any = true;
  selectTypeMerchant: any = false;
  selectTypeMerchantSMS: any;
  selectTypeMerchantSMSNOW: any;
  success: any;
  selectTypeVisitorSMS: any;
  selectTypeVisitorSMSNOW: any;
  successVisitor: any;
  befLOc: any;
  aftLOc: any;
  location: any;
  id: any;
  num: any;
  totalReviews: number;
  loading: boolean;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: AdminService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.befLOc = this.data.befLOc;
    this.location = this.data.location;
    this.aftLOc = this.data.aftLOc;
    this.totalReviews = this.aftLOc - this.befLOc;
    this.id = this.data.id;
    this.num = this.data.num;
  }

  merchant() {
    this.selectTypeMerchant = true;
    this.selectType = false;
  }
  sms() {
    this.selectTypeMerchant = false;
    this.selectTypeMerchantSMS = true;
  }
  sendSms() {
    this.selectTypeMerchantSMSNOW = true;
    this.selectTypeMerchantSMS = false;
  }
  sendnow() {
    this.loading = true;

    let obj = {
      compaginType: 'Sms',
      beforeReview: this.befLOc,
      afterReview: this.aftLOc,
      locationName: this.location,
      mobileNumber: this.num,
      deviceId: this.id,
    };
    this.service.campignReviewsGrowth(obj).subscribe((res) => {
      this.success = true;
      this.selectTypeMerchantSMSNOW = false;
      this.loading = false;
    });
  }
  visitors() {
    this.selectTypeVisitorSMS = true;
    this.selectType = false;
  }
  sendSmsVisitor() {
    this.selectTypeVisitorSMS = false;
    this.selectTypeVisitorSMSNOW = true;
  }
  sendnowVisitor() {
    this.successVisitor = true;
    this.selectTypeVisitorSMSNOW = false;
  }
  back() {
    this.selectType = true;
    this.success = false;
  }
  backVisitor() {
    this.selectType = true;
    this.successVisitor = false;
  }
  close() {
    this.dialog.closeAll();
  }
}
