<app-loader *ngIf="loader"></app-loader>


<div class="sidenavbar-heade" *ngIf="!loader">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Free trails</h1>
        </div>
       
     </div>
      <!-- <div class="d-flex justify-content-end mr-md-5 mb-3">
      <p class="mt-2 mr-2 fliter">fliterBy</p>
 <div>
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of fliterBy" [selected]="item == 'All'" value="{{item}}">{{item}}</option>
            </select>
        </div>
        <div class="ml-4">
   <mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date">
    <input matEndDate placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
</div>
     </div> -->
     
           
      <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
        </div>
     </div>

 <!-- <div class="d-flex justify-content-end mr-md-5 mb-3">
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of monthsArray" [selected]="item == this.currentMonth" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
 
        <div class="chart-customer">
            
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsfreetrailCancel" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
       <div class="d-flex search_view">
                <div class="mr-auto">
                <input (keyup)="applyFilter($event)" placeholder="Search a locationName" >

                </div>
                <div>
                <img src="../../../assets/images/search.png">

                </div>
            </div>
     <div class="table-data">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> Loc_id </th>
    <td mat-cell *matCellDef="let element"> 
      <p class="width">{{element.locationId}}</p>
     </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> Loc_name </th>
    <td mat-cell *matCellDef="let element">
      
      <p class="width">{{element.locationName ? element.locationName : "N/A"}}</p>
    
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> Merchant_name </th>
    <td mat-cell *matCellDef="let element">{{element?.ownerName}}</td>
  </ng-container>

 
    <!-- Symbol Column -->
    <ng-container matColumnDef="Step1">
      <th mat-header-cell *matHeaderCellDef> Onboadred_on</th>
      <td mat-cell *matCellDef="let element"> {{element?.onboardedDate | date}} </td>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="Step2">
      <th mat-header-cell *matHeaderCellDef> Activated_on</th>
      <td mat-cell *matCellDef="let element"> {{element?.Date | date}} </td>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="Step3">
      <th mat-header-cell *matHeaderCellDef> FT_status</th>
      <td mat-cell *matCellDef="let element">{{element.FreeTrailStatus ? element.FreeTrailStatus : 'N/A'}} </td>
    </ng-container>
    <ng-container matColumnDef="Step4">
      <th mat-header-cell *matHeaderCellDef> Reminder_SMS?</th>
      <td mat-cell *matCellDef="let element">{{element.sms ? element.sms :'N/A'}} </td>
    </ng-container>
    <ng-container matColumnDef="Step5">
      <th mat-header-cell *matHeaderCellDef> clicks</th>
      <td mat-cell *matCellDef="let element">{{element.sms ? element.sms :'N/A'}} </td>
    </ng-container>
    <ng-container matColumnDef="Step6">
      <th mat-header-cell *matHeaderCellDef> Reminder_Push?</th>
      <td mat-cell *matCellDef="let element">{{element.sms ? element.sms :'N/A'}} </td>
    </ng-container>
    <ng-container matColumnDef="Step7">
      <th mat-header-cell *matHeaderCellDef> clicks</th>
      <td mat-cell *matCellDef="let element"> {{element.sms ? element.sms :'N/A'}}</td>
    </ng-container>
    <ng-container matColumnDef="Step8">
      <th mat-header-cell *matHeaderCellDef> Loc_category</th>
      <td mat-cell *matCellDef="let element">{{element.category}} </td>
        </ng-container> 

    <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> action </th>
    <td mat-cell *matCellDef="let element"> 
        
     <div class=" button_M cursor">
        <button (click)="campign(element.FreeTrailStatus,element.daysLeft,element.locationName,element.merchantDeviceId,element.ownerphone)">Campign</button>  
        
        </div>
    </td>
  </ng-container> 
  

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
  <!-- <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
 -->
     </div>
     <!-- <div class="charts_section_start">
        <div class="free-trail_chart mx-3">
            <div class="d-flex">
                <div class="trail_header mr-auto">
                    <h5>Growth of free trails</h5>
                </div>
                <div class="month_trail">
                   <ul>
                    <li><img src="../../../assets/images/bar-chart.png"></li>
                    <li class="ml-3">Every week 3000 new free trails initiated</li>
                   </ul>
                </div>
            </div>
            <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="freetrailChart" 
             style="display:block"></highcharts-chart>
        </div>
        <div class="free-trail_chart mx-3 mt-md-5">
            <div class="d-flex">
                <div class="trail_header mr-auto">
                    <h5>Free trails converted to subscriptinos</h5>
                </div>
                <div class="month_trail">
                   <ul>
                    <li><img src="../../../assets/images/bar-chart.png"></li>
                    <li class="ml-3">Every week 3000 new free trails initiated</li>
                   </ul>
                </div>
            </div>
            <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="freetrailChartSubscription" 
             style="display:block"></highcharts-chart>
        </div>
        <div class="free-trail_chart mx-3 mt-md-5">
            <div class="d-flex">
                <div class="trail_header mr-auto">
                    <h5>Free trails converted to subscriptinos</h5>
                </div>
                 
            </div>
            <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="freetrailChartConversion" 
             style="display:block"></highcharts-chart>
        </div>
      <div class="free-trail_chart mx-3 mt-md-5">
            <div class="d-flex">
                <div class="trail_header mr-auto">
                    <h5>Free trails cancellations</h5>
                </div>
                <div class="month_trail">
                   <ul>
                    <li><img src="../../../assets/images/bar-chart.png"></li>
                    <li class="ml-3">Every week 3000 new free trails initiated</li>
                   </ul>
                </div>
            </div>
            <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="freetrailChartSubscriptionAnalyticsChart" 
             style="display:block"></highcharts-chart>
        </div>
            <div class="free-trail_chart mx-3 mt-md-5">
            <div class="d-flex">
                <div class="trail_header mr-auto">
                    <h5>Cancellations Analysis</h5>
                    <p>When do they cancel free trail. After how many days of free trail do they cancel</p>
                </div>
                 
            </div>
            <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="freetrailChartCancellationsAnalysis" 
             style="display:block"></highcharts-chart>
        </div>
            <div class="free-trail_chart mx-3 mt-md-5">
            <div class="d-flex">
                <div class="trail_header mr-auto">
                    <h5>Cancellations Reasons</h5>
                    <p>When do they cancel free trail. After how many days of free trail do they cancel</p>
                </div>
                 
            </div>
            <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="freetrailChartCancellationsReasons" 
             style="display:block"></highcharts-chart>
        </div>
          <div class="free-trail_chart mx-3 mt-md-5">
            <div class="d-flex">
                <div class="trail_header mr-auto">
                    <h5>Free trails expired. Idle accounts</h5>
                </div>
                <div class="month_trail">
                   <ul>
                    <li><img src="../../../assets/images/bar-chart.png"></li>
                    <li class="ml-3">Every week 500 locations cancelling free trails</li>
                   </ul>
                </div>
            </div>
            <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="freetrailChartFreeTrailsExpiredIdleAccounts" 
             style="display:block"></highcharts-chart>
        </div>
     </div> -->
     </div>


     </div>