import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-freetrailcampinsdialog',
  templateUrl: './freetrailcampinsdialog.component.html',
  styleUrls: ['./freetrailcampinsdialog.component.css'],
})
export class FreetrailcampinsdialogComponent implements OnInit {
  selectType: any = true;
  selectTypeMerchant: any = false;
  selectTypeMerchantSMS: any;
  selectTypeMerchantSMSNOW: any;
  success: any;
  selectTypeVisitorSMS: any;
  selectTypeVisitorSMSNOW: any;
  successVisitor: any;
  ftStatus: any;
  location: any;
  Days: any;
  totalReviews: number;
  id: any;
  num: any;
  loading: boolean;
  type: string;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: AdminService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.ftStatus = this.data.ftStatus;
    this.location = this.data.location;
    this.Days = this.data.Days;
    this.id = this.data.id;
    this.num = this.data.num;
  }
  merchant() {
    this.selectTypeMerchant = true;
    this.selectType = false;
  }
  sms() {
    this.selectTypeMerchant = false;
    this.selectTypeMerchantSMS = true;
  }
  sendSms() {
    this.selectTypeMerchantSMSNOW = true;
    this.selectTypeMerchantSMS = false;
  }
  sendnow(text: any) {
    this.loading = true;
    if (text == 'sms') {
      this.type = 'Sms';
    } else {
      this.type = 'Push';
    }
    let obj = {
      compaginType: this.type,
      ft_status: this.ftStatus,
      expired: this.Days,
      locationName: this.location,
      mobileNumber: this.num,
      // deviceId:this.deviceId
      deviceId: this.id,
    };
    this.service.campignFreeTrail(obj).subscribe((res) => {
      if (text == 'sms') {
        this.success = true;
        this.selectTypeMerchantSMSNOW = false;
      } else {
        this.successVisitor = true;
        this.selectTypeVisitorSMSNOW = false;
      }

      this.loading = false;
    });
  }
  visitors() {
    this.selectTypeVisitorSMS = true;
    this.selectType = false;
    this.selectTypeMerchant = false;
  }
  sendSmsVisitor() {
    this.selectTypeVisitorSMS = false;
    this.selectTypeVisitorSMSNOW = true;
  }
  sendnowVisitor() {
    this.successVisitor = true;
    this.selectTypeVisitorSMSNOW = false;
  }
  back() {
    this.selectType = true;
    this.success = false;
  }
  backVisitor() {
    this.selectType = true;
    this.successVisitor = false;
  }
  close() {
    this.dialog.closeAll();
  }
}
