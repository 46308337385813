import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FileHandle } from 'src/app/_modal/fileHandle';
import { Issue } from 'src/app/_modal/issues';
import { AdminService } from 'src/app/_services/admin.service';
@Component({
  selector: 'app-reply-dialog',
  templateUrl: './reply-dialog.component.html',
  styleUrls: ['./reply-dialog.component.css'],
})
export class ReplyDialogComponent implements OnInit {
  errorIssue: boolean = true;
  replyIssue: boolean = false;
  loader: boolean = false;
  url: any;
  selectedFile: File;
  fileName: any;
  queryParamsDetails: any;
  userDetailsParams: any;
  replyIssueForm: FormGroup;
  issue: Issue = {
    issueImages: [],
  };
  issueDialog: any = [];
  submitted: boolean = false;
  success: boolean = false;
  markAsInvalidStatus: boolean = false;
  statusInvalid: any = 'invalid';
  pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  onLoadPage = new EventEmitter();
  queryParamsStatus: any;

  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  queryParams: {};
  img: any;
  @ViewChild('slider1') slider1: any;
  // permissionsArray: any;
  // permissons: any;

  constructor(
    private diloag: MatDialog,
    private sanitizer: DomSanitizer,
    private router: Router,
    private services: AdminService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private render: Renderer2,
    private elem: ElementRef,
    public dialogRef: MatDialogRef<ReplyDialogComponent>
  ) {
    this.replyIssueForm = this.fb.group({
      text: ['', [Validators.required]],
      file: [''],
    });
  }

  ngOnInit(): void {
    this.loader = true;
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.userDetailsParams = params.get('_id');
    });
    // this.permissons = localStorage.getItem('permissons');

    // this.permissionsArray = JSON.parse(this.permissons);
    this.services
      .supportRequestDetails(this.userDetailsParams)
      .subscribe((response) => {
        this.issueDialog.push(response);
        this.loader = false;

        // console.log(response)
      });
    this.img = document.getElementById('img1');
  }

  reloadComponent() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  // Function to increase image size
  public getExtestionType(value: string): string {
    const imageFileType = ['png', 'jpeg', 'jpg', 'gif'];
    const videoFileType = ['mp4', 'mp3'];
    const otherFileType = ['doc', 'docx', 'pdf'];
    const fileTypeLastIndex = value?.lastIndexOf('.');
    const fileType = value?.substring(fileTypeLastIndex + 1);
    if (imageFileType.includes(fileType)) {
      return 'image';
    } else if (videoFileType.includes(fileType)) {
      return 'video';
    } else if (otherFileType.includes(fileType)) {
      return 'other';
    }
    return '';
  }
  enlargeImg(value: any) {
    if (value) {
      console.log('value', value);
      const fileNameStartIndex = value.indexOf('attachment');
      const fileTypeLastIndex = value.lastIndexOf('.');
      const fileName = value.substring(fileNameStartIndex);
      const fileType = value.substring(fileTypeLastIndex + 1);
      console.log(fileName, fileType);

      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      a.download = 'test.pdf';

      a.href = value;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    // this.render.setStyle(this.slider1.nativeElement, 'transform', 'scale(9.5)');
    // // this.render.setStyle(this.close1.nativeElement, 'opacity', '1');
    // this.render.setStyle(
    //   this.slider1.nativeElement,
    //   'transition',
    //   'transform 0.25s ease'
    // );
  }

  close() {
    console.log('closed');
    this.router.navigate([], {
      queryParams: {
        yourParamName: null,
        youCanRemoveMultiple: null,
      },
      queryParamsHandling: 'merge',
    });
    this.diloag.closeAll();
  }
  clearParams() {
    // Clear query parameters by navigating to the current route with an empty query parameter object
    this.router.navigate([], {
      queryParams: {},
      queryParamsHandling: 'merge', // Merge ensures that other existing queryParams are not removed
      relativeTo: this.activatedRoute, // If you are using a child route, provide the ActivatedRoute
    });
  }
  reply() {
    this.replyIssue = true;
    this.errorIssue = false;
  }
  replyAfterIssues() {
    if (this.replyIssueForm.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;
      // const formData = new FormData();
      // if (this.url != null) {
      //   formData.append('file', this.selectedFile, this.fileName);
      // } else {
      //   formData.append('file', null);
      // }
      // formData.append('text', this.replyIssueForm.value.text);
      let obj = {
        reply: this.replyIssueForm.value.text,
      };
      this.services
        .supportChangeStatus(this.userDetailsParams, obj)
        .subscribe((response) => {
          this.issueDialog = response.message;
          this.success = true;
          this.replyIssue = false;
          this.errorIssue = false;
        });
    }
  }
  markAsInvalid() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      if (params.get('_id') != null) {
        this.userDetailsParams = params.get('_id');
      }
    });
    this.queryParamsStatus = {
      status: this.statusInvalid ? this.statusInvalid : '',
    };
    this.services
      .supportUpdate(this.userDetailsParams, this.queryParamsStatus)
      .subscribe((response) => {
        this.markAsInvalidStatus = true;
        this.success = false;
        this.replyIssue = false;
        this.errorIssue = false;
        this.onLoadPage.emit();
        // this.dialogRef.close('refresh');
      });
  }
  getSupportRequest() {}
  get updatecmsEvent(): { [key: string]: AbstractControl } {
    return this.replyIssueForm.controls;
  }
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    // this.selectedFile = <File>event.target.files[0];
    // this.fileName = event.target.files[0].name;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result as string;
      console.log(this.url);

      this.selectedFile = <File>event.target.files[0];
      this.fileName = event.target.files[0].name;

      // const fileHandle: FileHandle = {
      //   file: file,
      //   url: this.sanitizer.bypassSecurityTrustUrl(
      //     window.URL.createObjectURL(file)
      //   ),
      // };
      // this.issue.issueImages.push(fileHandle);
    };
  }
  resetFileInput() {
    const Elem: any = document.getElementById('fileInput');
    Elem.value = null;
    Elem.type = 'text';
    Elem.type = 'file';
  }
}
