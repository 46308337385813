import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
import { FreetrailcampinsdialogComponent } from './freetrailcampinsdialog/freetrailcampinsdialog.component';

@Component({
  selector: 'app-freetrails',
  templateUrl: './freetrails.component.html',
  styleUrls: ['./freetrails.component.css'],
})
export class FreetrailsComponent implements OnInit {
  Language: any = ['This month', 'This week', 'Today'];
  items: any = [];
  highcharts = Highcharts;
  freetrailChart: any;
  freetrailChartSubscription: any;
  freetrailChartConversion: any;
  freetrailChartSubscriptionAnalyticsChart: any;
  freetrailChartCancellationsAnalysis: any;
  freetrailChartFreeTrailsExpiredIdleAccounts: any;
  freetrailChartCancellationsReasons: any;
  userQuery: any;
  pageSize: any = 10;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalFreeTrailsWeeklyCounts: any;
  currentMonth: any;
  freeTrailGrowth: any = [];
  convertedToSubscriptionWeeklyCount: any = [];
  month: any;
  loader: boolean = false;
  monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  freeTrailCancellationWeeklyCounts: any;
  freeTrailCancellationWeeklyCount: any = [];
  freeTrailExpiredWeeklyCounts: any;
  freeTrailExpiredWeeklyCount: any = [];
  cancellationReasonCounts: any;
  cancellationReasonCount: any = [];
  cancellationReason: any = [];
  ELEMENT_DATA: Element[] = [];
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Step1',
    'Step2',
    'Step3',
    'Step4',
    'Step5',
    'Step6',
    'Step7',
    'Step8',
    'action',
  ];

  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  freetrailResponseCountView: any = [];
  freetrailResponse: any = [];
  chartOptionsfreetrailCancel: any;
  loading: boolean;
  totalSummary: any;
  fliterBy: any[] = ['By Location', 'By City', 'By Loc_category', 'All'];

  constructor(private service: AdminService, private dialog: MatDialog) {}

  ngOnInit(): void {
    // this.dialog.open(FreetrailcampinsdialogComponent, {});

    const currentDate = new Date();
    const monthIndex = currentDate.getMonth(); // This gets the month index (0-11)
    const monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.loader = true;
    const currentMonth = monthsArray[monthIndex]; // Get the month name using the index
    this.currentMonth = currentMonth;
    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.freeTrail(this.userQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.dashBoardData;
      this.totalSummary = result.totalOnBoarded;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      result.freetrailResponse.forEach((response: any) => {
        this.freetrailResponseCountView.push(response.value);
        this.freetrailResponse.push(response.key);
        // console.log(this.ratingCountView);
      });
      this.items = [
        { name: 'Locations', number: result.locations },
        { name: 'Actived', number: result.activated },
        {
          name: 'Not Activated',
          number: result.notActivated,
        },
        { name: 'Expired', number: result.ExpiredFreeTrails },
        // { name: 'Subscribed', number: result.subscribed },
      ];
      this.loader = false;
    });
    this.chartOptionsfreetrailCancel = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'freetrail trials',
      },
      credits: {
        enabled: true,
      },

      xAxis: {
        categories: this.freetrailResponse,
      },
      yAxis: {
        // min: 0,
        title: {
          text: 'freetrail trials',
        },
        // categories: ['0', '54', '70', '80', '140', '180', '200', '240'],
      },
      legend: {
        reversed: true,
      },
      colors: ['#dbb0e8a1'],
      series: [
        {
          // name: this.freetrailResponse,
          data: this.freetrailResponseCountView,
        },
      ],
    };
    // this.freetrailChartBtn();
    // this.freetrailChartConversionBtn();
    // // this.freetrailChartSubscriptionAnalytics();
    // this.freetrailChartCancellationsAnalysisBtn();
    // this.freetrailChartFreeTrailsExpiredIdleAccountsBtn();
    // this.freetrailChartCancellationsReasonsBtn();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
      locationName: filterValue,
    };

    this.service.freeTrail(this.userQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.dashBoardData;
      this.totalSummary = result.totalOnBoarded;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      // result.freetrailResponse.forEach((response: any) => {
      //   this.freetrailResponseCountView.push(response.value);
      //   this.freetrailResponse.push(response.key);
      //   // console.log(this.ratingCountView);
      // });
      this.items = [
        { name: 'Locations', number: result.locations },
        { name: 'Actived', number: result.activated },
        {
          name: 'Not Activated',
          number: result.notActivated,
        },
        { name: 'Expired', number: result.ExpiredFreeTrails },
        // { name: 'Subscribed', number: result.subscribed },
      ];
      this.loader = false;
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.loading = true;

    // this.currentPage = event.pageIndex + 1;
    let newCurrentPage = event.pageIndex + 1;
    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage,
    };

    this.service.freeTrail(this.userQuery).subscribe((result) => {
      console.log(result);
      this.ELEMENT_DATA = result.dashBoardData;
      this.totalSummary = result.totalOnBoarded;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      result.freetrailResponse.forEach((response: any) => {
        this.freetrailResponseCountView.push(response.value);
        this.freetrailResponse.push(response.key);
        // console.log(this.ratingCountView);
      });
      this.items = [
        { name: 'Locations', number: result.locations },
        { name: 'Actived', number: result.activated },
        {
          name: 'Not Activated',
          number: result.notActivated,
        },
        { name: 'Expired', number: result.ExpiredFreeTrails },
        { name: 'Subscribed', number: result.subscribed },
      ];
      this.loader = false;
    });
  }
  campign(clicks: any, reqSent: any, location: any, id: any, num: any) {
    this.dialog.open(FreetrailcampinsdialogComponent, {
      data: {
        ftStatus: clicks,
        Days: reqSent,
        location: location,
        id: id,
        num: num,
      },
    });
  }
  freetrailChartBtn() {
    this.userQuery = {
      month: this.currentMonth,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.freeTrail(this.userQuery).subscribe((result) => {
      console.log(result);

      //freetrailsgrowth-chart-start
      this.totalFreeTrailsWeeklyCounts =
        result.weeklyCounts.totalFreeTrailsWeeklyCounts.forEach((res: any) => {
          console.log(res);
          this.freeTrailGrowth.push(res.count);
          console.log(this.freeTrailGrowth);

          this.freetrailChart = {
            chart: {
              type: 'spline',
            },
            title: {
              text: 'Free trails growth',
            },
            credits: {
              enabled: true,
            },

            xAxis: {
              categories: ['week 1', 'week 2', 'week 3', 'week 4'],
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Goals',
              },
              gridLineWidth: 0,
            },
            legend: {
              reversed: true,
            },
            colors: ['#160FE2', '#076611ab'],
            series: [
              {
                name: 'Free trails growth',
                data: this.freeTrailGrowth,
              },
            ],
          };
        });
      //freetrailsgrowth-chart-end
      //freetrails-subscription-chart-start

      this.totalFreeTrailsWeeklyCounts =
        result.weeklyCounts.convertedToSubscriptionWeeklyCount.forEach(
          (res: any) => {
            console.log(res);
            this.convertedToSubscriptionWeeklyCount.push(res.count);
            console.log(this.convertedToSubscriptionWeeklyCount);
          }
        );
      this.freetrailChartSubscription = {
        chart: {
          type: 'spline',
        },
        title: {
          text: 'Free trails growth',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
          gridLineWidth: 0,
        },
        legend: {
          reversed: true,
        },
        colors: ['#076611ab'],
        series: [
          {
            name: 'Free trails growth',
            data: this.convertedToSubscriptionWeeklyCount,
          },
        ],
      };
      //freetrails-subscription-chart-end
      //freetrails-canacelltion-chart-end
      this.freeTrailCancellationWeeklyCounts =
        result.weeklyCounts.freeTrailCancellationWeeklyCount.forEach(
          (res: any) => {
            console.log(res);
            this.freeTrailCancellationWeeklyCount.push(res.count);
            console.log(this.freeTrailCancellationWeeklyCount);
          }
        );

      this.freetrailChartSubscriptionAnalyticsChart = {
        chart: {
          type: 'spline',
        },
        title: {
          text: 'Cancellations',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
        },
        legend: {
          reversed: true,
        },
        colors: ['#F0490B'],
        series: [
          {
            name: 'Cancellations',
            data: this.freeTrailCancellationWeeklyCount,
          },
        ],
      };
      //freetrails-canacelltion-chart-end
      //freetrails-exipired-chart-end
      this.freeTrailExpiredWeeklyCounts =
        result.weeklyCounts.freeTrailExpiredWeeklyCount.forEach((res: any) => {
          console.log(res);
          this.freeTrailExpiredWeeklyCount.push(res.count);
          console.log(this.freeTrailCancellationWeeklyCount);
        });

      this.freetrailChartFreeTrailsExpiredIdleAccounts = {
        chart: {
          type: 'spline',
        },
        title: {
          text: 'Free trails growth',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
          gridLineWidth: 0,
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            pointPadding: 0, // Adjust these values
            groupPadding: 0.1, // to control the bar height
            borderWidth: 0, // Optional: remove border to visually increase height
          },
        },
        colors: ['#101010'],
        series: [
          {
            name: 'Trails expired',
            data: this.freeTrailExpiredWeeklyCount,
          },
        ],
      };
      //freetrails-exipired-chart-end
      //freetrails-cancelltions-chart-end
      this.cancellationReasonCounts = result.cancellationReasonCounts.forEach(
        (res: any) => {
          console.log(res);
          this.cancellationReasonCount.push(res.count);
          this.cancellationReason.push(res.cancellation_reason);
          console.log(this.cancellationReasonCounts);
        }
      );

      this.freetrailChartCancellationsReasons = {
        chart: {
          type: 'bar',
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: 'categories',
          categories: this.cancellationReason,
        },
        yAxis: {
          title: {
            text: 'Cancellations Reasons',
          },
        },
        legend: {
          enabled: false,
          reversed: true,
        },
        plotOptions: {
          series: {
            pointPadding: 0, // Adjust these values
            groupPadding: 0.1, // to control the bar height
            borderWidth: 0, // Optional: remove border to visually increase height
            dataLabels: {
              enabled: true,
              format: '{point.y:.1f}%',
            },
          },
        },
        colors: ['#068466'],

        tooltip: {
          headerFormat:
            '<span style="font-size:11px">{Cancellations Reasons}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point}</span>: <b>{point.y:.2f}%</b> of total<br/>',
        },

        series: [
          {
            name: this.cancellationReason,
            data: this.cancellationReasonCount,
            // data: [
            //   {
            //     name: 'Same day',
            //     y: 20,
            //     drilldown: 'Same day',
            //   },
            //   {
            //     name: 'within two days',
            //     y: 70,
            //     drilldown: 'within two days',
            //   },
            //   {
            //     name: 'within five days',
            //     y: 40,
            //     drilldown: 'within five days',
            //   },
            //   {
            //     name: 'within 12 days',
            //     y: 37,
            //     drilldown: 'within 12 days',
            //   },
            //   {
            //     name: 'During last day',
            //     y: 76,
            //     drilldown: 'During last day',
            //   },
            // ],
          },
        ],
      };
      //freetrails-cancelltions-chart-end
    });
  }
  freetrailChartSubscriptionBtn() {}
  langugeChange(e: any) {
    this.month = e.target.value;
  }
  freetrailChartFreeTrailsExpiredIdleAccountsBtn() {}
  freetrailChartSubscriptionAnalytics() {}
  freetrailChartConversionBtn() {
    this.freetrailChartConversion = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'categories',
        categories: [
          'same day',
          'within two days',
          'within five days',
          'within 12 days',
          'During last day',
        ],
      },
      yAxis: {
        title: {
          text: 'Total percent market share',
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.2, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',
          },
        },
      },
      colors: ['#068466'],

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      },

      series: [
        {
          data: [
            {
              name: 'Same day',
              y: 63.06,
              drilldown: 'Same day',
            },
            {
              name: 'within two days',
              y: 19.84,
              drilldown: 'within two days',
            },
            {
              name: 'within five days',
              y: 4.18,
              drilldown: 'within five days',
            },
            {
              name: 'within 12 days',
              y: 4.12,
              drilldown: 'within 12 days',
            },
            {
              name: 'During last day',
              y: 2.33,
              drilldown: 'During last day',
            },
          ],
        },
      ],
    };
  }
  freetrailChartCancellationsAnalysisBtn() {
    this.freetrailChartCancellationsAnalysis = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'categories',
        categories: [
          'same day',
          'within two days',
          'within five days',
          'within 12 days',
          'During last day',
        ],
      },
      yAxis: {
        title: {
          text: 'Total percent market share',
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.1, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',
          },
        },
      },
      colors: ['#068466'],

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      },

      series: [
        {
          data: [
            {
              name: 'Same day',
              y: 63.06,
              drilldown: 'Same day',
            },
            {
              name: 'within two days',
              y: 19.84,
              drilldown: 'within two days',
            },
            {
              name: 'within five days',
              y: 4.18,
              drilldown: 'within five days',
            },
            {
              name: 'within 12 days',
              y: 4.12,
              drilldown: 'within 12 days',
            },
            {
              name: 'During last day',
              y: 2.33,
              drilldown: 'During last day',
            },
          ],
        },
      ],
    };
  }
  freetrailChartCancellationsReasonsBtn() {}
}
