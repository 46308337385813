

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Merchants</h1>
        </div>
            <app-loader *ngIf="loader"></app-loader>

       <!-- <div class="d-flex   language-header">
       
            <select   class="selectpicker" >
                <option  *ngFor="let item of Language" [selected]="item == 'This month'" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
     </div>

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
        </div>
     </div>
       <!-- <h3 class="heading-1">Filter Customers by Business Categories</h3>
              <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsBarRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
        <h3 class="heading-1">Filter Visits by Business Categories</h3>
              <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsBarRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div> -->
     <!-- <div class="d-flex justify-content-end mr-md-5 mb-3">
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of monthsArray" [selected]="item == this.currentMonth" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
        <!-- <div><h2>Merchant Subscriptions</h2>
             </div> -->

         <div class="table-data mb-5 pb-3 mt-4">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> M_Id </th>
    <td mat-cell *matCellDef="let element">
      <p class="Id"> {{element._id}}</p>  </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> Phone </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element?.mobileNumber}}</p></td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element?.email}}</p></td>
  </ng-container>
 <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> Location </th>
    <td mat-cell *matCellDef="let element"> 
        <p class="Id">
{{element?.locations[0]?.formatted_address}}
        </p> </td>
  </ng-container>


 <ng-container matColumnDef="freetrail1">
    <th mat-header-cell *matHeaderCellDef> Location Name </th>
    <td mat-cell *matCellDef="let element"> 
        <p class="Id">
{{element?.locations[0]?.name}}
        </p> </td>
  </ng-container>
  <!-- Weight Column -->
  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> Merchant Name </th>
    <td mat-cell *matCellDef="let element"><p class="Id">{{element?.Merchant_name}}</p></td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> Designation </th>
    <td mat-cell *matCellDef="let element"> {{element?.designation}} </td>
  </ng-container>
  <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> createdAt </th>
    <td mat-cell *matCellDef="let element"> {{element?.createdAt | date}} </td>
  </ng-container>
 
  <!-- <ng-container matColumnDef="subscription">
    <th mat-header-cell *matHeaderCellDef> status </th>
    <td mat-cell *matCellDef="let element"> {{element?.statusDetails?.status}} </td>
  </ng-container> -->
  <!-- <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Invited </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> invite_clicks </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="leads">
    <th mat-header-cell *matHeaderCellDef> Leads </th>
    <td mat-cell *matCellDef="let element"> {{element.totalLeads}} </td>
  </ng-container>
  <ng-container matColumnDef="firstvisits">
    <th mat-header-cell *matHeaderCellDef> first_visits </th>
    <td mat-cell *matCellDef="let element"> {{element.firstVisit}} </td>
  </ng-container>
  <ng-container matColumnDef="conversion">
    <th mat-header-cell *matHeaderCellDef> Conversion </th>
    <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
  </ng-container>
  <ng-container matColumnDef="Revenue">
    <th mat-header-cell *matHeaderCellDef> Revenue </th>
    <td mat-cell *matCellDef="let element"> {{element.revenue}} </td>
  </ng-container> -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">view </div></td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> 
  <!-- <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
-->
     </div>
 
      </div>
      </div>