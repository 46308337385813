<div class="">
    <app-loader *ngIf="loader"></app-loader>

<div class="d-flex">
 <h4>Reviews</h4>
        <img src="../../../../assets/images/close2.svg" class="close" (click)="close()">
</div>      
        <h6 *ngIf="this.error != null">No reviews found for this location</h6>

    <div class="reviews">
        <div class="card" *ngFor="let v of reviews">
            <div class="card-body">
                <div class="d-flex">
                    <img src="../../../../assets/images/user-1.png" width="30px">
                    <p>{{v.reviewed_by}}</p>
                </div>
                <div class="mt-2 mb-2">
<!-- <span class="fa fa-star " [class.checked]="v.rating == 1 || v.rating == 2 || v.rating == 3"></span>
<span class="fa fa-star" [class.checked]="v.rating == 2 || v.rating == 3"></span>
<span class="fa fa-star" [class.checked]=" v.rating == 1 || v.rating == 2 || v.rating == 3"></span>
<span class="fa fa-star"[class.checked]="  v.rating == 1 || v.rating == 2 || v.rating == 3  ||v.rating == 4"></span>
<span class="fa fa-star"[class.checked]="v.rating == 5"></span> -->
   <star-rating [starType]="'svg'" [rating]="v.stars"  size="small" space="no"
             ></star-rating>
                </div>
                <div class="rating-dec">
                    <p>{{v.review}}</p>
                </div>
            </div>
        </div>
    </div>
</div>