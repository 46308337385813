
<div class="main">
    <div class="selcect_btn" *ngIf="selectType">
      <div class="d-flex">
        <button  class="cursor button_M" (click)="merchant()">Merchants/ Staff</button>
        <!-- <button class="cursor button_M">Visitors</button> -->
      </div>
    </div>
<app-loader *ngIf="loading"></app-loader>


    <div>
 <div class="selcect_btn merchant" *ngIf="selectTypeMerchant">
        <p class="ml-2">Select the campaign type</p>
      <div class="d-flex ">
        <button  class="cursor button_Merchant"  (click)="visitors()">Push</button>
        <button class="cursor button_Merchant" (click)="sms()">SMS</button>
      </div>
      <div class="d-flex">
        <button  class="cursor button_Merchant">Email</button>
        <button class="cursor button_Merchant">Whatsapp</button>
      </div>
    </div>
    <div class="selcect_btn merchant" *ngIf="selectTypeMerchantSMS">
        <p class="ml-2">Send SMS to merchant/Staff</p>
       <div>
       <h3 class="select_label">FT_Status</h3>
       
            <select  class="selectpicker" >
              <option value="">{{ftStatus}}</option>
                <!-- <option>FT_Status</option> -->
            </select>
        </div>
       <div>
       <h3 class="select_label">Expired</h3>
       
            <select  class="selectpicker" >
              <option value="">{{Days}}</option>
                <!-- <option>Expired</option> -->
            </select>


            <h6>We found your FT status has {{ftStatus}} as of {{Days}} days ago</h6>

        <button  class="cursor button_M send_sms" (click)="sendSms()">Send SMS</button>

        </div>
    </div>
    <div class="selcect_btn merchant" *ngIf="selectTypeMerchantSMSNOW">
        <p class="ml-2">Send SMS to merchant/Staff</p>
      
       
        <div class="sendnow">
            <p>Hello {{location}}</p>
            <br>
            <p>
                IMPORTANT Your Free trial has {{ftStatus}} {{Days}} days ago with LocalFirst You won’t be able to request for new Google reviews and referrals from the app Please activate subscription to continue </p>
                 <br>
                 <p class="m-0">Powered by</p>
                 <p>LocalFirst</p>
        </div>
        <button  class="cursor button_M send_sms" (click)="sendnow('sms')">Send Now</button>

    </div>
    <div class="selcect_btn merchant" *ngIf="success">
        <h5 class="ml-2">Successfully Send SMS to merchant/Staff</h5>
      
       
        <a (click)="back()" class="cursor">Go back</a>

    </div>
    </div>
    <div>
 
    <div class="selcect_btn merchant" *ngIf="selectTypeVisitorSMS">
              <p class="ml-2">Send SMS to merchant/Staff</p>
         <div>
       <h3 class="select_label">FT_Status</h3>
       
            <select  class="selectpicker" >
              <option value="">{{ftStatus}}</option>
                <!-- <option>FT_Status</option> -->
            </select>
        </div>
       <div>
       <h3 class="select_label">Expired</h3>
       
            <select  class="selectpicker" >
              <option value="">{{Days}}</option>
                <!-- <option>Expired</option> -->
            </select>

            <h6>We found your FT status has {{ftStatus}} as of {{Days}} days ago</h6>
          

        <button  class="cursor button_M send_sms" (click)="sendSmsVisitor()">Send SMS</button>

        </div>
    </div>
    <div class="selcect_btn merchant" *ngIf="selectTypeVisitorSMSNOW">
     <p class="ml-2">Send SMS to merchant/Staff</p>
      
          
        <div class="sendnow">
            <p>Hello {{location}}</p>
            <br>
            <p>
                IMPORTANT:
                <br> Your Free trial has {{ftStatus}} {{Days}} days ago with LocalFirst You won’t be able to request for new Google reviews and referrals from the app Please activate subscription to continue </p>
                 <br>
                 <p class="m-0">Powered by</p>
                 <p>LocalFirst</p>
        </div>
        <button  class="cursor button_M send_sms" (click)="sendnow('Push')">Send Now</button>

    </div>
    <div class="selcect_btn merchant" *ngIf="successVisitor">
              <h5 class="ml-2">Successfully Send SMS to merchant/Staff</h5>

      
       
        <a (click)="backVisitor()" class="cursor">Go back</a>

    </div>
    </div>
   

    <a (click)="close()" class="cursor close">Close</a>
</div>