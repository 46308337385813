import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
import { TrackingDialogComponent } from './tracking-dialog/tracking-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
export interface PeriodicElement {
  Cid: any;
  Cname: any;
  ctype: any;
  Locations: any;
  singupdate: any;
  freetrail: any;
  subscription: any;
  status: any;
  action: any;
}
// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'Active',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'Active',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'Active',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'inactive',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'inactive',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'Active',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'HM',
//     ctype: 'shop',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'inactive',
//     status: 'Subscription Running',
//     action: 'view',
//   },
// ];
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
})
export class CustomersComponent implements OnInit {
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'Mname',
    // 'ctype',
    'Locations',
    'singupdate',
    'freetrail1',
    'freetrail',

    'action',
  ];
  Language: any = ['This month', 'This week', 'Today'];
  loader: boolean = false;
  userQuery!: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean = false;
  loading: boolean = false;
  userData: any;
  highcharts = Highcharts;
  chartOptionsRevered: any;
  chartpie1: any;
  weeklyCounts: any;
  appRegistration: any = [];
  activation: any;
  activationChart: any = [];
  subscriptionParam: any = '';
  freetrailParams: any = '';
  statusParams: any = '';
  chartOptionsBarRevered: any;
  monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  currentMonth: any;
  name: any;
  typeNameParams: any = '';
  locationParams: any = '';
  constructor(
    private service: AdminService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.openDialog();
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth(); // This gets the month index (0-11)
    const monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentMonth = monthsArray[monthIndex]; // Get the month name using the index
    this.currentMonth = currentMonth;

    console.log(currentMonth); // This will log the current month's name
    this.userApisList();
    this.splineChart();
  }

  freetrail(e: any) {
    this.freetrailParams = e.target.value;
    this.userQuery = {
      free_trail: this.freetrailParams,
      Subscription: this.subscriptionParam,
      Status: this.statusParams,
      type: this.typeNameParams,
      location: this.locationParams,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.customers;
      this.totalSummary = res.totalSubscriber;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  typeName(e: any) {
    this.typeNameParams = e.target.value;
    this.userQuery = {
      free_trail: this.freetrailParams,
      Subscription: this.subscriptionParam,
      Status: this.statusParams,
      type: this.typeNameParams,
      location: this.locationParams,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.customers;
      this.totalSummary = res.totalSubscriber;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  location(e: any) {
    this.locationParams = e.target.value;
    this.userQuery = {
      free_trail: this.freetrailParams,
      Subscription: this.subscriptionParam,
      Status: this.statusParams,
      type: this.typeNameParams,
      location: this.locationParams,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.customers;
      this.totalSummary = res.totalSubscriber;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  subscription(e: any) {
    this.subscriptionParam = e.target.value;
    this.userQuery = {
      free_trail: this.freetrailParams,
      Subscription: this.subscriptionParam,
      Status: this.statusParams,
      type: this.typeNameParams,
      location: this.locationParams,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.customers;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalSubscriber;
    });
  }
  staus(e: any) {
    this.statusParams = e.target.value;
    this.userQuery = {
      free_trail: this.freetrailParams,
      Subscription: this.subscriptionParam,
      Status: this.statusParams,
      type: this.typeNameParams,
      location: this.locationParams,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.customers;
      this.totalSummary = res.totalSubscriber;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  userApisList() {
    this.loading = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalCustomers;
      this.items = [
        { name: 'Total customers', number: res.totalCustomers },
        { name: 'Total RepeatVist', number: res.totalRepeatVisit },
        // { name: 'Cancelled Customers', number: res.cancelledCustomers },
      ];
    });
  }
  splineChart() {
    this.chartOptionsBarRevered = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      title: {
        text: 'Customers by Business Categories',
      },
      xAxis: {
        type: 'categories',
        categories: ['Food', 'Saloon', 'Beauty', 'Dental', 'Skin Care'],
      },
      yAxis: {
        title: {
          text: 'Customers by Business Categories',
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.2, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
      },
      colors: ['#98b8eb'],

      tooltip: {
        // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
      },
      series: [
        {
          // name: this.data,
          // data: this.count,
          data: [
            {
              name: 'Food',
              y: 150000,
              drilldown: 'Food',
            },
            {
              name: 'Saloon',
              y: 10000,
              drilldown: 'Saloon',
            },
            {
              name: 'Baeuty',
              y: 50000,
              drilldown: 'Baeuty',
            },
            {
              name: 'Dental',
              y: 60000,
              drilldown: 'Dental',
            },
            {
              name: 'Skin care',
              y: 20000,
              drilldown: 'Skin care',
            },
          ],
        },
      ],
    };
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.loading = true;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage,
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalCustomers;
    });
  }

  langugeChange(e: any) {
    this.language = e.target.value;
    this.appRegistration = [];
    this.activationChart = [];
    this.userQuery = {
      month: this.language,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.activation = res.weeklyCounts.activation;
      this.weeklyCounts = res.weeklyCounts.appRegistration;

      this.weeklyCounts.forEach((response: any) => {
        this.appRegistration.push(response.count);
        console.log(this.appRegistration);
      });
      this.activation.forEach((response: any) => {
        this.activationChart.push(response.count);
        console.log(this.appRegistration);
      });

      this.chartOptionsRevered = {
        chart: {
          type: 'spline',
        },
        title: {
          text: 'data',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
        },
        legend: {
          reversed: true,
        },
        colors: ['#160FE2', '#076611ab'],
        series: [
          {
            name: 'App registrations',
            data: this.appRegistration,
          },
          {
            name: 'Account actives',
            data: this.activationChart,
          },
        ],
      };
    });
  }
  toggleChanged(e: any) {
    console.log('toggl', e.target.checked);
  }
  openDialog(id: any) {
    // this.dialog.open(TrackingDialogComponent, {});
    this.router.navigate(['/Customers/tracking'], { queryParams: { id: id } });
  }
  deleteUser(id: any) {
    // this.adminService.deleteUser(id).subscribe((res) => {
    //   console.log('delete ', res);
    //   this.userDelete = true;
    //   Swal.fire({
    //     text: 'User delete succefully',
    //     showConfirmButton: true, // Hide the "OK" button
    //     icon: 'success', // You can change the icon type
    //     timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
    //   });
    //   this.userApisList();
    //   setTimeout(() => {
    //     this.userDelete = false;
    //   }, 3000);
    // });
  }

  applyFilter(event: Event) {
    this.loading = true;

    const filterValue = (event.target as HTMLInputElement).value;
    this.userQuery = {
      locationName: filterValue,
    };

    this.service.customers(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalCustomers;
    });
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
