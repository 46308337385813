import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
import { ReviewscampinsdialogComponent } from './reviewscampinsdialog/reviewscampinsdialog.component';
import { MatDialog } from '@angular/material/dialog';
export interface PeriodicElement {
  Cid: any;
  Cname: any;
  ctype: any;
  Locations: any;
  singupdate: any;
  freetrail: any;
  subscription: any;
  status: any;
  action: any;
}
// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'Active',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'Active',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'Active',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'inactive',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'inactive',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'PK Saloon',
//     ctype: 'Saloon',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'Active',
//     status: 'Subscription Running',
//     action: 'view',
//   },
//   {
//     Cid: 764958,
//     Cname: 'HM',
//     ctype: 'shop',
//     Locations: 'Hyderabad, Telangana',
//     singupdate: 'Dec 25, 2023',
//     freetrail: 'Completed',
//     subscription: 'inactive',
//     status: 'Subscription Running',
//     action: 'view',
//   },
// ];

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css'],
})
export class ReviewsComponent implements OnInit {
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Locations',
    'singupdate',
    'freetrail',
    'freetrail1',
    'freetrail2',
    'freetrail3',
    'subscription',
    'status',
    'sms',
    'category',
    'action',
  ];
  Language: any = ['This month', 'This week', 'Today'];
  loader: boolean = false;
  userQuery!: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any = 0;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean = false;
  userData: any;
  highcharts = Highcharts;
  chartOptionsRevered: any;
  chartOptionsBarRevered: any;
  weeklyCounts: any;
  appRegistration: any = [];
  ratingCounts: any;
  ratingCountView: any = [];
  subscriptionParam: any = '';
  statusParams: any = '';
  freetrailParams: any = '';
  locationParams: any = '';
  typeNameParams: any = '';
  loading: boolean = false;
  currentMonth: any;
  chartOptionsSubscriptioncancel: any;
  chartOptionsReviews: any;
  monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  fliterBy: any[] = ['By Location', 'By City', 'By Loc_category', 'All'];
  constructor(private service: AdminService, private dialog: MatDialog) {}

  ngOnInit(): void {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth(); // This gets the month index (0-11)
    const monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const currentMonth = monthsArray[monthIndex]; // Get the month name using the index
    this.currentMonth = currentMonth;
    this.userApisList();
    this.splineChart();
  }
  campign(clicks: any, reqSent: any, location: any, id: any, num: any) {
    this.dialog.open(ReviewscampinsdialogComponent, {
      data: {
        clicks: clicks,
        reqSent: reqSent,
        location: location,
        id: id,
        num: num,
      },
    });
  }
  userApisList() {
    this.loading = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
      locationName: this.currentPage ? this.currentPage : '',
    };
    this.service.reviews(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalDocuments;
      this.loading = false;

      this.ELEMENT_DATA = res.dashBoardData;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.items = [
        { name: 'Locations', number: res.cardData.distinctLocationCount },
        { name: 'Requests Sent', number: res.cardData.totalReviewRequests },
        { name: 'Clicked', number: res.cardData.clicked },
        // { name: 'Not clicked', number: res.cardData.notClicked },
        // { name: 'Reviewed', number: res.cardData.reviewed },
      ];
    });
  }
  splineChart() {
    this.userQuery = {
      month: this.currentMonth,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.reviews(this.userQuery).subscribe((res) => {
      this.weeklyCounts = res.chartData;
      const index = this.weeklyCounts.findIndex(
        (item: any) => item.key === 'totalReviewRequests'
      );

      if (index > 0) {
        const [totalRequestItem] = this.items.splice(index, 1);
        this.weeklyCounts.unshift(totalRequestItem);
      }
      this.weeklyCounts.forEach((response: any) => {
        this.appRegistration.push(response.value);
        console.log(this.appRegistration);
      });
      this.ratingCounts = res.chartData;
      const index1 = this.weeklyCounts.findIndex(
        (item: any) => item.key === 'totalReviewRequests'
      );

      if (index > 0) {
        const [totalRequestItem] = this.items.splice(index, 1);
        this.ratingCounts.unshift(totalRequestItem);
      }
      this.ratingCounts.forEach((response: any) => {
        this.ratingCountView.push(response.key);
        console.log(this.ratingCountView);
      });
      this.chartOptionsSubscriptioncancel = {
        chart: {
          type: 'column',
        },
        title: {
          text: 'Review SMS Metrics',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: this.ratingCountView,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Review SMS Metrics',
          },
        },
        legend: {
          enabled: true,
          reversed: true,
        },
        plotOptions: {
          series: {
            pointPadding: 0, // Adjust these values
            groupPadding: 0.2, // to control the bar height
            borderWidth: 0, // Optional: remove border to visually increase height
            dataLabels: {
              enabled: true,
              format: '{point.y}',
            },
          },
        },

        tooltip: {
          // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
        },
        colors: ['#dbb0e8a1'],
        series: [
          {
            name: 'Review SMS Metrics',
            data: this.appRegistration,
          },
        ],
      };
    });
  }
  freetrail(e: any) {
    this.freetrailParams = e.target.value;
    this.userQuery = {
      // free_trail: this.freetrailParams,
      // Subscription: this.subscriptionParam,
      // Status: this.statusParams,
      type: this.typeNameParams,
      location: this.locationParams,
      rating: this.freetrailParams,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.service.reviews(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.customers;
      // this.totalSummary = res.cardData.;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  typeName(e: any) {
    this.typeNameParams = e.target.value;
    this.userQuery = {
      // free_trail: this.freetrailParams,
      // Subscription: this.subscriptionParam,
      // Status: this.statusParams,
      type: this.typeNameParams,
      location: this.locationParams,
      rating: this.freetrailParams,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.reviews(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.customers;
      // this.totalSummary = res.cardData.;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  location(e: any) {
    this.locationParams = e.target.value;
    this.userQuery = {
      // free_trail: this.freetrailParams,
      // Subscription: this.subscriptionParam,
      // Status: this.statusParams,
      type: this.typeNameParams,
      location: this.locationParams,
      rating: this.freetrailParams,

      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.reviews(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.customers;
      // this.totalSummary = res.cardData.;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  // subscription(e: any) {
  //   this.subscriptionParam = e.target.value;
  //   this.userQuery = {
  //     month: 'july',
  //     Subscription: this.subscriptionParam,
  //     pageLimit: this.pageSize ? this.pageSize : '',
  //     pageNumber: this.currentPage ? this.currentPage : '',
  //   };

  //   this.service.reviews(this.userQuery).subscribe((res) => {
  //     console.log(res);
  //     this.ELEMENT_DATA = res.customers;
  //     this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  //   });
  // }
  // staus(e: any) {
  //   this.statusParams = e.target.value;
  //   this.userQuery = {
  //     month: 'july',
  //     Status: this.statusParams,
  //     pageLimit: this.pageSize ? this.pageSize : '',
  //     pageNumber: this.currentPage ? this.currentPage : '',
  //   };

  //   this.service.reviews(this.userQuery).subscribe((res) => {
  //     console.log(res);
  //     this.ELEMENT_DATA = res.customers;
  //     this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  //   });
  // }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.loading = true;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    console.log(this.pageSize);
    this.loader = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
      // locationName: filterValue,
    };
    this.service.reviews(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalDocuments;
      this.loading = false;

      this.ELEMENT_DATA = res.dashBoardData;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.items = [
        { name: 'Locations', number: res.cardData.distinctLocationCount },
        { name: 'Requests Sent', number: res.cardData.totalReviewRequests },
        { name: 'Clicked', number: res.cardData.clicked },
        // { name: 'Not clicked', number: res.cardData.notClicked },
        // { name: 'Reviewed', number: res.cardData.reviewed },
      ];
    });
  }

  langugeChange(e: any) {
    this.language = e.target.value;
    this.appRegistration = [];
    this.ratingCountView = [];
    this.userQuery = {
      month: this.language,
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.reviews(this.userQuery).subscribe((res) => {
      this.weeklyCounts = res.weeklyCounts.review;
      this.weeklyCounts.forEach((response: any) => {
        this.appRegistration.push(response.count);
        console.log(this.appRegistration);
      });
      this.ratingCounts = res.ratingCounts;
      this.ratingCounts.forEach((response: any) => {
        this.ratingCountView.push(response.count);
        console.log(this.ratingCountView);
      });
      this.chartOptionsRevered = {
        chart: {
          type: 'spline',
        },
        title: {
          text: 'data',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['week 1', 'week 2', 'week 3', 'week 4'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
        },
        legend: {
          reversed: true,
        },
        colors: ['#076611ab'],
        series: [
          {
            name: '',
            data: this.appRegistration,
          },
        ],
      };
      this.chartOptionsBarRevered = {
        chart: {
          type: 'bar',
        },
        title: {
          text: 'data',
        },
        credits: {
          enabled: true,
        },

        xAxis: {
          categories: ['5 star', '4 star', '3 star', '2 star', '1 star'],
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Goals',
          },
        },
        legend: {
          reversed: true,
        },
        dataLabels: {
          style: {
            color: '#000000',
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        colors: ['#068466'],
        series: [
          {
            name: '',
            data: this.ratingCountView.reverse(),
          },
        ],
      };
    });
  }
  toggleChanged(e: any) {
    console.log('toggl', e.target.checked);
  }
  deleteUser(id: any) {
    // this.adminService.deleteUser(id).subscribe((res) => {
    //   console.log('delete ', res);
    //   this.userDelete = true;
    //   Swal.fire({
    //     text: 'User delete succefully',
    //     showConfirmButton: true, // Hide the "OK" button
    //     icon: 'success', // You can change the icon type
    //     timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
    //   });
    //   this.userApisList();
    //   setTimeout(() => {
    //     this.userDelete = false;
    //   }, 3000);
    // });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
      locationName: filterValue,
    };
    this.service.reviews(this.userQuery).subscribe((res) => {
      console.log(res);
      this.totalSummary = res.totalDocuments;
      this.loading = false;

      this.ELEMENT_DATA = res.dashBoardData;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.items = [
        { name: 'Locations', number: res.cardData.distinctLocationCount },
        { name: 'Requests Sent', number: res.cardData.totalReviewRequests },
        { name: 'Clicked', number: res.cardData.clicked },
        // { name: 'Not clicked', number: res.cardData.notClicked },
        // { name: 'Reviewed', number: res.cardData.reviewed },
      ];
    });
  }
}
