

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->

    <app-loader *ngIf="loading"></app-loader>
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Customers</h1>
        </div>
       
     </div>

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
        </div>
     </div>
      <p class="succesfully" *ngIf="userDelete">User delete successfully</p>
      <!-- <div class="d-flex justify-content-end mr-md-5 mb-3">
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of monthsArray" [selected]="item == this.currentMonth" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
        <!-- <h3 class="heading-1">Filter Customers by Business Categories</h3>
              <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsBarRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div>
        <h3 class="heading-1">Filter Visits by Business Categories</h3>
              <div class="chart-customer">
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="chartOptionsBarRevered" 
             style="width: 1000px; height: 500px;display:block"></highcharts-chart>
      </div> -->


      <div >
        <div class="d-flex search_view">
                <div class="mr-auto">
                <input (keyup)="applyFilter($event)" placeholder="Search by locationName" >

                </div>
                <div>
                <img src="../../../assets/images/search.png">

                </div>
            </div>
        <!-- <div class="type">

            <div class="d-flex search_view">
                <div class="mr-auto">
                <input (keyup)="applyFilter($event)" placeholder="Search a customer" >

                </div>
                <div>
                <img src="../../../assets/images/search.png">

                </div>
            </div>
            <div class="type d-flex justify-content-xl-around">
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Type</p>
                    <select (change)="typeName($event)">
                        <option>select</option>
                        <option>saloon</option>
                        <option>medical</option>
                        <option>restaurent</option>
                        <option>general store</option>
                        <option>coaching</option>
                        <option>food corner</option>
                        <option>pc world</option>
                        <option>health center</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Locations</p>
                    <select (change)="location($event)">
                        <option>Select</option>
                        <option>Single</option>
                        <option>Double</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Free trail</p>
                    <select (change)="freetrail($event)">
                        <option>select</option>

                        <option>Not started</option>
                        <option>Completed</option>
                        <option>Expired</option>
                        <option>Cancelled</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Subscription</p>
                    <select (change)="subscription($event)">
                        <option>select</option>

                        <option>Not started</option>
                        <option>Completed</option>
                        <option>Expired</option>
                        <option>Cancelled</option>
                    </select>
                </div>
                <div class="d-flex type_view mb-3">
                    <p class="mr-3">Status</p>
                    <select (change)="staus($event)">
                        <option>select</option>
                        <option>Free trail running</option>
                        <option>Subscription Running</option>
                        <option>Subscription Running</option>
                        <option>Subscription Cancelled</option>
                        <option>Subscription expired</option>
                        <option>Free trail expired</option>
                        <option>Payment failed</option>
                    </select>
                </div>
            </div>
        </div> -->
      </div>
     <div class="table-data">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> L-id </th>
    <td mat-cell *matCellDef="let element">
      <a class="link" (click)="openDialog(element.locationId)">{{element.locationId}}</a>  </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> L-name </th>
    <td mat-cell *matCellDef="let element"> {{element?.locationName}}</td>
  </ng-container>
  <ng-container matColumnDef="Mname">
    <th mat-header-cell *matHeaderCellDef> merchantName </th>
    <td mat-cell *matCellDef="let element"> {{element?.merchantName}}</td>
  </ng-container>

  <!-- Weight Column -->
  <!-- <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> Subscription </th>
    <td mat-cell *matCellDef="let element">{{element?.subscription}}</td>
  </ng-container> -->

  <!-- Symbol Column -->
  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> Customers </th>
    <td mat-cell *matCellDef="let element"> {{element?.visitors}} </td>
  </ng-container>
  <ng-container matColumnDef="singupdate">
    <th mat-header-cell *matHeaderCellDef> Visits </th>
    <td mat-cell *matCellDef="let element"> {{element?.visits}} </td>
  </ng-container>
  <ng-container matColumnDef="freetrail1">
    <th mat-header-cell *matHeaderCellDef> New visits </th>
    <td mat-cell *matCellDef="let element"> {{element.newVisits}} </td>
  </ng-container>
  <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> Repeat </th>
    <td mat-cell *matCellDef="let element"> {{element.repeatVisitPercentage}} </td>
  </ng-container>
 
  
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">view </div></td>
  </ng-container>
  <!-- Weight Column -->
  <!-- <ng-container matColumnDef="channel">
    <th mat-header-cell *matHeaderCellDef> Interests </th>
    <td mat-cell *matCellDef="let element"> 
      <div class="categroy" *ngFor="let item of element.topicData">
     <h4 >{{item.name ? item.name : 'N/A'}},</h4> 

      </div>
    
    </td>
  </ng-container> -->

  <!-- Symbol Column -->
  <!-- <ng-container matColumnDef="views">
    <th mat-header-cell *matHeaderCellDef> Total summary created </th>
    <td mat-cell *matCellDef="let element"> {{element.followtopicCount}} </td>
  </ng-container> -->
  <!-- Weight Column -->
  <!-- <ng-container matColumnDef="clicks">
    <th mat-header-cell *matHeaderCellDef> Bookmarks </th>
    <td mat-cell *matCellDef="let element"> {{element.bookmarkDataCount}} </td>
  </ng-container> -->

  <!-- Symbol Column -->
  <!-- <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> actions </th>
    <td mat-cell *matCellDef="let element"> <div class="d-flex">
        <div class="edit-img">
           <img  src="../../../assets/images/e-3.png">
        </div>
        <div class="toggle">
          <label class="toggle">
  <input type="checkbox" id="toggleButton" (change)="toggleChanged($event)">
  <span class="slider"></span>
</label>
        </div>
         <div class="edit-img">
           <a>
            <img class="cursor"  src="../../../assets/images/delete.png" (click)="deleteUser(element._id)">
            </a>
           
        </div>
       
       </div></td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
     </div>
</div>
</div>
   