import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modifications',
  templateUrl: './modifications.component.html',
  styleUrls: ['./modifications.component.css'],
})
export class ModificationsComponent implements OnInit {
  step1: any = '';
  step2: any = '';
  step3: any = '';
  step4: any = '';

  refferalItems: any = [];
  referalForm: FormGroup;
  heading: string;
  constructor(private fb: FormBuilder) {
    this.referalForm = this.fb.group({
      heading: [''],
      step1: [''],
      step2: [''],
      step3: [''],
      step4: [''],
    });
  }

  ngOnInit(): void {
    this.heading =
      'Make your happy customers to bring their local  friends to ';
    this.step1 = 'Set up Reward value for referrar ';
    this.step2 = 'Set up Reward value for referee ';
    this.step3 = 'Upload Social media banner and message';
    this.step4 = 'Launch referral program';
    this.referalForm.controls['heading'].setValue(this.heading);
    this.referalForm.controls['step1'].setValue(this.step1);
    this.referalForm.controls['step2'].setValue(this.step2);
    this.referalForm.controls['step3'].setValue(this.step3);
    this.referalForm.controls['step4'].setValue(this.step4);
  }
  setup(e: any, text: any) {
    // console.log('Setup', e.target.value);
    if (text === 'head') {
      this.heading = e.target.value;
    } else if (text === 's1') {
      this.step1 = e.target.value;
    } else if (text === 's2') {
      this.step2 = e.target.value;
    } else if (text === 's3') {
      this.step3 = e.target.value;
    } else if (text === 's4') {
      this.step4 = e.target.value;
    }
  }
}
