<div class="main ml-4 mt-3">
    <div>
        <div class="cursor" (click)="back()">
            <a>Back</a>
        </div>

        <div class="sms-req">
           <h5>Customers</h5>
        </div>
<div
[formGroup]="dummydata"
> 
        <div>

           <div class="L-input mt-4">
                <select  class="form-control" placeholder="Please select" formControlName="location">
                  <option value="" selected>Select the location name</option>
                  <option [value]="review" *ngFor="let review of locations">{{review}}</option>
                </select>
        <!-- <div *ngIf="stars" class="invalid-feedback m-t d-block">Stars is required </div> -->

            </div>

               <div  class="d-flex sms-1 mt-3">
                <h6>Location Name : </h6>
                <p> {{ this.dummydata.value.location}}</p>
            </div>

            <div  class="d-flex sms-1">
                <h6>This location registration date is :</h6>
                <p> </p>
            </div>
<div class="mt-4">
   <mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Start date" formControlName="start">
    <input matEndDate placeholder="End date" formControlName="end">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
</div>
       
    </div>
        
    <div class="d-flex mt-4">
        <div class="clic">
            <p>Enter count</p>
            <input class="form-control c-1 c-2" formControlName="count">
        </div>
       
    </div>
    
            <div class="ml-5 mt-3 pl-5">
  <a class="ml-5 pl-5 cursor" (click)="generate()">Generate Customers</a>

            </div>
       
        </div>

        <app-loader *ngIf="loading"></app-loader>


        <div class="mt-5 pt-5" *ngIf="cus">
             <h3 class="succeed">{{this.customers.length}} new customers generated for the location</h3>

            <div class="d-flex" *ngFor="let v of customers">
        <div class="clic">
            <p>C_name</p>
            <input class="form-control c-1 c-3" [value]="v.customerName">
        </div>
        <div class="clic ml-5 pl-4">
            <p>C_phone</p>
            <input class="form-control c-1 c-3" [value]="v.mobileNumber">
        </div>
        <div class="clic ml-5 pl-4">
            <p>C_bill</p>
            <input class="form-control c-1 c-3" [value]="v.billingAmount">
        </div>
        <div class="clic ml-5 pl-4">
            <p>C_visitedDate</p>
            <input class="form-control c-1 c-3" [value]="v.lastvisit |date ">
        </div>
    </div>
        </div>
<div> 
        

</div>
 