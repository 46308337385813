import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pushnotification',
  templateUrl: './pushnotification.component.html',
  styleUrls: ['./pushnotification.component.css'],
})
export class PushnotificationComponent implements OnInit {
  PushNotifications: FormGroup;
  Radiooptions = [
    { key: 'both', value: 'Both' },
    { key: 'android', value: 'Android' },
    { key: 'ios', value: 'iOS' },
  ];
  pushSubmitted: boolean = false;
  pushnotificationsUrl: string;
  selectedFilePushnotifications: File;
  fileNamePushnotifications: any;
  citys: any;
  category: any;
  review: any;
  locations: any;
  locationsEvent: any = '';
  type: string;
  type1: string;
  redirected: any = ['reviews', 'referrals', 'customers', 'premium'];
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private service: AdminService
  ) {
    this.PushNotifications = this.fb.group({
      deviceType: [''],
      deviceType1: [''],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      redirected: ['', [Validators.required]],
      // city: ['', [Validators.required]],
      // review: ['', [Validators.required]],
      // category: ['', [Validators.required]],
      // // AM: ['', [Validators.required]],
      // file: [''],
      // url: [
      //   '',
      //   [
      //     Validators.pattern(
      //       '^((https?|ftp):\\/\\/)?([a-z0-9-]+\\.)+[a-z]{2,}(\\/[^\\s]*)?$'
      //     ),
      //     // Validators.pattern('^(https?://)?(www.youtube.com|youtu.be)/.+$')
      //   ],
      // ],
      // time: [''],
    });
  }

  ngOnInit(): void {
    this.city();
    this.service.locationsNames().subscribe((locations) => {
      console.log(locations);
      this.locations = locations.data;
    });
  }
  get updatePushNotificaitons(): { [key: string]: AbstractControl } {
    return this.PushNotifications.controls;
  }
  city() {
    this.service.cityOptions().subscribe((options) => {
      console.log(options);
      this.citys = options.cities;
    });
    this.service.categoryOptions().subscribe((options) => {
      console.log(options);
      this.category = options.category;
    });
    this.service.reviewOptions().subscribe((options) => {
      console.log(options);
      this.review = options.review;
    });
  }
  onFileSelectedPushNotifications(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.pushnotificationsUrl = reader.result as string;
      this.selectedFilePushnotifications = <File>event.target.files[0];
      this.fileNamePushnotifications = event.target.files[0].name;
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.PushNotifications.get('file').setValue(file);
    }
  }
  radio(e: any) {
    if (e) {
      this.type1 = '';
      this.locationsEvent = '';
      // this.PushNotifications.get('deviceType1').setValue('All');
    }
  }
  locationsChange(e: any) {
    this.locationsEvent = e.target.value;
    if (this.locationsEvent != '') {
      this.type = '';
      this.PushNotifications.get('deviceType').setValue(this.locationsEvent);
    }
  }
  CreatePushNotifications() {
    if (this.PushNotifications.invalid) {
      this.pushSubmitted = true;
      console.log(this.PushNotifications);
    } else {
      console.log(this.PushNotifications);

      this.pushSubmitted = false;
      // const formData = new FormData();
      // formData.append('image', this.PushNotifications.get('file').value);
      // formData.append('title', this.PushNotifications.value.title.trim());
      // formData.append('destinationURL', this.PushNotifications.value.url);
      // formData.append(
      //   'description',
      //   this.PushNotifications.value.description.trim()
      // );
      // // formData.append('date', this.readyDateAndTime);
      // // formData.append('date', this.pushNotificationTime);
      // formData.append('deviceType', this.PushNotifications.value.deviceType);
      // formData.append('locationName', this.PushNotifications.value.location);
      // formData.append('city', this.PushNotifications.value.city);
      // formData.append('category', this.PushNotifications.value.category);
      // formData.append('reviews', this.PushNotifications.value.review);
      let obj = {
        locationName: this.PushNotifications.value.deviceType,
        type: this.PushNotifications.value.deviceType1,
        redirected: this.PushNotifications.value.redirected,
        titel: this.PushNotifications.value.title.trim(),
        description: this.PushNotifications.value.description.trim(),
      };
      this.service.createNotifications(obj).subscribe((result) => {
        this.pushnotificationsUrl = null;
        this.PushNotifications.reset();
        Swal.fire({
          text: 'Succefully created push notification',
          showConfirmButton: true, // Hide the "OK" button
          icon: 'success', // You can change the icon type
          timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        });
        // }, 1000);
      });
    }
  }
}
