import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
import { RefferalcampinsdialogComponent } from './refferalcampinsdialog/refferalcampinsdialog.component';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
export interface PeriodicElement {
  Cid: any;
  Cname: any;
  ctype: any;
  Step1: any;
  Step2: any;
  Step3: any;
  Step4: any;
  freetrail: any;
  status: any;
  action: any;
}

@Component({
  selector: 'app-refferals',
  templateUrl: './refferals.component.html',
  styleUrls: ['./refferals.component.css'],
})
export class RefferalsComponent implements OnInit {
  freetrailChartConversion: any;
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Step1',
    'Step2',
    'Step3',
    'Step4',
    'status',
    'freetrail',
    'channel',
    'channel1',
    'channel2',
    'action',
  ];
  displayedColumns1: string[] = [
    'Cid',
    'Cname',
    'Step3',
    'Step4',
    'status',
    'freetrail',
    'channel',
    'channel1',
    'channel2',
    'action',
  ];
  Language: any = ['This month', 'This week', 'Today'];
  loader: boolean = false;
  userQuery!: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  fliterBy: any[] = ['By Location', 'By City', 'By Loc_category', 'All'];

  currentPage: any;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: any[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean = false;
  highcharts = Highcharts;
  loading: boolean;
  chartData: any;
  count: any = [];
  data: any = [];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  endDate: any;
  startDate: any;
  ENDDATE: any = '';
  STARTDATE: any = '';
  constructor(
    private service: AdminService,
    private dialog: MatDialog,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.userApisList();
  }

  campign(
    clicks: any,
    reqSent: any,
    location: any,
    id: any,
    num: any,
    leads: any,
    leadsVisit: any
  ) {
    this.dialog.open(RefferalcampinsdialogComponent, {
      data: {
        clicks: clicks,
        reqSent: reqSent,
        location: location,
        id: id,
        num: num,
        leads: leads,
        leadsVisit: leadsVisit,
      },
    });
  }
  dateEvent(e: any, text: any) {
    if (text == 'end') {
      this.endDate = e.value;
      this.ENDDATE = this.datepipe.transform(this.endDate, 'yyyy/MM/dd');
    } else {
      this.startDate = e.value;
      this.STARTDATE = this.datepipe.transform(this.startDate, 'yyyy/MM/dd');
    }
    console.log(e.value);
    this.userApisList();
  }
  userApisList() {
    this.count = [];
    this.data = [];
    this.loading = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
      startDate: this.STARTDATE,
      endDate: this.ENDDATE,
    };

    this.service.referrals(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalCount;
      this.chartData = res.chartData;
      this.chartData.forEach((res: any) => {
        this.count.push(res.value);
        this.data.push(res.key);
      });
      this.freetrailChartConversionBtn();

      this.items = [
        { name: 'Locations', number: res.cardData[0].totalLocations },
        {
          name: 'Requests Sent',
          number: res.cardData[0].totalReferralRequestSent,
        },
        { name: 'Clicked', number: res.cardData[0].totalClicked },
        { name: 'Leads', number: res.cardData[0].totalLeads },
        {
          name: 'First visits',
          number: res.cardData[0].totalFirstVisitCount,
        },
      ];
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.loading = true;

    // this.currentPage = event.pageIndex + 1;
    let newCurrentPage = event.pageIndex + 1;
    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage,
    };
    this.service.referrals(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.dashBoardData;
      this.loading = false;

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalSummary = res.totalCount;
    });
  }
  freetrailChartConversionBtn() {
    this.freetrailChartConversion = {
      chart: {
        type: 'bar',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      title: {
        text: 'Referral program set up',
      },
      xAxis: {
        type: 'categories',
        categories: this.data,
      },

      legend: {
        enabled: true,
        reversed: true,
      },
      plotOptions: {
        series: {
          pointPadding: 0, // Adjust these values
          groupPadding: 0.2, // to control the bar height
          borderWidth: 0, // Optional: remove border to visually increase height
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
      },
      colors: ['#068466'],

      tooltip: {
        // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
      },

      series: [
        {
          name: this.data,
          data: this.count,
          // data: [
          //   {
          //     name: 'Smses Sent',
          //     y: 63.06,
          //     drilldown: 'Smses Sent',
          //   },
          //   {
          //     name: 'Opens',
          //     y: 19.84,
          //     drilldown: 'Opens',
          //   },
          //   {
          //     name: 'Clicks',
          //     y: 4.18,
          //     drilldown: 'Clicks',
          //   },
          //   {
          //     name: 'Invited',
          //     y: 4.12,
          //     drilldown: 'Invited',
          //   },
          //   {
          //     name: 'Leads',
          //     y: 2.33,
          //     drilldown: 'Leads',
          //   },
          //   {
          //     name: 'First Visits',
          //     y: 2.33,
          //     drilldown: 'First Visits',
          //   },
          // ],
        },
      ],
    };
  }
}
