


<div class="main pb-5">
    <div class="t-1_activity">
        <div class="activity_1 pt-5 px-4 mx-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex active_header">
                        <P>{{merchantName}}</P>
                        <p class="ml-5 pl-5">Activity</p>
                    </div>
                </div>
            </div>
        </div>
<app-loader *ngIf="loader"></app-loader>

        <div class="container">
  <div class="accordion" id="accordionExample">
  <div class="card">
    <div class="card-head" id="headingOne">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Onboarding</p>
            </div>
            

        </div>
      </h2>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body">
      
      
        <div class="wizard">
  <div class="process">
    <ul>
      <li class="step" [class.step-1] = "result.name === 'Mobile Number Verification'" [class.completed]="result.success" [class.failed]="result.failed" *ngFor="let result of onboarding">
        <div class="name" [class.failed]="result.failed">{{result.name}}
            
        
        </div>
         <div class="d-flex b-1" *ngIf="result.name == 'Mobile Number Verification'">
            <div>
            <p>{{result.date | date:'short' }}</p>

            </div>
            <div>
                <h3 *ngIf="result.success">Success</h3>
                <h6 *ngIf="result.failed">Failed</h6>
            </div>
            <div>
                <h4>retry</h4>
            </div>
            <div>
                <h4>Call</h4>
            </div>
         </div>
      </li>
      <!-- <li class="step">
        <div class="name">Basic details filled </div>
         
      </li>
      <li class="step">
        <div class="name">Google MyBiz Authenticated</div>
          
      </li>
      
      <li class="step">
        <div class="name">Claimed local business</div>
          
      </li> -->
      
    </ul>
  </div>
  <!-- <div class="input">
    <div class="form">[[FORM INFO]]</div>
  </div> -->
</div>
      </div>
    </div>
  </div>
  <!-- <div class="card">
    <div class="card-head" id="headingTwo">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>First Day</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
         <div class="wizard">
  <div class="process">
    <ul>
      <li class="step step-2" [class.completed]="true">
        <div class="name">First review request sent
            
        
        </div>
          
      </li>
      <li class="step" [class.failed]="true">
        <div class="name" [class.failed]="true">Received a review </div>
         
      </li>
      <li class="step">
        <div class="name">Invited team member</div>
          
      </li>
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div> -->
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 1</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of FirstDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 2</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="collapsesix" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of SecondDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 3</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="collapseseven" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of ThirdDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 4</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="collapseeight" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of FourthDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapsnine" aria-expanded="false" aria-controls="collapsnine">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 5</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="collapsnine" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of FifthDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#ten" aria-expanded="false" aria-controls="ten">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 6</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="ten" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of SixthDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#ten1" aria-expanded="false" aria-controls="ten1">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 7</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="ten1" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of SeventhDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
    
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Free Trail</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of trail" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#sub2" aria-expanded="false" aria-controls="sub2">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Subscription</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="sub2" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of Subscription" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#sub1" aria-expanded="false" aria-controls="sub1">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Payments</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="sub1" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of Payments" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#ten2" aria-expanded="false" aria-controls="ten2">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 15</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="ten2" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of FifteenDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#ten3" aria-expanded="false" aria-controls="ten3">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 20</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="ten3" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of twentyDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#ten4" aria-expanded="false" aria-controls="ten4">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 25</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="ten4" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of twentyFiveDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-head" id="headingThree">
      <h2 class="mb-0 collapsed" data-toggle="collapse" data-target="#ten5" aria-expanded="false" aria-controls="ten5">
            <div class="onboarding_1">
            <div class="onboard d-flex">
                <img src="../../../../assets/images/check (4).png"  class='mr-3' width="25px" height="25px">
                <p>Day 30</p>
            </div>
            

        </div>
      </h2>
    </div>
    <div id="ten5" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
<div class="wizard">
  <div class="process">
    <ul>
       
      <li class="step" *ngFor="let step of ThirtyDay" [class.completed]="step.success" [class.failed]="step.failed">
        <div class="name" [class.failed]="step.failed">{{ step.name }}</div>
         
      </li>
      
      
    </ul>
  </div>
   
</div>
      </div>
    </div>
  </div>
</div>
</div>

   
         
       
       
        
   
        

<div>
   
        
</div>
 
     
</div>


<!-- <div class="wizard">
  <div class="process">
    <ul>
      <li class="step step-1" [class.completed]="true">
        <div class="name">Onboarding
            
        
        </div>
        <div class="step-1-radio">
          <mat-radio-group *ngFor="let Onboarding of Onboarding"
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  >

    <mat-radio-button class="example-radio-button" [checked]="Onboarding.success" [value]="Onboarding.name">{{Onboarding.name}}</mat-radio-button>

</mat-radio-group>
        </div>
      </li>
      <li class="step step-1" [class.completed]="firstaday">
        <div class="name">First Day </div>
         <div class="step-1-radio">
          <mat-radio-group *ngFor="let Onboarding of firstDay"
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  >

    <mat-radio-button class="example-radio-button" [checked]="Onboarding.success" [value]="Onboarding.name">{{Onboarding.name}}</mat-radio-button>

</mat-radio-group>
        </div>
      </li>
      <li class="step step-2" [class.completed]="sevendays">
        <div class="name">7-Days</div>
                 <div class="step-1-radio">
          <mat-radio-group *ngFor="let Onboarding of days"
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  >

    <mat-radio-button class="example-radio-button" [checked]="Onboarding.success" [value]="Onboarding.name">{{Onboarding.name}}</mat-radio-button>

</mat-radio-group>
        </div>
      </li>
      <li class="step step-2"> 
        <div class="name">Free trail</div>
                 <div class="step-1-radio">
          <mat-radio-group *ngFor="let Onboarding of trail"
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  >

    <mat-radio-button class="example-radio-button" [checked]="Onboarding.success" [value]="Onboarding.name">{{Onboarding.name}}</mat-radio-button>

</mat-radio-group>
        </div>
      </li>
     
    </ul>
  </div>
  
</div> -->