 

 <div class="main">
    <div class="internal-team bg-white px-5 py-3">
        <div class="team_header d-flex">
            <div class="customer_header header">
                <h1>Internal-Team</h1>
            </div>
            <app-loader *ngIf="loader"></app-loader>
            <div class="d-flex">
                <div class="mr-3">
 <mat-form-field class="example-full-width" appearance="fill">
          <input
            matInput
            (keyup)="searchDropDown($event)"
            placeholder="Search by name , email..."
            #input
          />
          <mat-icon matSuffix
            ><img
              src="../../assets/images/search-icon.svg"
              class="calendra-img"
          /></mat-icon>
        </mat-form-field>
                </div>
                <div class="service-list-drop-down mr-2">
        <mat-form-field appearance="fill">
          <div class="arrow-up"></div>
          <span matPrefix>Role:&nbsp;</span>
          <mat-icon matSuffix 
          ><img
            src="../../assets/images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
          <mat-select
            placeholder="Select"
            multiple
            disableOptionCentering
            panelClass="myPanelDonationrole"
            (selectionChange)="roleEvent($event)"
            #closeSerivce
          >
          
            <!-- <mat-select-trigger>
              {{serviceControl.value?.[0] || ''}}
              <span
                *ngIf="(serviceControl.value?.length || 0) > 1"
                class="example-additional-selection"
              >
                (+{{ (serviceControl.value?.length || 0) - 1 }}
                {{ serviceControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger> -->
            <mat-option
              class="services-view"
              *ngFor="let viewService of services"
              [value]="viewService"
              >{{ viewService }}</mat-option
            >
          
          </mat-select>
        </mat-form-field>
      </div>
                
                <div class="service-list-drop-down mr-2">
        <mat-form-field appearance="fill">
          <div class="arrow-up"></div>
          <span matPrefix>Status:&nbsp;</span>
          <mat-icon matSuffix 
          ><img
            src="../../assets/images/arrow-1.svg"
            class="calendra-img"
        /></mat-icon>
          <mat-select
            placeholder="Select"
            multiple
            disableOptionCentering
            (selectionChange)="statusEvent($event)"
            panelClass="myPanelDonationrole"
            #closeSerivce
          >
          
            <!-- <mat-select-trigger>
              {{serviceControl.value?.[0] || ''}}
              <span
                *ngIf="(serviceControl.value?.length || 0) > 1"
                class="example-additional-selection"
              >
                (+{{ (serviceControl.value?.length || 0) - 1 }}
                {{ serviceControl.value?.length === 2 ? "other" : "others" }})
              </span>
            </mat-select-trigger> -->
            <mat-option
              class="services-view"
              *ngFor="let viewService of status"
              [value]="viewService"
              >{{ viewService }}</mat-option
            >
          
          </mat-select>
        </mat-form-field>
      </div>
                <div>
                <button class="btn cursor create-btn" routerLink="/create">Create</button>

                </div>
                </div>
           

    </div>
 </div>
  <div class="table mb-5 mt-4 mr-3">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mr-3">
        <!-- Position Column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>
            <h2>user</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <!-- <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox> -->

              <div class="d-flex">
                <ngx-avatar
                 
                  class="mr-2 mt-1"
                  size="40"
                  initialsSize="1"
                  name="{{ element.firstName }}"
                ></ngx-avatar>

                <!-- <img
                  *ngIf="element.image != ''"
                  src="{{ element.image }}"
                  class="user-image-table mr-2"
                /> -->
                <div class="">
                  <h6 class="mt-2">
                    {{ element.firstName }}
                     
                  </h6>
                  <p>{{ element.email }}</p>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="Phoneno">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Phone no.</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h4>+ 91 - {{ element.phone ? element.phone : 'NA' }}</h4>
          </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Role</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <img
                src="../../assets/images/admin-i.svg"
                class="admin-img mr-1"
              />
              <div class="">
                <h6 class="mt-2">{{ element.role | titlecase }}</h6>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Status</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6 *ngIf="element.status == 'active'" class="eligable">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == 'suspended'" class="not-member">
              {{ element.status | titlecase }}
            </h6>
            <h6 *ngIf="element.status == ''" class="not-eligable">
              {{ element.status | titlecase }}
            </h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Lastvisited">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Created Date</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <h6>{{ element?.createdAt | date:'dd-MM-yyyy' }}</h6>
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>
            <h2>Action</h2>
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              mat-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>
                <img
                  src="../../assets/images/action-dot.svg"
                  id="users-total"
                  class="action-dots"
              /></mat-icon>
              <div
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                place
              ></div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button
                class="View"
                mat-menu-item
                value="Edit"
                (click)="view(element._id)"
              >
                <img
                  src="../../assets/images/view.svg"
                  class="mr-3"
                />
                <span>View</span>
              </button>
              <button
                class="Deactivate"
                *ngIf="element.status != 'suspended'"
                mat-menu-item
                value="Deactivate"
                (click)="suspended(element._id)"
              >
                <img
                  src="../../assets/images/suspened.svg"
                  class="mr-3"
                />
                <span>Suspend</span>
                <!-- <span>Change Customer type from New,Regular</span> -->
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data Available</td>
        </tr>
      </table>
     <mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator>
    </div>
 </div>